import shop from "../../api/shop";
import * as types from "../../constants/ActionTypes";
import store from "../store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import _ from "lodash";
import axios from "axios";
import appConfig from "../../config";
import { getFilterDoctorParams } from "./doctorActions";
import { RECEIVE_SUGGESTIONS } from "../../constants/ActionTypes";
import { getSearchData } from "../../api/apicalls";

// // Auth Service
// export const setCurrentUser = user => ({
//     type: types.SET_CURRENT_USER,
//     user
// })
//
// export const login = data => dispatch => {
//     console.log("ACTIONS/AUTH: login");
//     axios
//         .post(appConfig.loginUrl, data)
//         .then(response => {
//             // Handle success.
//             console.log('Well done!');
//             console.log('User', response.data);
//             console.log('User profile', response.data.user);
//             console.log('User token', response.data.jwt);
//             localStorage.setItem('user', JSON.stringify(response.data));
//             //window.open(`${process.env.PUBLIC_URL}/`, '_self');
//             dispatch(setCurrentUser(response.data))
//         })
// }
//
// export const logout = () => dispatch => {
//     console.log('ACTIONS/AUTH: logout');
//     localStorage.removeItem('user');
//     dispatch({
//         type: types.LOGOUT
//     })
// }

// HELPER SERVICE
export const switchPrecriptionOn = () => ({
  type: types.SWITCH_PRESCRIPTION_ON,
});

export const switchPrecriptionOff = () => ({
  type: types.SWITCH_PRESCRIPTION_OFF,
});

// Products Service
export const fetchProductsBegin = () => ({
  type: types.FETCH_PRODUCTS_BEGIN,
});

export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

export const getAllProducts = () => (dispatch) => {
  dispatch(fetchProductsBegin());
  shop.getProducts((products) => {
    dispatch(receiveProducts(products));
    return products;
  });
};
export const fetchSingleProduct = (productId) => ({
  type: types.FETCH_SINGLE_PRODUCT,
  productId,
});

//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product, qty, bType, bTime) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty, bType, bTime));
};
export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
  dispatch(removeFromWishlist(product));
};

export const addToCartUnsafe = (product, qty, bType, bTime) => ({
  type: types.ADD_TO_CART,
  product,
  qty,
  bType,
  bTime,
});

// export const addToCartUnsafe = (product, qty) => ({
//     type: types.ADD_TO_CART,
//     product,
//     qty
// });

export const removeFromCart = (product_id) => (dispatch) => {
  toast.error("Item Removed from Cart");
  dispatch({
    type: types.REMOVE_FROM_CART,
    product_id,
  });
};
export const incrementQty = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
};
export const decrementQty = (productId) => (dispatch) => {
  toast.warn("Item Decrement Qty to Cart");

  dispatch({
    type: types.DECREMENT_QTY,
    productId,
  });
};

//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
  toast.success("Item Added to Wishlist");
  dispatch(addToWishlistUnsafe(product));
};
export const addToWishlistUnsafe = (product) => ({
  type: types.ADD_TO_WISHLIST,
  product,
});
export const removeFromWishlist = (product_id) => (dispatch) => {
  toast.error("Item Removed from Wishlist");
  dispatch({
    type: types.REMOVE_FROM_WISHLIST,
    product_id,
  });
};

//Compare Products
export const addToCompare = (product) => (dispatch) => {
  toast.success("Item Added to Compare");
  dispatch(addToCompareUnsafe(product));
};
export const addToCompareUnsafe = (product) => ({
  type: types.ADD_TO_COMPARE,
  product,
});
export const removeFromCompare = (product_id) => ({
  type: types.REMOVE_FROM_COMPARE,
  product_id,
});

// Filters
export const filterSearchString = (searchString) => ({
  type: types.FILTER_SEARCH_STRING,
  searchString,
});

export const filtereSpeciality = (speciality) => ({
  type: types.FILTER_SPECIALITY,
  speciality,
});
export const filterHCity = (hcity) => ({
  type: types.FILTER_HOSPITAL_CITY,
  hcity,
});
export const filterRate = (rating) => ({
  type: types.FILTER_RATE,
  rating,
});

export const filterDocspecial = (docspecial) => ({
  type: types.FILTER_DOCSPECIAL,
  docspecial,
});
export const filterDCity = (dcity) => ({
  type: types.FILTER_DOCTOR_CITY,
  dcity,
});
export const filterDRate = (drate) => ({
  type: types.FILTER_DOCTOR_RATE,
  drate,
});
export const filterPrice = (dprices) => ({
  type: types.FILTER_PRICE,
  dprices,
});

export const filterExperience = (experience) => ({
  type: types.FILTER_EXPERIENCE,
  experience,
});
export const filterGender = (gender) => ({
  type: types.FILTER_GENDER,
  gender,
});
export const filterAvailableFor = (availableFor) => ({
  type: types.FILTER_AVAILABLE_FOR,
  availableFor,
});
export const filterAddCities = (cities) => ({
  type: types.FILTER_ALL_CITIES,
  cities,
});
export const filterAddHospitalCities = (cities) => ({
  type: types.FILTER_ALL_HOSPITAL_CITIES,
  cities,
});
export const filterCity = (city) => ({
  type: types.FILTER_CITY,
  city,
});
export const filterClean = () => ({
  type: types.FILTER_CLEAN,
});

export const filterSort = (sort_by) => ({
  type: types.SORT_BY,
  sort_by,
});

export const filterBrand = (brand) => ({
  type: types.FILTER_BRAND,
  brand,
});
export const filterColor = (color) => ({
  type: types.FILTER_COLOR,
  color,
});

// Currency
export const changeCurrency = (symbol) => ({
  type: types.CHANGE_CURRENCY,
  symbol,
});

// Hospital Service
export const getSuggestions = () => (dispatch) => {
  let treat = getSearchData("Treatment");
  let cond = getSearchData("Condition");
  let spcl = getSearchData("Speciality");
  let hosp = getSearchData("Hospital");
  let doc = getSearchData("Doctor");

  Promise.all([spcl, cond, treat, hosp, doc]).then((r) => {
    console.log("SUGGESTIONS :: ", r);
    dispatch({
      type: types.RECEIVE_SUGGESTIONS,
      treatments: r[0],
      conditions: r[1],
      specialisations: r[2],
      hospitals: r[3],
      doctors: r[4],
    });
  });
};

//    treatments: [],
//     conditions: [],
//     specialisations: [],
//     hospitals: [],
//     doctors: [],

export const getSuggestionsTreatments = () => (dispatch) => {
  getSearchData("Treatment").then((r) => {
    dispatch({
      type: types.RECEIVE_SUGGESTIONS_TREATMENTS,
      treatments: r,
    });
  });
};

export const getSuggestionsConditions = () => (dispatch) => {
  getSearchData("Condition").then((r) => {
    dispatch({
      type: types.RECEIVE_SUGGESTIONS_CONDITIONS,
      conditions: r,
    });
  });
};

export const getSuggestionsSpeciality = () => (dispatch) => {
  getSearchData("Speciality").then((r) => {
    dispatch({
      type: types.RECEIVE_SUGGESTIONS_SPECIALISATIONS,
      specialisations: r,
    });
  });
};
export const getSuggestionsHospitals = () => (dispatch) => {
  getSearchData("Hospital").then((r) => {
    dispatch({
      type: types.RECEIVE_SUGGESTIONS_HOSPITALS,
      hospitals: r,
    });
  });
};
export const getSuggestionsDoctors = () => (dispatch) => {
  getSearchData("Doctor").then((r) => {
    dispatch({
      type: types.RECEIVE_SUGGESTIONS_DOCTORS,
      doctors: r,
    });
  });
};
