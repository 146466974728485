import React, { Component } from "react";
// import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import "react-tabs/style/react-tabs.scss";
// import {Link} from 'react-router-dom';
import { GoogleApiWrapper } from "google-maps-react";
import ReactStarsRating from "react-awesome-stars-rating";
// import axios from "axios";
// import appConfig from "../../../../config";
// import * as types from "../../../../constants/ActionTypes";
import Interweave from "interweave";
import { printDateTime } from "../../../../services";
import { Icon } from "@iconify/react";
import quoteRight from "@iconify/icons-el/quote-right";

class CommentsListHosital extends Component {
  state = {
    comments: this.props.comments ? this.props.comments : [],
    type: this.props.type,
  };

  onChange = (value) => {
    console.log(`React Stars Rating value is ${value}`);
  };

  // getCommentBody(comm) {
  //     return (<div>
  //         <span>{comm.Title}</span>
  //     </div>)
  // }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.comments !== this.props.comments) {
      this.setState({ comments: this.props.comments });
    }
  }

  render() {
    let { comments } = this.state;

    // console.log("HOSPITAL/COMMENTS-LIST item recived : ", comments);
    console.log("HOSPITAL/COMMENTS-LIST props type : ", this.props.type);
    // console.log("HOSPITAL/COMMENTS-LIST sate : ", this.state);

    // const mapStyles = {
    //     width: '100%',
    //     height: '100%',
    //     top: '0px',
    // };

    return (
      <div>
        <div>
          <div style={{ padding: "30px 0px 10px 0px" }}>
            <h2
              style={{
                borderBottom: "1px solid #dee2e6",
                paddingBottom: "10px",
              }}
            >
              {this.state.type}'s Reviews
            </h2>
          </div>
          {comments.map((comm, index) => {
            return (
              <div key={index}>
                <div
                  className="row"
                  style={{
                    padding: "15px 30px 0px 0px",
                    minHeight: "200px",
                    marginBottom: "70px",
                  }}
                >
                  <div className="col-md-3 ">
                    {/*<div>*/}
                    <h3
                      style={{
                        fontSize: "large",
                        fontWeight: "600",
                        marginTop: "10px",
                      }}
                    >
                      {comm.Name}
                    </h3>
                    <br />
                    <h6>{printDateTime(comm.createdAt)}</h6>
                    <br />

                    {this.props.type === "doctor" ? (
                      <div>
                        <h6>
                          Will you recommend this Doctor
                          <br />
                          <ReactStarsRating
                            value={comm.Cleanliness}
                            isEdit={false}
                            size={17}
                          />
                        </h6>
                        <br />
                      </div>
                    ) : (
                      <div>
                        <h6>
                          Cleanliness
                          <br />
                          <ReactStarsRating
                            value={comm.Cleanliness}
                            isEdit={false}
                            size={17}
                          />
                        </h6>
                        <br />
                        <h6>
                          Medical Care
                          <br />
                          <ReactStarsRating
                            value={comm.MedicalCare}
                            isEdit={false}
                            size={17}
                          />
                        </h6>
                        <br />
                        <h6>
                          Infrastructure
                          <br />
                          <ReactStarsRating
                            value={comm.Infrastructure}
                            isEdit={false}
                            size={17}
                          />
                        </h6>
                        <br />
                        <h6>
                          Staff Attitude
                          <br />
                          <ReactStarsRating
                            value={comm.StaffAttitude}
                            isEdit={false}
                            size={17}
                          />
                        </h6>
                        <br />
                        <h6>
                          Actual cost Vs Initial Estimate
                          <br />
                          <ReactStarsRating
                            value={comm.WaitTime}
                            isEdit={false}
                            size={17}
                          />
                        </h6>
                      </div>
                    )}
                  </div>
                  <div
                    className="col-md-9"
                    style={{
                      background: "#f2f2f2",
                      border: "1px solid #dadada",
                      borderRadius: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "large",
                        fontWeight: "bolder",
                        marginTop: "10px",
                      }}
                    >
                      {comm.Title}
                    </p>
                    <div
                      style={{
                        background: "#fff",
                        position: "absolute",
                        width: "-webkit-fill-available",
                        height: "-webkit-fill-available",
                        border: "1px solid #dee2e6",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          right: "30px",
                          fontSize: "xxx-large",
                          bottom: "10px",
                        }}
                      >
                        <Icon icon={quoteRight} color="#dadada80" />
                      </div>

                      <Interweave content={comm.Review} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

//export default DetailsTopTabs;
export default GoogleApiWrapper({
  apiKey: "AIzaSyClT2rSG4_sAQr3HPFxxenREGLsr__Pk9c",
})(CommentsListHosital);
