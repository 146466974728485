import React from 'react';
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';

import CardSection from './CardSection';
import appConfig from "../../config";
import axios from "axios";

class CheckoutForm extends React.Component {

    constructor() {
        super();
        this.state = {
            msg:""
        };
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        //event.preventDefault();

        const {stripe, elements} = this.props

        if (!stripe || !elements) {
            console.log("NOT MOVING FORWARD", stripe, elements)
            // Stripe.js has not yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }

        let key = this.props.bookingEntry.clientsecret;

        const result = await stripe.confirmCardPayment(key, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: this.props.user.user.user.name,
                },
            }
        });

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
            this.setState({msg:result.error.message})
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                console.log("PAYMENT DONE : ", result.paymentIntent);
                this.updateBilling(result.paymentIntent)
            }else{
                console.error("PAYMENT Failed : ", result);
            }
        }
    };

    updateBilling(paymentIntent) {
        let auth = this.props.user;
        console.log("auth is : ", auth);

        if (auth.isLogedIn) {
            let data = {
                "FeeStatus": paymentIntent.status,
                "paymentid": paymentIntent.id,
                "currency": paymentIntent.currency,
                "paymentmethod": paymentIntent.payment_method
            };
            let url = appConfig.bookingOrder + "/" + this.props.bookingEntry.id;

            axios
                .put(url, data, {
                    headers: {
                        Authorization: 'Bearer ' + auth.user.jwt
                    }
                })
                .then(response => {
                    console.log('BOOKING updated', response.data);
                    this.props.okmodel(paymentIntent);
                })
                .catch((error, resp) => {
                    // Handle error.
                    console.log('An error occurred:', error, resp);
                });
        }
    }

    testOK = () => {
        console.log("TEST OK");
        this.handleSubmit();
        //this.props.visible = false;
    }

    render() {
        console.log(this.props.user, this.props.bookingEntry, this);
        console.log("payement will be made for ", this.props.user.user.user.name);

        return (
            <form onSubmit={this.handleSubmit}>
                <CardSection/>
                <br/>
                <span style={{color: '#E91E63'}}>{this.state.msg}</span>
                {/*<button className="btn-solid btn" disabled={!this.props.stripe}>Confirm order</button>*/}
                {/*<button className="btn-solid btn" onClick={this.props.okmodel}>Confirm order</button>*/}
            </form>
        );
    }
}

export default function InjectedCheckoutForm(props) {
    console.log(props);
    return (
        <ElementsConsumer>
            {({stripe, elements}) => (
                <CheckoutForm stripe={stripe} elements={elements} user={props.user} bookingEntry={props.bookingEntry}
                              okmodel={props.okmodel} onRef={props.onRef}/>
            )}
        </ElementsConsumer>
    );
}

// export default function InjectedCheckoutForm() {
//     console.log(this);
//     return (
//         <ElementsConsumer>
//             {({stripe, elements}) => (
//                 <CheckoutForm  stripe={stripe} elements={elements}  />
//             )}
//         </ElementsConsumer>
//     );
// }