import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'

class NavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navClose: { right: '0px' }
        }
    }

    componentWillMount() {
        if (window.innerWidth < 750) {
            this.setState({ navClose: { right: '-410px' } })
        }
        if (window.innerWidth < 1199) {
            this.setState({ navClose: { right: '-300px' } })
        }
    }

    openNav() {
        console.log('open')
        this.setState({ navClose: { right: '0px' } })
    }
    closeNav() {
        this.setState({ navClose: { right: '-410px' } })
    }

    onMouseEnterHandler() {
        if (window.innerWidth > 1199) {
            document.querySelector("#main-menu").classList.add("hover-unset");
        }
    }

    handleSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;

        if(event.target.nextElementSibling.classList.contains('opensubmenu'))
            event.target.nextElementSibling.classList.remove('opensubmenu')
        else{
            document.querySelectorAll('.nav-submenu').forEach(function (value) {
                value.classList.remove('opensubmenu');
            });
            document.querySelector('.mega-menu-container').classList.remove('opensubmenu')
            event.target.nextElementSibling.classList.add('opensubmenu')
        }
    }

    handleMegaSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;

        if(event.target.parentNode.nextElementSibling.classList.contains('opensubmegamenu'))
            event.target.parentNode.nextElementSibling.classList.remove('opensubmegamenu')
        else{
            document.querySelectorAll('.menu-content').forEach(function (value) {
                value.classList.remove('opensubmegamenu');
            });
            event.target.parentNode.nextElementSibling.classList.add('opensubmegamenu')
        }
    }

    render() {
        const { translate } = this.props;
        return (
            <div>
                <div className="main-navbar">
                    <div id="mainnav" >
                        <div className="toggle-nav" onClick={this.openNav.bind(this)} >
                            <i className="fa fa-bars sidebar-bar"></i>
                        </div>
                        <ul className="nav-menu" style={this.state.navClose}>
                            <li className="back-btn" onClick={this.closeNav.bind(this)} >
                                <div className="mobile-back text-right">
                                    <span style={{color: '#fff'}}>Back</span>
                                    <i className="fa fa-angle-right pl-2" aria-hidden="true" style={{color: '#fff'}}></i>
                                </div>
                            </li>
                            <li>
                                <Link to={`${process.env.PUBLIC_URL}/`} className="nav-link teNavItem"
                                      onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('home')}
                                </Link>
                            </li>
                            {/*<li className="mega-menu">*/}
                            {/*    <Link to={`#`} className="nav-link teNavItem"*/}
                            {/*          onClick={(e) => this.handleSubmenu(e)}>*/}
                            {/*        Consult*/}
                            {/*        <span className="sub-arrow" />*/}
                            {/*    </Link>*/}
                            {/*    <ul className="nav-submenu">*/}
                            {/*        <li><Link to={`/doctors/india/oncology`}>Cancer</Link></li>*/}
                            {/*        <li><Link to={`/doctors/india/cardiology`}>Cardiology</Link></li>*/}
                            {/*        <li><Link to={`/doctors/india/neuroscience`}>Neuroscience</Link></li>*/}
                            {/*        <li><Link to={`/doctors/india/transplants`}>Transplants</Link></li>*/}
                            {/*        <li><Link to={`/doctors/india/orthopedics`}>Orthopedics</Link></li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                            {/*==========*/}
                            <li className="mega-menu">
                                <Link to="#" className="dropdown teNavItem" onClick={(e) => this.handleSubmenu(e)}>
                                    Consult
                                    <span className="sub-arrow"/>
                                </Link>
                                {/*<div className="mega-menu-container" style={{ width: '40%',marginLeft: '40%'}}>*/}
                                <div className="mega-menu-container">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            Life Threatening
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content">
                                                        <ul>
                                                            <li><Link to={`/consult/cancer`}>Cancer</Link></li>
                                                            <li><Link to={`/consult/cardiology`}>Cardiology</Link></li>
                                                            <li><Link to={`/consult/neuroscience`}>Neuroscience</Link></li>
                                                            <li><Link to={`/consult/transplants`}>Transplants</Link></li>
                                                            <li><Link to={`/consult/orthopedics`}>Orthopedics</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            Optional
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content">
                                                        <ul>
                                                            <li><Link to={`/consult/fertility`}>Fertility</Link></li>
                                                            <li><Link to={`/consult/weight-loss`}>Weight Loss</Link></li>
                                                            <li><Link to={`/consult/cosmetic-surgery`}>Cosmetic surgery</Link></li>
                                                            <li><Link to={`/consult/general-surgery`}>General Surgery</Link></li>
                                                            <li><Link to={`/consult/preventive-medicine`}>Preventive Medicine</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </li>
                            {/*==========*/}
                            <li>
                                <Link to="#" className="nav-link teNavItem" onClick={(e) => this.handleSubmenu(e)}>
                                    About
                                    <span className="sub-arrow"/>
                                </Link>

                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>About Us</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>Quality Care</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>Lower Costs</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>End to end support</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>Treatments we offer</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>Our Mission</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>Vision</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`}>Partners</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/contact`}>Contact</Link></li>

                                </ul>
                            </li>
                            <li>
                                <Link to={`#`} className="nav-link teNavItem"
                                      onClick={(e) => this.handleSubmenu(e)}>
                                    Providers
                                    <span className="sub-arrow" />
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/hospitals/india`}>Hospitals</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/doctors/india`}>Doctors</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/doctors/india`}>Medicines</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/doctors/india`}>Tests</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to={`#`} className="nav-link teNavItem"
                                      onClick={(e) => this.handleSubmenu(e)}>
                                    How It Works
                                    <span className="sub-arrow" />
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/hospitals/india`}>Tele Consultation</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/doctors/india`}>Seek specialists opinion</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/doctors/india`}>Plan a health campaign in your country</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/doctors/india`}>For General Practitioners</Link></li>
                                </ul>
                            </li>


                            {/*<li>*/}
                            {/*    <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>*/}
                            {/*        {translate('pages')}*/}
                            {/*        <span className="sub-arrow"></span>*/}
                            {/*    </Link>*/}
                            {/*    <ul className="nav-submenu">*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`} >{translate('about_us')}</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/pages/404`} >404</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/pages/lookbook`} >{translate('lookbook')}</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/pages/login`} >{translate('login')}</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/pages/register`} >{translate('register')}</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/pages/search`} >{translate('search')}</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/pages/collection`} >{translate('collection')}</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/pages/forget-password`} >{translate('forget_password')}</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/pages/contact`} >{translate('contact')}</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/pages/dashboard`} >{translate('dashboard')}</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/pages/faq`} >{translate('FAQ')}</Link></li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                            {/*<li >*/}
                            {/*    <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>*/}
                            {/*        {translate('blog')}*/}
                            {/*        <span className="sub-arrow"></span>*/}
                            {/*    </Link>*/}
                            {/*    <ul className="nav-submenu">*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/blog/blog-page`} >{translate('blog_left_sidebar')}</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/blog/right-sidebar`} >{translate('blog_right_sidebar')}</Link></li>*/}
                            {/*        <li><Link to={`${process.env.PUBLIC_URL}/blog/details`} >{translate('blog_detail')}</Link></li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(NavBar);