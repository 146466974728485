import * as types from '../../constants/ActionTypes'

const helperReducerDefaultState = {
    prescription: false,
    rightBarConfig: {
        frameWidth: "100%",
        prescriptionWidth: "0px"
    }
};

const helperReducer = (state = helperReducerDefaultState, action) => {
    //console.log('DOCTOR/REDUCER : ', action);
    //console.log(action);
    switch (action.type) {
        case types.SWITCH_PRESCRIPTION_ON:
            return {
                prescription: true,
                rightBarConfig:{
                    frameWidth:"calc(100% - 450px)",
                    prescriptionWidth:"450px"
                }
            }
        case types.SWITCH_PRESCRIPTION_OFF:
            return {
                prescription: false,
                rightBarConfig: {
                    frameWidth: "100%",
                    prescriptionWidth: "0px"
                }
            };
        default:
            return state;
    }
}

export default helperReducer;