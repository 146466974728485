import React, {Component} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import {Link} from 'react-router-dom';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

class DoctorTopTabs extends Component {

    state = {
        showingInfoWindow: true,  //Hides or the shows the infoWindow
        activeMarker: {},          //Shows the active marker upon click
        selectedPlace: {}          //Shows the infoWindow to the selected place upon a marker
    };

    render (){
        const {symbol, item, addToCartClicked, BuynowClicked, addToWishlistClicked} = this.props

        console.log("doctor is : ", item)

        const mapStyles = {
            width: '100%',
            height: '100%',
            top:'0px',
        };



        return (
            <div className="mt-4 text-center">
            <div style={{position:'relative',paddingBottom:'56.25%'}}>
                {item.Geo?
                    <Map
                        google={this.props.google}
                        zoom={14}
                        style={mapStyles}
                        initialCenter={{lat:item.Geo.Latitude, lng:item.Geo.Longitude}}
                    >
                        <Marker
                            name={item.name}
                        />
                        {/*<InfoWindow*/}
                        {/*    marker={this.state.activeMarker}*/}
                        {/*    visible={true}*/}
                        {/*    //onClose={this.onClose}*/}
                        {/*>*/}
                        {/*    <div>*/}
                        {/*        <h4>{item.name}</h4>*/}
                        {/*    </div>*/}
                        {/*</InfoWindow>*/}
                    </Map>
                    :null}

            </div>
        </div>
            // <section className="tab-product m-0">
            //     <div className="row">
            //         <div className="col-sm-12 col-lg-12">
            //             <Tabs className="tab-content nav-material">
            //                 <TabList className="nav nav-tabs nav-material">
            //                     <Tab className="nav-item">
            //                         <span className="nav-link active">
            //                             <i className="icofont icofont-ui-home"></i>Description</span>
            //                         <div className="material-border"></div>
            //                     </Tab>
            //                     {/*<Tab className="nav-item">*/}
            //                     {/*    <span className="nav-link" ><i className="icofont icofont-man-in-glasses"></i>Details</span>*/}
            //                     {/*    <div className="material-border"></div>*/}
            //                     {/*</Tab>*/}
            //                     <Tab className="nav-item">
            //                         <span className="nav-link" >
            //                             <i className="icofont icofont-contacts"></i>Video</span>
            //                         <div className="material-border"></div>
            //                     </Tab>
            //                     <Tab className="nav-item">
            //                         <span className="nav-link" >
            //                             <i className="icofont icofont-contacts"></i>Location</span>
            //                         <div className="material-border"></div>
            //                     </Tab>
            //                     {/*<Tab className="nav-item">*/}
            //                     {/*    <span className="nav-link" >*/}
            //                     {/*        <i className="icofont icofont-contacts"></i>Write Review</span>*/}
            //                     {/*    <div className="material-border"></div>*/}
            //                     {/*</Tab>*/}
            //                 </TabList>
            //                 <TabPanel className="tab-pane fade mt-4 show active">
            //                     <table className="table table-striped mb-0">
            //                         <tbody>
            //                         <tr>
            //                             <th>Experience</th>
            //                             <td>{item.Experience}</td>
            //                         </tr>
            //                         {/*<tr>*/}
            //                         {/*    <th>Memberships :</th>*/}
            //                         {/*    <td>{item.Memberships}</td>*/}
            //                         {/*</tr>*/}

            //                         {/*<tr>*/}
            //                         {/*    <th>Awards</th>*/}
            //                         {/*    <td>{item.Awards}</td>*/}
            //                         {/*</tr>*/}
            //                         <tr>
            //                             <th>Department</th>
            //                             <td>{item.Department}</td>
            //                         </tr>
            //                         <tr>
            //                             <th>Designation</th>
            //                             <td>{item.Designation}</td>
            //                         </tr>
            //                         <tr>
            //                             <th>Specialication</th>
            //                             <td>{item.Specialization? item.Specialization.map((itm,indx)=>{
            //                                 return (<span key={indx}>{itm} </span>)
            //                             }):""}</td>
            //                         </tr>
            //                         </tbody>
            //                     </table>
            //                 </TabPanel>
            //                 {/*<TabPanel>*/}
            //                 {/*    <p className="mt-4 p-0">*/}
            //                 {/*        Lorem Ipsum is simply dummy text of the printing and*/}
            //                 {/*        typesetting industry. Lorem Ipsum has been the industry's*/}
            //                 {/*        standard dummy text ever since the 1500s, when an unknown*/}
            //                 {/*        printer took a galley of type and scrambled it to make a*/}
            //                 {/*        type specimen book. It has survived not only five centuries,*/}
            //                 {/*        but also the leap into electronic typesetting, remaining*/}
            //                 {/*        essentially unchanged. It was popularised in the 1960s with*/}
            //                 {/*        the release of Letraset sheets containing Lorem Ipsum*/}
            //                 {/*        passages, and more recently with desktop publishing software*/}
            //                 {/*        like Aldus PageMaker including versions of Lorem Ipsum.*/}
            //                 {/*    </p>*/}
            //                 {/*</TabPanel>*/}
            //                 <TabPanel>
            //                     <div className="mt-4 text-center">
            //                         <div className="embed-responsive embed-responsive-16by9">
            //                             <iframe
            //                                 // src="https://www.youtube.com/embed/BUWzX78Ye_8"
            //                                 src={item.Video}
            //                                 allow="autoplay; encrypted-media"
            //                                 allowFullScreen>
            //                             </iframe>
            //                         </div>
            //                     </div>
            //                 </TabPanel>
            //                 <TabPanel>
            //                     <div className="mt-4 text-center">
            //                         <div style={{position:'relative',paddingBottom:'56.25%'}}>
            //                             <Map
            //                                 google={this.props.google}
            //                                 zoom={14}
            //                                 style={mapStyles}
            //                                 initialCenter={{lat:item.Geo.Latitude, lng:item.Geo.Longitude}}
            //                             >
            //                                 <Marker
            //                                     name={item.name}
            //                                 />
            //                                 {/*<InfoWindow*/}
            //                                 {/*    marker={this.state.activeMarker}*/}
            //                                 {/*    visible={true}*/}
            //                                 {/*    //onClose={this.onClose}*/}
            //                                 {/*>*/}
            //                                 {/*    <div>*/}
            //                                 {/*        <h4>{item.name}</h4>*/}
            //                                 {/*    </div>*/}
            //                                 {/*</InfoWindow>*/}
            //                             </Map>
            //                         </div>
            //                     </div>
            //                 </TabPanel>
            //                 {/*<TabPanel>*/}
            //                 {/*    <form className="theme-form mt-4">*/}
            //                 {/*        <div className="form-row">*/}
            //                 {/*            <div className="col-md-12 ">*/}
            //                 {/*                <div className="media m-0">*/}
            //                 {/*                    <label>Rating</label>*/}
            //                 {/*                    <div className="media-body ml-3">*/}
            //                 {/*                        <div className="rating three-star">*/}
            //                 {/*                            <i className="fa fa-star"></i>*/}
            //                 {/*                            <i className="fa fa-star"></i>*/}
            //                 {/*                            <i className="fa fa-star"></i>*/}
            //                 {/*                            <i className="fa fa-star"></i>*/}
            //                 {/*                            <i className="fa fa-star"></i>*/}
            //                 {/*                        </div>*/}
            //                 {/*                    </div>*/}
            //                 {/*                </div>*/}
            //                 {/*            </div>*/}
            //                 {/*            <div className="col-md-6">*/}
            //                 {/*                <label htmlFor="name">Name</label>*/}
            //                 {/*                <input type="text" className="form-control" id="name" placeholder="Enter Your name" required />*/}
            //                 {/*            </div>*/}
            //                 {/*            <div className="col-md-6">*/}
            //                 {/*                <label htmlFor="email">Email</label>*/}
            //                 {/*                <input type="text" className="form-control" id="email" placeholder="Email" required />*/}
            //                 {/*            </div>*/}
            //                 {/*            <div className="col-md-12">*/}
            //                 {/*                <label htmlFor="review">Review Title</label>*/}
            //                 {/*                <input type="text" className="form-control" id="review" placeholder="Enter your Review Subjects" required />*/}
            //                 {/*            </div>*/}
            //                 {/*            <div className="col-md-12">*/}
            //                 {/*                <label htmlFor="review">Review Title</label>*/}
            //                 {/*                <textarea className="form-control" placeholder="Wrire Your Testimonial Here" id="exampleFormControlTextarea1" rows="6"></textarea>*/}
            //                 {/*            </div>*/}
            //                 {/*            <div className="col-md-12">*/}
            //                 {/*                <button className="btn btn-solid" type="submit">Submit YOur Review</button>*/}
            //                 {/*            </div>*/}
            //                 {/*        </div>*/}
            //                 {/*    </form>*/}
            //                 {/*</TabPanel>*/}
            //             </Tabs>
            //         </div>
            //     </div>
            // </section>
        )
    }
}

//export default DetailsTopTabs;
export default GoogleApiWrapper({
    apiKey: 'AIzaSyClT2rSG4_sAQr3HPFxxenREGLsr__Pk9c'
})(DoctorTopTabs);