import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import '../../commonAll/index.scss';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import ThemeSettings from "../../commonAll/theme-settings"

// Import custom components
import {
    svgFreeShipping,
    svgservice,
    svgoffer,
    svgpayment
} from "../../../services/script"
import TopCollection from "../../layouts/common/collection"
import NewProduct from "../../commonAll/new-product"
import Instagram from "../../layouts/common/instagram"
import HeaderOne from "../../commonAll/headers/header-one"
import FooterOne from "../../commonAll/footers/footer-one"
import BlogSection from "../../layouts/common/blogsection";
import HeaderFive from "../../commonAll/headers/header-five";
import Pace from "react-pace-progress";
import appConfig from "../../../config";
import axios from "axios";
import TrandingCollection from "../common/collection";
import {Skeleton} from "antd";
import FooterFour from "../../commonAll/footers/footer-four";
import Trending from "../home/trending";
import Special from "../common/special";

class Cancer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            isLoading: false,
            homeData: {},
            param: {},
            selectedDoctorOption: [],
            selectedHospitalOption: [],
            selectedSpecialOption: [],
            suggestionsDoc: [],
            suggestionsHos: [],
            suggestionsSpcl: appConfig.docspecial,
        }

        // this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color3.css` );
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color2.css`);
        this.getHomeContent();
    }

    getHomeContent() {
        axios
            .get(appConfig.home)
            .then(response => {
                // Handle success.
                // console.log('HOME-PAGE: Data Received', response.data);
                let homeData = response.data;
                this.setState({
                    homeData
                });
            })
    }

    // constructor(props){
    //     super(props)
    //
    //     this.state = {
    //         open: false
    //     }
    // }

    onOpenModal = () => {
        this.setState({open: true});
    };

    onCloseModal = () => {
        this.setState({open: false});
    };


    render() {
        const {homeData, selectedDoctorOption, selectedHospitalOption, selectedSpecialOption, suggestionsDoc, suggestionsHos, suggestionsSpcl} = this.state;

        return (
            <div>
                <Helmet>
                    <title>Treatment Excellence</title>
                </Helmet>
                <HeaderFive logoName={'logo-lite.png'}/>

                {this.state.isLoading ? <Pace color="#27ae60"/> : null}

                {/*<Helmet>*/}
                {/*    <title>MultiKart | Beauty Store</title>*/}
                {/*</Helmet>*/}
                {/*<HeaderOne logoName={'layout3/logo.png'}/>*/}
                {/*<section className="p-0">*/}
                {/*    <Slider className="slide-1 home-slider">*/}
                {/*        <div>*/}
                {/*            <div className="home home34">*/}
                {/*                <div className="container">*/}
                {/*                    <div className="row">*/}
                {/*                        <div className="col">*/}
                {/*                            <div className="slider-contain">*/}
                {/*                                <div>*/}
                {/*                                    <h4>welcome to beauty</h4>*/}
                {/*                                    <h1>beauty products</h1><a href="#" className="btn btn-solid">shop*/}
                {/*                                    now</a></div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <div className="home home35">*/}
                {/*                <div className="container">*/}
                {/*                    <div className="row">*/}
                {/*                        <div className="col">*/}
                {/*                            <div className="slider-contain">*/}
                {/*                                <div>*/}
                {/*                                    <h4>save 30% off</h4>*/}
                {/*                                    <h1>beauty products</h1><a href="#" className="btn btn-solid">shop*/}
                {/*                                    now</a></div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </Slider>*/}
                {/*</section>*/}


                <br/>
                <br/>

                {/*About Section*/}
                <section className="beauty-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 col-md-12 offset-xl-1 text-center">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/consulting.jpg`} alt=""
                                     className="img-fluid blur-up lazyload"/>
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-12">
                                <div className="about-section">
                                    <div>
                                        <h2>Consult A Oncology Specialist</h2>
                                        <div className="about-text">
                                            <p>Get priority consults from our handpicked specialist Doctors. The basic
                                                criteria to be on our website as a specialist, is to be on the forefront
                                                of latest, cutting edge medical practices and having great contributions
                                                in peer read medical journals.</p>

                                            <p>In addition to above basic criteria, we use our team’s years of
                                                experience in healthcare, and now, after having helped over 17,000
                                                International patients, our team (of Doctors and patient care managers),
                                                have carefully worked together to handpick specialist Doctors and their
                                                respective teams as our providers on our website.
                                            </p>
                                        </div>
                                        {/*<div className="service small-section pb-0">*/}
                                        {/*    <div className="row">*/}
                                        {/*        <div className="col-sm-4 service-block1">*/}
                                        {/*            <div dangerouslySetInnerHTML={{__html: svgFreeShipping}}/>*/}
                                        {/*            <h5>free shipping</h5>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="col-sm-4 service-block1">*/}
                                        {/*            <div dangerouslySetInnerHTML={{__html: svgservice}}/>*/}
                                        {/*            <h5>24 X 7 service</h5>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="col-sm-4 service-block1">*/}
                                        {/*            <div dangerouslySetInnerHTML={{__html: svgoffer}}/>*/}
                                        {/*            <h5>festival offer</h5>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*About Section End*/}
                <br/>
                <br/>
                {/*Product slider*/}
                {homeData.hospitals ? <Trending type={'doctor'} home={homeData}/> :
                    <Skeleton active avatar paragraph={{rows: 5}}/>}


                <section className="beauty-about">
                    <br/>
                    <br/>
                    <div className="container">
                        <div className="row">
                            {/*<div className="col-xl-5 col-lg-6 col-md-12 offset-xl-1 text-center">*/}
                            {/*    <img src={`${process.env.PUBLIC_URL}/assets/images/beauty/about-us.jpg`} alt=""*/}
                            {/*         className="img-fluid blur-up lazyload"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-xl-5 col-lg-6 col-md-12">*/}
                            <div className="col">
                                <div className="about-section" style={{border: '1px solid #000', padding: '30px'}}>
                                    <div>
                                        <h2>Highest Quality Guaranteed</h2>
                                        <div className="about-text">
                                            <p>Our stringent criterion includes treatment outcomes at the top; followed
                                                by quality of experience, patient care by self and support team,
                                                friendliness with patient, and post treatment support.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <br/>
                <br/>

                {homeData.hospitals ?
                    <TopCollection type={'hospital'} hospitals={homeData.hospitals} showHeading={"No"}/> :
                    <Skeleton active avatar paragraph={{rows: 5}}/>}


                {/*{homeData.hospitals ?*/}
                {/*    <TopCollection type={'hospital'} hospitals={homeData.hospitals} showHeading={"No"}/> :*/}
                {/*    <Skeleton active avatar paragraph={{rows: 5}}/>}*/}
                {/*<TopCollection type={'beauty'} />*/}
                {/*Product slider End*/}

                {/*Video Section*/}
                {/*<section className="video-section pt-0">*/}
                {/*    <div className="title1">*/}
                {/*        <h4>special offer</h4>*/}
                {/*        <h2 className="title-inner1">product tutorial</h2>*/}
                {/*    </div>*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-8 offset-md-2">*/}
                {/*                <a href="javascript:void(0)" onClick={this.onOpenModal}>*/}
                {/*                    <div className="video-img">*/}
                {/*                        <img src={`${process.env.PUBLIC_URL}/assets/images/beauty/video_1.jpg`} alt=""*/}
                {/*                             className="img-fluid blur-up lazyload"/>*/}
                {/*                        <div className="play-btn">*/}
                {/*                            <span><i className="fa fa-play" aria-hidden="true"></i></span>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </a>*/}
                {/*                <Modal*/}
                {/*                    open={this.state.open}*/}
                {/*                    onClose={this.onCloseModal}*/}
                {/*                    id="video"*/}
                {/*                    className="modal fade video-modal" center>*/}
                {/*                    <iframe width="560" height="315" src="https://www.youtube.com/embed/LeH2-ROgbmI"*/}
                {/*                            frameBorder="0"*/}
                {/*                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                {/*                            allowFullScreen></iframe>*/}
                {/*                    /!*<iframe src="https://www.youtube.com/embed/FRIDLxM8Roc"*!/*/}
                {/*                    /!*        allowFullScreen></iframe>*!/*/}
                {/*                </Modal>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/*Video Section End*/}

                <br/>
                {/*Product slider*/}
                {homeData.featuredDoctors ? <Special type={'doctor'} featured={homeData.featuredDoctors}/> :
                    <Skeleton active avatar paragraph={{rows: 15}}/>}
                {/*<TopCollection type={'beauty'} />*/}
                {/*Product slider End*/}

                {/*Blog Section*/}
                {/*<div className="container">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col">*/}
                {/*            <div className="title1">*/}
                {/*                <h4>Recent Story</h4>*/}
                {/*                <h2 className="title-inner1">from the blog</h2>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<section className="blog p-t-0 ratio3_2">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-12">*/}
                {/*                <BlogSection/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/*Blog Section end*/}

                <section className="beauty-about">
                    <br/>
                    <br/>
                    <div className="container">
                        <div className="row">
                            {/*<div className="col-xl-5 col-lg-6 col-md-12 offset-xl-1 text-center">*/}
                            {/*    <img src={`${process.env.PUBLIC_URL}/assets/images/beauty/about-us.jpg`} alt=""*/}
                            {/*         className="img-fluid blur-up lazyload"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-xl-5 col-lg-6 col-md-12">*/}
                            <div className="col">
                                <div className="about-section">
                                    <div>
                                        <h4>Please Note</h4>
                                        <div className="about-text">
                                            <ul style={{listStyleType: 'lower-alpha'}}>
                                                <li>
                                                    <span>
                                                        Online consultation is not a substitute for real, in clinic consultation with the Doctor; though this is becoming more and more acceptable to Doctor and patient both across the world.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        The outcome of the consultation could be a prescribed lab test, follow up consultation; and may or may not end up in medicine being prescribed. Often, an opinion is provided.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Please upload all medical reports (latest only), along with medical history of the patient before the consultation.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        For follow – up consults, each Doctor has his criteria for charging fee. Some offer free follow up, some others charge for it.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        In case a procedure is advised, it is mostly required to come to Doctors clinic for a final opinion and plan.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Getting an opinion is Free.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Online consultation is paid. Fee varies for each specialist.
                                                    </span>
                                                </li>
                                            </ul>
                                            {/*<p>Our stringent criterion includes treatment outcomes at the top; followed*/}
                                            {/*    by quality of experience, patient care by self and support team,*/}
                                            {/*    friendliness with patient, and post treatment support.</p>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*Instagram Section*/}
                <div className="section-b-space">
                    {/*<Instagram type="watch" />*/}
                </div>
                {/*Instagram Section End*/}

                {/*<FooterOne logoName={'layout3/logo.png'}/>*/}
                <FooterFour logoName={'logo-dark.png'}/>

                {/*<ThemeSettings/>*/}
            </div>
        )
    }
}


export default Cancer;