import React, {Component} from 'react';
// import {Link} from 'react-router-dom';
// import {getCartTotal, getUser, printDateTime} from "../../../services/index";
import axios from "axios";
import appConfig from "../../../config";
// import {decrementQty, incrementQty, removeFromCart} from "../../../redux/actions";
import {logout} from "../../../redux/actions/authActions";
import {connect} from "react-redux";
// import Multiselect from "react-bootstrap-multiselect"
// import { WithContext as ReactTags } from 'react-tag-input';
import Select from 'react-select';


class PrescriptionForm extends Component {

    constructor() {
        super();
        this.state = {
            city: {},
            bookings: [],
            diagnosis: "",
            followupDate: "",
            selectedOption: [],
            med: "",
            selectedFreq: "",
            suggestions: [
                {value: 'chocolate', label: 'Chocolate'},
                {value: 'strawberry', label: 'Strawberry'},
                {value: 'vanilla', label: 'Vanilla'},

                {value: 'USA', label: 'USA'},
                {value: 'Germany', label: 'Germany'},
                {value: 'Austria', label: 'Austria'},
                {value: 'Costa Rica', label: 'Costa Rica'},
                {value: 'Sri Lanka', label: 'Sri Lanka'},
                {value: 'Thailand', label: 'Thailand'}
            ],
            freqOptions: [
                {value: 'SOS', label: 'SOS'},
                {value: 'OD', label: 'OD'},
                {value: 'BD', label: 'BD'},
                {value: 'TDS', label: 'TDS'},
                {value: 'QID', label: 'QID'},
                {value: 'HS', label: 'HS'},
                {value: 'BBF', label: 'BBF'},
            ],
            medecine: [],
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitPrescription = this.submitPrescription.bind(this);
    }

    componentDidMount() {
        console.log("PRESCRIPTION FORM");
        this.getTests();
    }

    getTests() {
        let user = this.props.user;
        console.log("user for test ", user);
        axios
            .get(appConfig.tests, {
                headers: {
                    Authorization: 'Bearer ' + user.jwt
                }
            })
            .then(response => {
                // Handle success.
                let suggestions = response.data.map(itm => {
                    return ({value: itm.id, label: itm.Name})
                })
                console.log('tests data', response.data, suggestions);
                this.setState({suggestions})
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
    }

    handleChange(event) {
        //console.log('handleChange', this);
        const {name, value} = event.target;
        console.log(name, value, event.target.value);
        console.log('handleChange', this);
        const {user} = this.state;
        this.setState({
            [name]: value
        });
    }

    handleTestChange = selectedOption => {
        this.setState(
            {selectedOption},
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };
    handleFreqChange = selectedFreq => {
        this.setState(
            {selectedFreq},
            () => console.log(`Option selected:`, this.state.selectedFreq)
        );
    };

    submitPrescription() {
        let data = {
            Diagnosis: this.state.diagnosis,
            FollowUp: this.state.followupDate,

            Medicine: [],
            tests: [],

            booking_order: this.props.booking.id,
            doctor: this.props.booking.doctor,
            doctorUser: this.props.user.user.id,
            user: this.props.patient.id,

        }
        this.state.selectedOption.map(test => {
            data.tests.push(test.value)
        })
        this.state.medecine.map(med => {
            data.Medicine.push({
                Drug: med.med,
                Frequency: med.freq
            })
        })
        console.log("PRESCRIPTION submit data is ", data);
        this.uploadPrescription(data);
    }

    uploadPrescription(data) {
        axios
            .post(appConfig.prescriptions, data, {
                headers: {
                    Authorization: 'Bearer ' + this.props.user.jwt
                }
            })
            .then(response => {
                console.log('PRESCRIPTION Uploaded', response.data);
                //this.setState({reports: response.data});
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
    }

    getMedTable(medecine) {
        return (<div>
            <table className="table cart-table table-responsive-xs"
                   style={{border: '1px solid #ddd'}}>
                <thead>
                <tr className="table-head">
                    <th scope="col" style={{paddingTop: '20px'}}>Medecine</th>
                    <th scope="col" style={{paddingTop: '20px'}}>Frequency</th>
                    <th scope="col" style={{paddingTop: '20px'}}>Remove</th>
                </tr>
                </thead>
                {medecine.map((item, index) => {
                    return (
                        <tbody key={index}>
                        <tr>
                            <td>{item.med}</td>
                            <td>{item.freq}</td>
                            <td>X</td>
                        </tr>
                        </tbody>
                    )
                })
                }
            </table>
        </div>)
    }

    render() {
        const {patient, booking, user} = this.props;
        const {tags, suggestions, freqOptions, selectedOption, selectedFreq, medecine} = this.state;
        console.log("PRESCRIPTION FORM doctor :", user);
        console.log("PRESCRIPTION FORM patient :", patient);
        console.log("PRESCRIPTION FORM booking :", booking);

        window.cnfT = this;
        window.confstate = this.state;
        window.confprops = this.props;

        return (
            <div className="dashboard">
                <div className="col-md-12">
                    <label htmlFor="review">Diagnosis</label>
                    <textarea className="form-control"
                              placeholder="Write Diagnosis Here"
                              name="diagnosis" value={this.state.diagnosis}
                              onChange={this.handleChange}
                              id="diagnosis" rows="6" required/>
                </div>
                <div className="col-md-12">
                    <label htmlFor="review">Tests</label>
                    <div style={{color: '#000'}}>
                        <Select
                            value={selectedOption}
                            onChange={this.handleTestChange}
                            options={suggestions}
                            isMulti
                            isSearchable
                            style={{color: '#000'}}
                        />
                    </div>
                </div>


                <div className="form-row" style={{padding: '20px'}}>
                    <div className="col-md-6">
                        <label htmlFor="review">Medicine</label>
                        <input type="text" className="form-control" id="med" name="med"
                               value={this.state.med}
                               onChange={this.handleChange}
                               placeholder="Medicine" required=""/>

                    </div>
                    <div className="col-md-4">
                        <label htmlFor="review">Frequency</label>
                        <div style={{color: '#000'}}>
                            <Select
                                value={selectedFreq}
                                onChange={this.handleFreqChange}
                                options={freqOptions}
                                style={{color: '#000'}}
                            />
                        </div>

                    </div>
                    <div className="col-md-2" style={{height: '38px'}}>
                        <label htmlFor="review">Action</label>
                        <button
                            style={{marginTop: '6px'}}
                            onClick={() => {
                                this.setState({
                                    medecine: [...medecine, {
                                        med: this.state.med,
                                        freq: this.state.selectedFreq.value
                                    }]
                                })
                                console.log("med clicked");
                            }}>
                            Add
                        </button>
                    </div>
                </div>

                {medecine.length ? this.getMedTable(medecine) : ""}


                <div style={{bottom: '15px', position: 'absolute'}}>
                    <div className="col-md-12">
                        <label htmlFor="review">Follow Up</label>
                        <div style={{color: '#000'}}>
                            <input type="date"
                                   className="form-control"
                                   id="followupDate"
                                   name="followupDate"
                                   value={this.state.followupDate}
                                   onChange={this.handleChange}
                                   placeholder="Report Date"
                                   required=""/>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <label htmlFor="review">Note: Once Submitted the prescription can not be changed.</label>
                        <div className="btn btn-solid"
                             style={{marginLeft: '0px', marginTop: '20px'}}
                             onClick={this.submitPrescription}>Submit
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
})
export default connect(mapStateToProps, {logout})(PrescriptionForm)
