import React from 'react';
import {Link} from 'react-router-dom';
import {printDateTime} from "../../../../services/index";

const CartHeader = ({item, total, symbol, removeFromCart}) => (
    <li>
        <div className="media">
            <Link to={`${process.env.PUBLIC_URL}/doctor/${item.slug}`}><img alt="" className="mr-3"
                                                                            src={`${item.Pictures[0].url}`}/></Link>
            <div className="media-body">
                <Link to={`${process.env.PUBLIC_URL}/doctor/${item.slug}`}><h4>{item.Name}</h4></Link>
                {/*<h4><span>{item.qty} x {symbol} {(item.price*item.discount/100)} {item.bType}</span></h4>*/}
                <h4><span>{item.bType}</span><br/><span>{printDateTime(item.bTime)}</span></h4>
            </div>
        </div>
        {/*<span>{cart}</span>*/}
        <div className="close-circle">
            <a href="/#" onClick={removeFromCart} ><i className="fa fa-times" aria-hidden="true"/></a>
        </div>
    </li>
)


export default CartHeader;
