import * as types from "../../constants/ActionTypes";
import axios from "axios";
import appConfig from "../../config";
import { notification } from "antd";
// Auth Service
export const setCurrentUser = (user) => ({
  type: types.SET_CURRENT_USER,
  user,
});

export const login = (data) => (dispatch) =>
  axios.post(appConfig.loginUrl, data).then((response) => {
    console.log("Well done!");
    console.log("User", response.data);
    console.log("User profile", response.data.user);
    console.log("User token", response.data.jwt);
    localStorage.setItem("user", JSON.stringify(response.data));
    //window.open(`${process.env.PUBLIC_URL}/`, '_self');
    dispatch(setCurrentUser(response.data));

    notification.success({
      message: "Login Successful",
    });

    return response;
  });

export const setUser = (data) => (dispatch) => {
  console.log("ACTIONS/AUTH: setUser");
  dispatch(setCurrentUser(data));
};

export const logout = () => (dispatch) => {
  console.log("ACTIONS/AUTH: logout");
  localStorage.removeItem("user");
  dispatch({
    type: types.LOGOUT,
  });
};
