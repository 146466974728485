import {combineReducers} from 'redux';
import {IntlReducer as Intl} from 'react-redux-multilingual'

// Import custom components
import productReducer from './products';
import cartReducer from './cart';
import filtersReducer from './filters';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import authReducer from "./auth";
import hospitalReducer from "./hospitals";
import doctorReducer from "./doctors";
import helperReducer from "./helpers";


const rootReducer = combineReducers({
    data: productReducer,
    cartList: cartReducer,
    filters: filtersReducer,
    wishlist: wishlistReducer,
    compare: compareReducer,
    auth: authReducer,
    hospital: hospitalReducer,
    doctors: doctorReducer,
    helpers: helperReducer,
    Intl
});

export default rootReducer;