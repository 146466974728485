import { Col, Row } from 'antd';
import React, { Component } from 'react';
import Slider from 'react-slick';

import {Slider6} from "../../../services/script";

class LogoBlock extends Component {

    render (){
        {/* <section className="section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"> */}
        return (    
            <Row style={{margin:"10px",padding:"20px 0"}}>

                <Col span={24}>


                            <Slider {...Slider6} className="slide-6 no-arrow">
                                <div>
                                    <div className="logo-block" >
                                        {/*<a href={null}>*/}
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/logos/1.png`} alt="" style={{width: '150px', height: '125px', objectFit: 'contain'}} />
                                        {/*</a>*/}
                                    </div>
                                </div>

                                <div>
                                    <div className="logo-block">
                                        {/*<a href={null}>*/}
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/logos/2.png`} alt="" style={{width: '150px', height: '125px', objectFit: 'contain'}}/>
                                        {/*</a>*/}
                                    </div>
                                </div>



                                <div>
                                    <div className="logo-block">
                                        {/*<a href={null}>*/}
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/logos/3.png`} alt="" style={{width: '150px', height: '125px', objectFit: 'contain'}}/>
                                        {/*</a>*/}
                                    </div>
                                </div>
                                <div>
                                    <div className="logo-block">
                                        {/*<a href={null}>*/}
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/logos/4.jpg`} alt="" style={{width: '150px', height: '125px', objectFit: 'contain'}}/>
                                        {/*</a>*/}
                                    </div>
                                </div>

                                <div>
                                    <div className="logo-block">
                                        {/*<a href={null}>*/}
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/logos/5.jpg`} alt="" style={{width: '150px', height: '125px', objectFit: 'contain'}}/>
                                        {/*</a>*/}
                                    </div>
                                </div>

                                <div>
                                    <div className="logo-block">
                                        {/*<a href={null}>*/}
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/logos/6.jpg`} alt="" style={{width: '150px', height: '125px', objectFit: 'contain'}}/>
                                        {/*</a>*/}
                                    </div>
                                </div>

                                <div>
                                    <div className="logo-block">
                                        {/*<a href={null}>*/}
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/logos/7.jpg`} alt="" style={{width: '150px', height: '125px', objectFit: 'contain'}}/>
                                        {/*</a>*/}
                                    </div>
                                </div>

                                <div>
                                    <div className="logo-block">
                                        {/*<a href={null}>*/}
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/logos/8.png`} alt="" style={{width: '150px', height: '125px', objectFit: 'contain'}}/>
                                        {/*</a>*/}
                                    </div>
                                </div>

                                <div>
                                    <div className="logo-block">
                                        {/*<a href={null}>*/}
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/logos/9.jpg`} alt="" style={{width: '150px', height: '125px', objectFit: 'contain'}}/>
                                        {/*</a>*/}
                                    </div>
                                </div>

                                <div>
                                    <div className="logo-block">
                                        {/*<a href={null}>*/}
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/logos/10.jpg`} alt="" style={{width: '150px', height: '125px', objectFit: 'contain'}}/>
                                        {/*</a>*/}
                                    </div>
                                </div>

                                <div>
                                    <div className="logo-block">
                                        {/*<a href={null}>*/}
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/logos/4.jpg`} alt="" style={{width: '150px', height: '125px', objectFit: 'contain'}}/>
                                        {/*</a>*/}
                                    </div>
                                </div>


                                {/*<div>*/}
                                {/*    <div className="logo-block">*/}
                                {/*        /!*<a href={null}>*!/*/}
                                {/*            <img src={`${process.env.PUBLIC_URL}/assets/images/logos/4.png`} alt="" />*/}
                                {/*        /!*</a>*!/*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <div className="logo-block">*/}
                                {/*        /!*<a href={null}>*!/*/}
                                {/*            <img src={`${process.env.PUBLIC_URL}/assets/images/logos/4.png`} alt="" />*/}
                                {/*        /!*</a>*!/*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </Slider>
                </Col>

            </Row>
            
            //             </div>
            //         </div>
            //     </div>
            // </section>
        )
    }
}

export default LogoBlock;