import React, { Component } from "react";
import axios from "axios";

import Breadcrumb from "../commonAll/breadcrumb";
import appConfig from "../../config";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        uname: "",
        phone: "",
        email: "",
        password: "",
        type: "user",
      },
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let href = window.location.href;
    let result = href.split("/");

    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        type: result[5],
      },
    });
  }

  handleChange(event) {
    const { name, value } = event.target;

    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  userRegister = () => {
    this.setState({ submitted: true });
    const { user } = this.state;
    if (user.uname && user.phone && user.email && user.password) {
      //this.props.register(user);

      axios
        .post(appConfig.register, {
          username: user.email,
          email: user.email,
          password: user.password,
          name: user.uname,
          phone: user.phone,
          type: user.type,
        })
        .then((response) => {
          // Handle success.

          localStorage.setItem("user", JSON.stringify(response.data));
          window.open(`${process.env.PUBLIC_URL}/`, "_self");
        })
        .catch((error, resp) => {
          // Handle error.
        });
    }
  };

  render() {
    return (
      <div>
        <Breadcrumb title={"create account"} />

        {/*Regsiter section*/}
        <section className="register-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/*<h3>create account</h3>*/}
                <div className="theme-card">
                  <form className="theme-form">
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="uname">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="uname"
                          name="uname"
                          value={this.state.user.uname}
                          onChange={this.handleChange}
                          placeholder="Name"
                          required=""
                        />
                        {this.state.submitted && !this.state.user.uname && (
                          <div className="help-block">Name is required</div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone">Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                          value={this.state.user.phone}
                          onChange={this.handleChange}
                          placeholder="Phone"
                          required=""
                        />
                        {this.state.submitted && !this.state.user.phone && (
                          <div className="help-block">Phone is required</div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="email">email</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          value={this.state.user.email}
                          onChange={this.handleChange}
                          placeholder="Email"
                          required=""
                        />
                        {this.state.submitted && !this.state.user.email && (
                          <div className="help-block">Email is required</div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="review">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="review"
                          name="password"
                          value={this.state.user.password}
                          onChange={this.handleChange}
                          placeholder="Enter your password"
                          required=""
                        />
                        {this.state.submitted && !this.state.user.password && (
                          <div className="help-block">Password is required</div>
                        )}
                      </div>
                      {/* <div className="col-md-6">
                                                <label htmlFor="type">User Type</label>
                                                <select  className="form-control" id="type" name="type"onChange={this.handleChange}
                                                       required>
                                                           <option selected value="user">User</option>
                                                           <option value="doctor">Doctor</option>
                                                           <option value="hospital">Hospital</option>
                                                           </select>

                                                {this.state.submitted && !this.state.user.type &&
                                                <div className="help-block">User type is Required</div>
                                                }
                                            </div> */}
                      <div className="col-md-6">
                        {/* <label htmlFor="review">Password</label>
                                                <input type="password" className="form-control" id="review" name="password" value={this.state.user.password} onChange={this.handleChange}
                                                       placeholder="Enter your password" required=""/>
                                                {this.state.submitted && !this.state.user.password &&
                                                <div className="help-block">Password is required</div>
                                                } */}
                      </div>
                      {/*<a href="#" className="btn btn-solid">create Account</a>*/}
                      {/*<button className="btn btn-solid"*/}
                      {/*        style={{marginLeft: '10px'}} >Create*/}
                      {/*    Account*/}
                      {/*</button>*/}
                      <div
                        className="btn btn-solid cardBtn"
                        style={{ marginLeft: "10px", marginTop: "20px" }}
                        onClick={this.userRegister}
                      >
                        Create Account
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Register;
