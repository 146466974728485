import React from 'react';
import {Select } from 'antd';

const {Option} = Select;
const submenuList=({name,value,handleSpeclChange,optionValues,speclName})=>{
    
    return(
        
        <li className="li-list">
        
        <h4>{name}</h4>
        <Select
            // mode="multiple"
            allowClear
            showSearch
            bordered={false}
            showArrow={false}
            style={{width: '100%', border: 'none'}}
            placeholder="Please select"
            defaultValue={value ? value :[]}
            onChange={(e) => handleSpeclChange(e, '', speclName)}
            className={'headerSearch'}
        >
            {optionValues.map((item, index) => {
                return (
                    <Option key={index} value={item.slug} label={item.label}>
                           {item.label}
                    </Option>
                    )
                })}
        </Select>
    </li>
    )
}
export default submenuList;