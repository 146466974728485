import * as types from '../../constants/ActionTypes'

let user = JSON.parse(localStorage.getItem('user'));

const authReducerDefaultState = user ? {
    isLogedIn: true,
    user
} : {isLogedIn: false};

const authReducer = (state = authReducerDefaultState, action) => {
    //console.log('AUTH REDUCER: ', action);
    switch (action.type) {
        // case types.GET_USER:
        //     return {
        //         ...state,
        //         user: authReducerDefaultState
        //     };
        case types.SET_CURRENT_USER:
            return{
                isLogedIn: true,
                user: action.user
            }
        case types.LOGOUT:
            return {
                isLogedIn: false,
            };
        default:
            return state;
    }
}

export default authReducer;