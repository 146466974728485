import React, {Component} from 'react';
import { Link} from 'react-router-dom';

import {SlideUpDown} from "../../../services/script"
import LogoImage from "../headers/common/logo"

class FooterFour extends Component {

    componentDidMount(){
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function(elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }


    render () {

        return (
            <footer className="">
                {/*<div className="white-layout">*/}
                {/*    <div className="container">*/}
                {/*        <section className="small-section">*/}
                {/*            /!*<div className="row">*!/*/}
                {/*            /!*    <div className="col-xl-6 offset-xl-3">*!/*/}
                {/*            /!*        <div className="subscribe">*!/*/}
                {/*            /!*            <h4>newsletter</h4>*!/*/}
                {/*            /!*            <form className="form-inline subscribe-form classic-form">*!/*/}
                {/*            /!*                <div className="form-group">*!/*/}
                {/*            /!*                    <input type="text" className="form-control" id="exampleFormControlInput"*!/*/}
                {/*            /!*                           placeholder="Enter your email" />*!/*/}
                {/*            /!*                </div>*!/*/}
                {/*            /!*                <button type="submit" className="btn btn-solid">subscribe</button>*!/*/}
                {/*            /!*            </form>*!/*/}
                {/*            /!*        </div>*!/*/}
                {/*            /!*    </div>*!/*/}
                {/*            /!*</div>*!/*/}
                {/*        </section>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <section className="section-b-space darken-layout">
                    <div className="container">
                        <div className="row footer-theme partition-f">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-title footer-mobile-title">
                                    <h4>about</h4>
                                </div>
                                <div className="footer-contant">
                                    <div className="footer-logo">
                                        <LogoImage logo={this.props.logoName} />
                                    </div>
                                    <p>At Treatment Traveller, we are here to help you reach out to the best in healthcare. Providing an ideal platform which comprises of
                                        doctors, healthcare specialists and communication professionals. Thereby, aiding the search of patients towards receiving treatment in the
                                        prestigious hospitals in India. </p>
                                    <div className="footer-social">
                                        <ul>
                                            <li>
                                                <Link to={'https://www.facebook.com/'} ><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={'https://plus.google.com/'} ><i className="fa fa-google-plus" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={'https://twitter.com'}><i className="fa fa-twitter" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={'https://instagram.com'}><i className="fa fa-instagram" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={'https://rss.com/'}><i className="fa fa-rss" aria-hidden="true"></i></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col offset-xl-1">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>my account</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul>
                                            <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >Hospitals</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >Doctors</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >Pharmacies</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >Labs</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>why we choose</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul>
                                            <li><a href={`${process.env.PUBLIC_URL}/pages/login`}>Privacy Policy</a></li>
                                            <li><a href={`${process.env.PUBLIC_URL}/pages/login`}>Terms & Conditions</a></li>
                                            <li><a href={`${process.env.PUBLIC_URL}/pages/login`}>Secure Transactions</a></li>
                                            <li><a href={`${process.env.PUBLIC_URL}/pages/login`}>Refund Policy</a></li>
                                            <li><a href={`${process.env.PUBLIC_URL}/pages/login`}>FAQ's</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>store information</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul className="contact-list">
                                            <li><i className="fa fa-map-marker"/>Treatment Traveller
                                                India 201301
                                            </li>
                                            <li><i className="fa fa-phone"/>Call Us: +91-8860-40-6501</li>
                                            <li><i className="fa fa-envelope-o"/>Email Us: <a
                                                href={`${process.env.PUBLIC_URL}/pages/login`}>Support@treatmenttraveller.com</a></li>
                                            {/*<li><i className="fa fa-fax"></i>Fax: 123456</li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="sub-footer dark-subfooter">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="footer-end">
                                    <p><i className="fa fa-copyright" aria-hidden="true"/> 2019-20 Treatment Traveller</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="payment-card-bottom">
                                    <ul>
                                        <li>
                                            <a href={`${process.env.PUBLIC_URL}/pages/login`}><img src={`${process.env.PUBLIC_URL}/assets/images/icon/visa.png`} alt="" /></a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.PUBLIC_URL}/pages/login`}><img src={`${process.env.PUBLIC_URL}/assets/images/icon/mastercard.png`} alt="" /></a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.PUBLIC_URL}/pages/login`}><img src={`${process.env.PUBLIC_URL}/assets/images/icon/paypal.png`} alt="" /></a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.PUBLIC_URL}/pages/login`}><img src={`${process.env.PUBLIC_URL}/assets/images/icon/american-express.png`} alt="" /></a>
                                        </li>
                                        <li>
                                            <a href={`${process.env.PUBLIC_URL}/pages/login`}><img src={`${process.env.PUBLIC_URL}/assets/images/icon/discover.png`} alt="" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default FooterFour;