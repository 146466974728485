import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Link, withRouter} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {getDoctors} from "../../../redux/actions/doctorActions";
import {Skeleton} from "antd";

import {getTotal, getCartProducts} from '../../../redux/reducers'
import {addToCart, addToWishlist, addToCompare,filterAddCities,filterDCity} from '../../../redux/actions'
import {
    getVisibleproducts,
    getVisibleDoctors,
    getDoctorsForSearchParam,
    getHospitalsForSearchParam, searchKeywords
} from '../../../services';
//import ProductListItem from "./_old/product-list-item";
import DoctorListItem from "./doctor-list-item";
import SearchCheck from "../../search/search";
import {getParams, getSearchParamID, getDoctors as searchDoctors} from "../../../api/apicalls";


class SearchListing extends Component {

    constructor(props) {
        super(props)

        this.state = {
            limit: 12,
            hasMoreItems: true,
            searchResult: [],
            filteredResults:[],
        };
    }

    componentWillMount() {

        if (this.props.location.search) {
            this.getListingData();
            // eslint-disable-next-line no-restricted-globals
            // getDoctorsForSearchParam(getParams(location.href)).then(result => {
            //     console.log("SEARCH :: Result of listing is : ", result)
            //     if (result && result.length) {
            //         this.setState({
            //             searchResult: [...result]
            //         })
            //     }
            // });
        } else {
            // this.setState({
            //     searchResult: [...this.props.doctors.doctors]
            // })
        }

        // eslint-disable-next-line no-restricted-globals
        // getHospitalsForSearchParam(getParams(location.href)).then(result => {
        //     console.log("Result of listing is : ", result)
        //     this.setState({
        //         searchResult: [...this.state.searchResult, ...result]
        //     })
        // });

    }


   


    filterData(){
        let result=this.state.searchResult;
        let {filterDoc} = this.props;
        console.log("filterDOcty",filterDoc);
        
       let tempResult=[];
       result.map(product=>{
      //  return product;
        if((product.Rating<=filterDoc.drate[1] && product.Rating>=filterDoc.drate[0])&& 
            (product.Experience<=filterDoc.experience[1] && product.Experience>=filterDoc.experience[0])&&
            (filterDoc.dCheckedCity.length?filterDoc.dCheckedCity.includes(product.City):true)&&
             (product.Price<=filterDoc.dprices[1] && product.Price>=filterDoc.dprices[0])){
                tempResult.push(product);
              
             }
            
             
        })
      let  filteredResults = tempResult;
        filteredResults = filteredResults.filter(product=>filterDoc.availableFor.videoConference?product.OnVideo===filterDoc.availableFor.videoConference :true).filter(product=>filterDoc.availableFor.onCall?product.OnPhone===filterDoc.availableFor.onCall:true).filter(product=>filterDoc.availableFor.inHospital?product.OnLocation===filterDoc.availableFor.inHospital:true);

       console.log("hi",filteredResults)
       this.setState({filteredResults:filteredResults})
    //    filter(product=>(product.Rating<=filterDoc.drate.max && product.Rating>=filterDoc.drate.min)).filter(product=>(product.Experience<=filterDoc.experience.max && product.Experience>=filterDoc.experience.min))
    //    .filter(product=>(product.Price<=filterDoc.dprices.max && product.Price>=filterDoc.dprices.min))
      
    // //    map((product)=>{
    // //     if((product.Rating<=filterDoc.drate.max && product.Rating>=filterDoc.drate.min)&& 
    // //     (product.Experience<=filterDoc.experience.max && product.Experience>=filterDoc.experience.min)&&
    // //     (product.OnVideo===filterDoc.availableFor.videoConference && product.OnPhone===filterDoc.availableFor.onCall && product.OnLocation===filterDoc.availableFor.inHospital)&&
    // //      (product.Price<=filterDoc.dprices.max && product.Price>=filterDoc.dprices.min)){
    // //          return product
    // //      }
    // // })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            // this.getSearchParam();
        }
        if(prevProps.searchString !== this.props.searchString){
            console.log("SEARCH-LISTING :: searchString Updated")
            this.getListingData();
        }
        console.log("prevProps.filterDoc",prevProps.filterDoc);
        console.log("this.props.filterDoc",this.props.filterDoc);
           if(JSON.stringify(prevProps.filterDoc)!==JSON.stringify(this.props.filterDoc)){
                this.filterData();
           }
      
         
           
       
    }
    

    getListingData = () => {
        // eslint-disable-next-line no-restricted-globals
        let params = getParams(location.href)
        let prms = [];
        // console.log("SEARCH :: params for url are : ", params);
        if(Object.keys(params).length && Object.keys(params)[0]!==''){
            Object.keys(params).map((param, idx) => {
                // console.log("SEARCH :: The individual Param is : ", param, ' - ', params[param]);
                prms.push(getSearchParamID(param, params[param].replaceAll('-', ' ')))
            })
            Promise.all(prms).then(resp => {
                // console.log("SEARCH :: ALL PROMISE : ", resp);
                let qer = '';
                resp.map(query => {
                    // console.log("SEARCH :: queary is : ", query[0])
                    qer = qer.length ? qer + "&" + searchKeywords[query[0].category] + '=' + query[0].id : searchKeywords[query[0].category] + '=' + query[0].id
                })
                console.log("SEARCH :: Final Queary is : ", qer)
                searchDoctors('?'+qer).then(r=>{
                    this.setState({searchResult: [...r],filteredResults:[...r]});
                    let cities=[];
                    r.forEach(element => {
                        if(cities.indexOf(element.City)===-1)
                        cities.push(element.City);
                    });
                    this.props.handleCity(cities);
                  
                    this.props.filterAddCities(cities);
                    this.props.filterDCity(cities);
                })
            })
        }


    }

    fetchMoreItems = () => {
        // console.log("reached ", this.state.limit, this.props.doctors.doctors.length)
        if (this.state.limit >= this.state.searchResult.length) {
            this.setState({hasMoreItems: false});
            return;
        }
        // a fake async api call
        setTimeout(() => {
            this.setState({
                limit: this.state.limit + 8
            });
        }, 1000);


    }

    render() {
        console.log("search-listing::Filters",this.props.filters);
        const {products, doctors, addToCart, symbol, addToWishlist, addToCompare,filters} = this.props;
        const {searchResult,filteredResults} = this.state;
        console.log("filteredResultss:",filteredResults);
        window.dlstate = this.state;
        window.srclistingprops = this.props;
        // console.log("DOCTOR/LISTING-PAGE props : ", this.props);
        // console.log("DOCTOR/LISTING-PAGE state : ", this.state);

        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        {(filteredResults.length && filteredResults.length > 0 )?
                            <InfiniteScroll
                                dataLength={this.state.limit} //This is important field to render the next data
                                next={this.fetchMoreItems}
                                hasMore={this.state.hasMoreItems}
                                // loader={<div className="loading-cls"></div>}
                                endMessage={
                                    <p className="seen-cls seen-it-cls">
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            >
                                <div className="row">
                                   
                                    {/*{ doctors.doctors.slice(0, this.state.limit).map((product, index) =>*/}
                                    {/*{searchResult.slice(0, this.state.limit).map((product, index) =>*/}
                                    { filteredResults.map((product, index) =>{
                                       
                                        return(
                                            
                                        <div className={`${'col-lg-12'}`} key={index}>
                                            <SearchCheck  type="Doctor" data={product}/>                                    
                                        </div>)
                                    
                                    })
                                    }
                                </div>
                            </InfiniteScroll>
                            :
                            <Skeleton active avatar paragraph={{rows: 7}}/>
                        }
                        {    ( filteredResults.length && filteredResults.length ==0 )?
                             <div className="row">
                             <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                 <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                                      className="img-fluid mb-4"/>
                                 <h3>Sorry! Couldn't find the result you were looking For!!! </h3>
                                 <p>Please check if you have misspelt something or try searching with other
                                     words.</p>
                                 <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue
                                     </Link>
                             </div>
                         </div>:null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    // products: getVisibleDoctors(state.data, state.filters),
    // eslint-disable-next-line no-restricted-globals
    // products: getDoctorsForSearchParam(getParams(location.href)),

    searchString: state.filters.searchString,

    doctors: state.doctors,
    filters: state.filters,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare, getDoctors,filterAddCities,filterDCity}
)(withRouter(SearchListing))
