import {
    FETCH_SINGLE_PRODUCT,
    CHANGE_CURRENCY,
    RECEIVE_PRODUCTS,
    FETCH_SUGGESTIONS,
    RECEIVE_SUGGESTIONS,
    RECEIVE_SUGGESTIONS_TREATMENTS,
    RECEIVE_SUGGESTIONS_CONDITIONS,
    RECEIVE_SUGGESTIONS_SPECIALISATIONS,
    RECEIVE_SUGGESTIONS_HOSPITALS,
    RECEIVE_SUGGESTIONS_DOCTORS,
} from "../../constants/ActionTypes";


const initialState = {
    products: [],
    symbol: '₹',
    product_details: [],
    home: {},
    treatments: [],
    conditions: [],
    specialisations: [],
    hospitals: [],
    doctors: [],
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_PRODUCTS:
            return {
                ...state,
                products: action.products
            };
        case FETCH_SINGLE_PRODUCT:
            if (state.products.findIndex(product => product.id === action.productId) !== -1) {
                const singleItem = state.products.reduce((itemAcc, product) => {
                    return product
                }, [])
                return {
                    ...state,
                    product_details: singleItem
                };
            }
            // TODO: test if we need break here
            break
        case CHANGE_CURRENCY:
            return {
                ...state,
                symbol: action.symbol
            };
        case RECEIVE_SUGGESTIONS:
            return {
                ...state,
                treatments: action.treatments,
                conditions: action.conditions,
                specialisations: action.specialisations,
                hospitals: action.hospitals,
                doctors: action.doctors
            };
        case RECEIVE_SUGGESTIONS_TREATMENTS:
            return {...state, treatments: action.treatments};
        case RECEIVE_SUGGESTIONS_CONDITIONS:
            return {...state, conditions: action.conditions};
        case RECEIVE_SUGGESTIONS_SPECIALISATIONS:
            return {...state, specialisations: action.specialisations};
        case RECEIVE_SUGGESTIONS_HOSPITALS:
            return {...state, hospitals: action.hospitals};
        case RECEIVE_SUGGESTIONS_DOCTORS:
            return {...state, doctors: action.doctors};

        default:
            return state;
    }
};
export default productReducer;
