import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import HeaderFive from "../commonAll/headers/header-five";
import TopBarDark from "../commonAll/headers/common/topbar-dark";
import HeaderIframe from "../commonAll/headers/header-iframe";
import {getCartTotal} from "../../services";
import {connect} from "react-redux";
import {decrementQty, incrementQty, removeFromCart} from "../../redux/actions";
import {logout} from "../../redux/actions/authActions";
import axios from "axios";
import appConfig from "../../config";
import PrescriptionForm from "./common/prescriptionForm";

class Conference extends Component {

    constructor(props) {
        super(props)
        this.state = {
            url: "https://conf.html5.run/pages/r.html?room=Varun%27s&p=eyJsc1JlcFVybCI6Imh0dHBzOi8vY29uZi5odG1sNS5ydW4vIiwibmFtZXMiOiJWYXJ1biIsInBhc3MiOiJhZG1pbiIsImlzQWRtaW4iOjF9&isAdmin=1",
            booking: {},
            rightBar: true,
            rightBarConfig: {
                frameWidth: "calc(100% - 450px)",
                prescriptionWidth: "450px"
            },
            prescription: this.props.prescription
        }
        this.switchRightBar = this.switchRightBar.bind(this);
    };

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color2.css`);
        const {auth, bookingID} = this.props;
        this.getBooking(bookingID, auth);
    };

    getBooking(bookingID, auth) {
        let param = "/" + bookingID;
        axios
            .get(appConfig.bookingOrder + param, {
                headers: {
                    Authorization: 'Bearer ' + auth.user.jwt
                }
            })
            .then(response => {
                // Handle success.
                // console.log('CONFERENCE/BOOKING: Data Received', response.data);
                let booking = response.data;
                let url = booking.Bookings[0].BookingURL;
                // TODO : uncomment this
                if(auth.user.user.type==="doctor"){
                    url+="&isAdmin=1"
                }
                this.setState({
                    booking,
                    url
                });
            })
    }

    switchRightBar(prescription) {
        //console.log("switch")
        if (prescription) {
            this.setState({
                rightBarConfig: {
                    frameWidth: "calc(100% - 450px)",
                    prescriptionWidth: "450px"
                }
            })
        } else {
            this.setState({
                rightBarConfig: {
                    frameWidth: "100%",
                    prescriptionWidth: "0px"
                }
            })
        }
    }

    getBody = (booking, auth) => {
        if (auth.user.user.type === "doctor") {
            // Showing Precribtion Bar
            return (<div style={{width: "100%", backgroundColor: '#000', color: "#fff", height: '100%'}}>
                <div style={{
                    width: this.props.rightBarConfig.frameWidth,
                    backgroundColor: '#000',
                    color: "#fff",
                    height: '100%',
                    float: 'left'
                }}>
                    <iframe
                        src={this.state.url}
                        allowFullScreen
                        style={{width: '100%', height: '100%', border: 'none'}}
                        allow="geolocation; microphone; camera *;"/>
                </div>
                <div style={{
                    width: this.props.rightBarConfig.prescriptionWidth,
                    height: '100%',
                    backgroundColor: '#133973',
                    float: 'right'
                }}>
                    {/*<span onClick={this.switchRightBar}>switch</span>*/}
                    <PrescriptionForm patient={this.state.booking.user} booking={this.state.booking}/>
                </div>
            </div>)
        } else {
            return (<div style={{width: "100%", backgroundColor: '#000', color: "#fff", height: '100%'}}>
                <iframe //src="https://conf.html5.run/pages/r.html?room=Varun%27s&p=eyJsc1JlcFVybCI6Imh0dHBzOi8vY29uZi5odG1sNS5ydW4vIiwibmFtZXMiOiJWYXJ1biIsInBhc3MiOiJhZG1pbiIsImlzQWRtaW4iOjF9&isAdmin=1"
                    src={this.state.url}
                    allowFullScreen
                    style={{width: '100%', height: '100%', border: 'none'}}
                    allow="geolocation; microphone; camera *;"/>
            </div>)
        }
    }

    render() {
        const {auth, bookingID, prescription} = this.props;
        const {booking} = this.state;
        // console.log("CONFERENCE state : ", this.state);
        // console.log("CONFERENCE props : ", this.props);

        return (

            <div>
                <Helmet>
                    <title>Conference</title>
                </Helmet>

                <HeaderIframe logoName={'logo.png'} booking={booking}/>
                <div style={{height: 'calc(100vh - 55px)'}}>
                    {/*<Breadcrumb title={'404 Page'}/>*/}
                    {booking ? this.getBody(booking, auth) : <span>Booking Not Found</span>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    bookingID: ownProps.match.params.id,
    prescription: state.helpers.prescription,
    rightBarConfig: state.helpers.rightBarConfig,
})

export default connect(
    mapStateToProps,
    {removeFromCart, incrementQty, decrementQty, logout}
)(Conference)