import React, {Component} from "react";
import "../commonAll/index.scss";
import {connect} from "react-redux";
import ImageZoom from "../products/common/product/image-zoom";
import SmallImages from "../products/common/product/small-image";
import {addToCart, addToCartUnsafe, addToWishlist} from "../../redux/actions";
import Slider from "react-slick";
import DoctorBooking from "../products/common/product/Doctor-Booking";
import {Rate} from "antd";
import {Link} from "react-router-dom";
import {Typography} from "antd";

const {Text, Title} = Typography;

class Doctor extends Component {
    constructor(props) {
        super();
        this.state = {
            nav1: null,
            nav2: null,
            item: {},
            doctors: [],
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
        let doctor = this.props.data;
        // console.log("hospital", this.props.data);

        this.setState({doctor});
        // console.log("doctor-card::doctor", doctor);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            let doctor = this.props.data;

            this.setState({doctor});
        }
    }

    render() {
        const {
            symbol,
            addToCart,
            addToCartUnsafe,
            addToWishlist,
            auth,
        } = this.props;
        let products = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            fade: true,
        };
        let productsnav = {
            slidesToShow: 3,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true,
        };

        const item = this.state.doctor;
        const placeholder = `${process.env.PUBLIC_URL}/assets/images/doctors/Doctor-Male-Placeholder.jpg`;
        return (
            <div>
                {item ? (
                    <div
                        className="row searchCard"
                        style={{
                            // paddingLeft: "0",
                            // paddingRight: "0",
                            borderRight: "1px solid #d4d4d4",
                            borderTop: "3px solid #14a3dd",
                            borderBottom: "2px solid #d4d4d4",
                            padding: "0px",
                        }}
                    >
                        <div className="col-lg-3 col-md-4" style={{padding: "0px"}}>
                            <Link to={`${process.env.PUBLIC_URL}/doctor/${item.slug}`}>
                                <ImageZoom
                                    image={item.Pictures[0] ? item.Pictures[0].url : placeholder}
                                    style={{
                                        height: "220px",
                                        width: "100%",
                                    }}
                                />
                                {/* {item.Pictures.length ?
                                        <Slider {...products} asNavFor={this.state.nav2}
                                            ref={slider => (this.slider1 = slider)}
                                            className="product-right-slick">
                                            {item.Pictures.map((vari, index) =>
                                                <div key={index}>
                                                    <ImageZoom image={vari.url} style={{
                                                      height:"220px",
                                                      width:"100%"
                                                    }}
                                                        className="img-fluid image_zoom_cls-0" />
                                                </div>
                                            )}
                                        </Slider>
                                        : <Slider {...products} asNavFor={this.state.nav2}
                                            ref={slider => (this.slider1 = slider)}
                                            className="product-right-slick">
                                            <div>
                                                <ImageZoom
                                                    image={"/assets/images/doctors/Doctor-Male-Placeholder.jpg"}
                                                   style={{ height:"220px",
                                                      width:"100%"
                                                    }}
                                                    className="img-fluid image_zoom_cls-0" />
                                            </div>
                                        </Slider>} */}
                            </Link>
                        </div>
                        <div className="col-lg-6 col-md-8">
                            <Link to={`${process.env.PUBLIC_URL}/doctor/${item.slug}`}>
                                <h3 style={{marginBottom: "-5px"}}>
                                    {" "}
                                    {item.Name}{" "}
                                    {item.Verified ? (
                                        <img
                                            src={`${
                                                process.env.PUBLIC_URL
                                            }/assets/images/icons/verified.svg`}
                                            style={{width: "20px", paddingBottom: "10px"}}
                                            alt={"verified"}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </h3>
                            </Link>
                            <Title style={{margin: "2px 0 0 0"}} level={4}>
                                {item.Designation}
                            </Title>

                            <Text>
                                Department:<Text strong>{item.Department}</Text>
                            </Text>
                            <br/>

                            {/* <Text>Designation:<Text strong>{item.Designation}</Text></Text><br /> */}


                            {/*<Text>Specialities:</Text>*/}
                            {/*<br/>*/}
                            {/*<Text strong>{item.Specialization?item.Specialization.toString():"N/A"}</Text>*/}
                            <br/>
                            <Rate
                                disabled
                                value={item.Rating}
                                style={{fontSize: 17, color: "#ffa201"}}
                            />
                            <div
                                className="row"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "5px",
                                }}
                            >
                                <div className="col">
                                    <button
                                        className="btn btn-solid cardBtn"
                                        style={{padding: "10px"}}
                                    >
                                        Favroute
                                    </button>
                                </div>
                                <div className="col">
                                    <button
                                        className="btn btn-solid cardBtn"
                                        style={{padding: "10px"}}
                                    >
                                        Get Opinion
                                    </button>
                                </div>
                                <div className="col">
                                    <button
                                        className="btn btn-solid cardBtn"
                                        // className="btn btn-warning"
                                        style={{padding: "10px"}}
                                    >
                                        Live Chat
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3"
                            style={{borderLeft: "1px solid #d4d4d4"}}
                        >
              <span strong style={{textAlign: "center"}}>
                YEARS OF EXPERIENCE
              </span>
                            <br/>
                            <Title level={4} style={{textAlign: "center"}}>
                                {item.Experience}
                            </Title>
                            <DoctorBooking symbol={symbol} item={item}/>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.data.slug;
    return {
        item: state.data.products.find((el) => el.id == productId),
        symbol: state.data.symbol,
        doctors: state.doctors,
        did: productId,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
    {addToCart, addToCartUnsafe, addToWishlist}
)(Doctor);
//export default Doctor;
