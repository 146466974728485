import React, { Component } from "react";
import Slider from "react-slick";
import "../commonAll/index.scss";
import { connect } from "react-redux";

// import custom Components
import RelatedProduct from "../commonAll/related-product";
import Breadcrumb from "../commonAll/breadcrumb";
import Details from "./common/product/details";
//import Doctordetails from "./common/product/details-doctor";
import Price from "./common/product/price";
import PriceDoctor from "./common/product/price-doctor";
import DoctorDetails from "./common/product/DoctorDetails";
import DetailsTopTabs from "./common/details-top-tabs";
import DoctorTopTabs from "./common/doctor-top-tabs";
import { addToCart, addToCartUnsafe, addToWishlist } from "../../redux/actions";
import ImageZoom from "./common/product/image-zoom";
import SmallImages from "./common/product/small-image";
import axios from "axios";
import appConfig from "../../config";
import _ from "lodash";
import CommentsHosital from "./common/comments/comments-hosital";
import Interweave from "interweave";
import { Rate, Skeleton } from "antd";
import { Link as ReactLink } from "react-router-dom";
import { Anchor } from "antd";

const { Link } = Anchor;

class Doctor extends Component {
  constructor() {
    super();
    this.state = {
      nav1: null,
      nav2: null,
      isLoading: true,
      top: "",
    };
  }

  listenScrollEvent = (e) => {
    // console.log(window.scrollY)
    if (window.scrollY > 100) {
      this.setState({ top: "42px" });
    } else {
      this.setState({ top: "" });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
    document.getElementById("sticky").style.display = "none";
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
    const {
      match: { params },
    } = this.props;
    this.doctorId = params.id;
    // console.log("DOCTOR/ITEM-PAGE State : ", this.state);
    // console.log("DOCTOR/ITEM-PAGE Props : ", this.props);
    // console.log("DOCTOR/ITEM-PAGE useParams : ", params);

    let d = this.props.doctors.doctors;
    let did = this.props.did;
    let doctor = _.find(d, { slug: did });
    if (doctor) {
      //console.log('DOCTOR/ITEM: Data Found', doctor);
      this.setState({ doctor, isLoading: false });
    } else {
      this.getDoctor(params.id);
    }
  }

  getDoctor(id) {
    // let param = "/" + id;
    let param = "?slug=" + id;
    console.log("param is ", param);
    axios.get(appConfig.doctors + param).then((response) => {
      // Handle success.
      //console.log('DOCTOR/ITEM: Data Received', response.data);
      let doctor = response.data[0];
      this.setState({ doctor, isLoading: false });
    });
  }

  render() {
    const {
      symbol,
      addToCart,
      addToCartUnsafe,
      addToWishlist,
      auth,
    } = this.props;
    var products = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      fade: true,
    };
    var productsnav = {
      slidesToShow: 3,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
    };
    let item = this.state.doctor;
    console.log("doctor::item", item);
    //console.log("DOCTOR/ITEM-PAGE : ", item);
    window.diprops = this.props;
    window.distate = this.state;

    return (
      <div className="doctor-body doc-main container">
        {item ? (
          <div>
            {item ? (
              <div
                className="price-doctor-inside-card hide-me"
                style={{ top: this.state.top }}
              >
                <PriceDoctor
                  symbol={symbol}
                  item={item}
                  navOne={this.state.nav1}
                  addToCartClicked={addToCart}
                  BuynowClicked={addToCartUnsafe}
                />
              </div>
            ) : null}
            <section id="Overview" style={{ paddingTop: "0" }}>
              {item ? (
                <div className="doctor-container">
                  <div className="doctor-inner-container">
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "10px",
                      }}
                    >
                      <div className="doctor-page-card dark-bg">
                        <div className="summary">
                          <div style={{ marginRight: "24px" }}>
                            {item.Pictures.length ? (
                              <img
                                className="designated-provider-image"
                                src={item.Pictures[0].url}
                              />
                            ) : (
                              <img
                                class="designated-provider-image"
                                src="/assets/images/doctors/Doctor-Male-Placeholder.jpg"
                              />
                            )}
                          </div>
                          <div>
                            <div style={{ color: "#fff" }} className="h2">
                              {item.Name}
                            </div>
                            <p style={{ color: "#fff" }}>
                              <span>{item.Designation} </span>•&nbsp;
                              <span> Male </span>
                              <span>•&nbsp;Age 60</span>
                            </p>
                            <Rate
                              disabled
                              value={
                                item.hospital && item.hospital.Rating
                                  ? item.hospital.Rating
                                  : 0
                              }
                            />
                          </div>
                        </div>
                        <div className="make-appt row">
                          <div className="col-sm-10" />
                          <div className="col-sm-2">
                            <p style={{ color: "#fff", fontWeight: "bold" }}>
                              <span> Share | Save</span>
                            </p>
                          </div>
                        </div>
                        <div className="doctor-about-container">
                          <hr style={{ backgroundColor: "#fff" }} />
                          <p style={{ color: "#fff" }} className="about-doctor">
                            {item.Description}
                          </p>
                          <hr style={{ backgroundColor: "#fff" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Skeleton active avatar paragraph={{ rows: 7 }} />
              )}
            </section>

            <Anchor>
              <Link
                href={`/doctor/${this.doctorId}#Overview`}
                title="Overview"
              />
              <Link href={`/doctor/${this.doctorId}#Rating`} title="Rating" />
              <Link
                href={`/doctor/${this.doctorId}#Awards`}
                title="Awards & Membership"
              />
              <Link
                href={`/doctor/${this.doctorId}#HospitalSection`}
                title="Hospital"
              />
            </Anchor>

            <div
              style={{ marginLeft: "auto", marginRight: "auto" }}
              className="container-fluid"
            >
              <section id="Rating">
                {item ? (
                  <section className="tab-product m-0">
                    {/* <div className="container"> */}
                    <div className="row">
                      <div className="col-sm-12 col-lg-12">
                        <CommentsHosital
                          item={item}
                          auth={auth}
                          type={"doctor"}
                        />
                      </div>
                    </div>
                    {/* </div> */}
                  </section>
                ) : (
                  <Skeleton active avatar paragraph={{ rows: 7 }} />
                )}
              </section>

              <section id="Awards">
                <section>
                  {item && item.Memberships ? (
                    <div className="borderDesc container">
                      <h4 className="product-title">Memberships :</h4>
                      <Interweave content={item.Memberships} />
                      {/*{item.UniqueContribution}*/}
                    </div>
                  ) : null}
                </section>
                <section>
                  {item && item.Awards ? (
                    <div className="borderDesc container">
                      <h4 className="product-title">Awards :</h4>
                      <Interweave content={item.Awards} />
                      {/*{item.UniqueContribution}*/}
                    </div>
                  ) : null}
                </section>
              </section>

              <section id="HospitalSection">
                {item && item.hospital ? (
                  <section>
                    <div className="media">
                      <div className="borderDesc container">
                        <h3>Hospital</h3>
                        <ReactLink
                          to={`${process.env.PUBLIC_URL}/hospital/${
                            item.hospital.Alias
                          }`}
                        >
                          <img
                            className="img-fluid"
                            src={`${item.hospital.Pictures[0].url}`}
                            alt=""
                            style={{ width: "400px", margin: "0" }}
                          />
                        </ReactLink>
                        <div className="media-body align-self-center">
                          <Rate
                            disabled
                            value={item.hospital.Rating}
                            style={{ fontSize: 14, color: "#ffa201" }}
                          />
                          <ReactLink
                            to={`${process.env.PUBLIC_URL}/hospital/${
                              item.hospital.Alias
                            }`}
                          >
                            <h6>{item.hospital.Name}</h6>
                          </ReactLink>
                        </div>
                        <DoctorTopTabs item={item} />
                      </div>
                    </div>
                  </section>
                ) : null}
              </section>
            </div>
          </div>
        ) : (
          <Skeleton active avatar paragraph={{ rows: 7 }} />
        )}
      </div>

      //     <div style={{backgroundColor:"#ECECEC"}} >
      //     <div className='container inPageFrame'>
      //     {/*<div className='container inPageFrame' style={{margin:'0 10% 0 10%'}}>*/}
      //     {/*<div style={{margin:'0 10% 0 10%',boxShadow: "0px 3px 5px 5px rgba(0,0,0,0.15)"}}>*/}

      //         <Breadcrumb title={' Doctor / ' + (item ? item.Name : " ")}/>

      //         {/*Section Start*/}
      //         {(item) ?
      //             <section>
      //                 <div className="collection-wrapper">
      //                     <div className="container">
      //                         <div className="row">
      //                             <div className="col-lg-4 product-thumbnail">
      //                             <h3 style={{marginBottom: '5px'}}> {item.Name} {item.Verified?<img src={`${process.env.PUBLIC_URL}/assets/images/icons/verified.svg`} style={{width:'20px', paddingBottom:'10px'}} alt={"verified"}/>:''}</h3>
      //                                 {item.Pictures.length ?
      //                                     <Slider {...products} asNavFor={this.state.nav2}
      //                                             ref={slider => (this.slider1 = slider)}
      //                                             className="product-right-slick">
      //                                         {item.Pictures.map((vari, index) =>
      //                                             <div key={index}>
      //                                                 <ImageZoom image={vari.url}
      //                                                            className="img-fluid image_zoom_cls-0"/>
      //                                             </div>
      //                                         )}
      //                                     </Slider>
      //                                     : <Slider {...products} asNavFor={this.state.nav2}
      //                                               ref={slider => (this.slider1 = slider)}
      //                                               className="product-right-slick">
      //                                         <div>
      //                                             <ImageZoom
      //                                                 image={"/assets/images/doctors/Doctor-Male-Placeholder.jpg"}
      //                                                 className="img-fluid image_zoom_cls-0"/>
      //                                         </div>
      //                                     </Slider>}

      //                                 {/*<br/>*/}
      //                                 {/*<br/>*/}
      //                                 {/*{item.hospital ? <div className="media">*/}
      //                                 {/*    <Link to={`${process.env.PUBLIC_URL}/doctor/${item.hospital.Alias}`}>*/}
      //                                 {/*        <img className="img-fluid" src={`${item.hospital.Pictures[0].url}`} alt="" style={{width:'200px', margin:'20px'}}/>*/}
      //                                 {/*    </Link>*/}
      //                                 {/*    <div className="media-body align-self-center">*/}
      //                                 {/*        <Rate disabled value={item.hospital.Rating} style={{ fontSize: 14, color: '#ffa201' }}/>*/}
      //                                 {/*        <Link to={`${process.env.PUBLIC_URL}/doctor/${item.hospital.Alias}`}>*/}
      //                                 {/*            <h6>{item.hospital.Name}</h6></Link>*/}

      //                                 {/*    </div>*/}
      //                                 {/*</div> : ""}*/}

      //                             </div>
      //                             {/* Product Details */}
      //                             {/* <Doctordetails item={item} addToWishlistClicked={addToWishlist}/> */}
      //                             <DoctorDetails item={item} />
      //                             {/* Product Price Details */}
      //                             <PriceDoctor symbol={symbol} item={item} navOne={this.state.nav1}
      //                                          addToCartClicked={addToCart} BuynowClicked={addToCartUnsafe}/>
      //                             {/*<img src={"/assets/images/not-available.jpg"}  className="img-fluid image_zoom_cls-0" />*/}

      //                         </div>
      //                     </div>
      //                 </div>
      //             </section> : <Skeleton active avatar paragraph={{rows: 7}}/>}
      //         {/*Section End*/}

      //         <br/>
      //         <br/>

      //         <section>
      //             <div className="collection-wrapper">
      //                 <div className="container">
      //                     <div className="row">
      //                         <div className="col-md-8">
      //                             {/*<div>*/}
      //                             {(item) ?
      //                                 <section>
      //                                     <div className="borderDesc container">
      //                                         <h4 className="product-title">Doctor’s Profile</h4>
      //                                         <Interweave content={item.Description}/>
      //                                         {/*{item.Description}*/}
      //                                     </div>
      //                                     <br/>
      //                                     <br/>
      //                                     {item.UniqueContribution ?
      //                                         <div className="borderDesc container">
      //                                             <h4 className="product-title">Unique Contribution</h4>
      //                                             <Interweave content={item.UniqueContribution}/>
      //                                             {/*{item.UniqueContribution}*/}
      //                                         </div> : ""}
      //                                 </section> : <Skeleton active avatar paragraph={{rows: 7}}/>}

      //                             {(item) ?
      //                                 <section className="tab-product m-0">
      //                                     <div className="container">
      //                                         <div className="row">
      //                                             <div className="col-sm-12 col-lg-12">
      //                                                 <DoctorTopTabs item={item}/>
      //                                                 <CommentsHosital item={item} auth={auth} type={'doctor'}/>
      //                                             </div>

      //                                         </div>
      //                                     </div>
      //                                 </section> : <Skeleton active avatar paragraph={{rows: 7}}/>}
      //                         </div>
      //                         <div className="col-md-4">

      //                             {item && item.hospital ? <section>
      //                                 <div className="media">
      //                                     <div className="borderDesc container">
      //                                         <Link to={`${process.env.PUBLIC_URL}/hospital/${item.hospital.Alias}`}>
      //                                             <img className="img-fluid" src={`${item.hospital.Pictures[0].url}`}
      //                                                  alt="" style={{width: '200px', margin: '20px'}}/>
      //                                         </Link>
      //                                         <div className="media-body align-self-center">
      //                                             <Rate disabled value={item.hospital.Rating}
      //                                                   style={{fontSize: 14, color: '#ffa201'}}/>
      //                                             <Link
      //                                                 to={`${process.env.PUBLIC_URL}/hospital/${item.hospital.Alias}`}>
      //                                                 <h6>{item.hospital.Name}</h6>
      //                                             </Link>
      //                                         </div>
      //                                     </div>
      //                                 </div>
      //                             </section> : ""}

      //                             <section>
      //                                 {item && item.Memberships ?
      //                                     <div className="borderDesc container">
      //                                         <h4 className="product-title">Memberships :</h4>
      //                                         <Interweave content={item.Memberships}/>
      //                                         {/*{item.UniqueContribution}*/}
      //                                     </div> : ""}
      //                             </section>
      //                             <section>
      //                                 {item && item.Awards ?
      //                                     <div className="borderDesc container">
      //                                         <h4 className="product-title">Awards :</h4>
      //                                         <Interweave content={item.Awards}/>
      //                                         {/*{item.UniqueContribution}*/}
      //                                     </div> : ""}
      //                             </section>
      //                         </div>
      //                     </div>
      //                 </div>
      //             </div>
      //         </section>
      //         {/*<RelatedProduct />*/}
      //     </div>
      // </div>
      //
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  console.log("productId", productId);
  console.log("state", state);
  return {
    item: state.data.products.find((el) => el.id == productId),
    symbol: state.data.symbol,
    doctors: state.doctors,
    did: productId,
    auth: state.auth,
  };
};

export default connect(
  mapStateToProps,
  { addToCart, addToCartUnsafe, addToWishlist }
)(Doctor);
