import * as types from "../../constants/ActionTypes";

const filtersReducerDefaultState = {
  //brand: [],

  searchString: "",

  speciality: [],
  hcity: [],
  rate: { min: 0, max: 5 },

  docspecial: [],
  dcity: [],
  drate: { min: 0, max: 5 },
  dprices: { min: 0, max: 15000 },
  gender: { male: true, female: true },
  experience: { min: 0, max: 100 },
  availableFor: { videoConference: true, onCall: true, inHospital: true },
  cities: [],
  hospitalCities: [],
  city: [],
  sortBy: "",
};

const filtersReducer = (state = filtersReducerDefaultState, action) => {
  // console.log('FILTER/REDUCER : ', action);
  switch (action.type) {
    case types.FILTER_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
      };

    //*************** Hospital Filtering
    case types.FILTER_SPECIALITY:
      return {
        ...state,
        speciality: action.speciality,
      };
    case types.FILTER_HOSPITAL_CITY:
      return {
        ...state,
        hcity: action.hcity,
      };
    case types.FILTER_RATE:
      return {
        ...state,
        rate: { min: action.rating.value.min, max: action.rating.value.max },
      };

    //*************** Doctor Filtering
    case types.FILTER_DOCSPECIAL:
      return {
        ...state,
        docspecial: action.docspecial,
      };
    case types.FILTER_DOCTOR_CITY:
      return {
        ...state,
        dcity: action.dcity,
      };
    case types.FILTER_DOCTOR_RATE:
      return {
        ...state,
        drate: { min: action.drate.value.min, max: action.drate.value.max },
      };
    case types.FILTER_PRICE:
      return {
        ...state,
        dprices: {
          min: action.dprices.value.min,
          max: action.dprices.value.max,
        },
      };
    case types.FILTER_EXPERIENCE:
      return {
        ...state,
        experience: {
          min: action.experience.value.min,
          max: action.experience.value.max,
        },
      };
    case types.FILTER_GENDER:
      return {
        ...state,
        gender: {
          male: action.gender.value.male,
          female: action.gender.value.female,
        },
      };
    case types.FILTER_AVAILABLE_FOR:
      return {
        ...state,
        availableFor: {
          videoConference: action.availableFor.value.videoConference,
          onCall: action.availableFor.value.onCall,
          inHospital: action.availableFor.value.inHospital,
        },
      };
    case types.FILTER_ALL_CITIES:
      return {
        ...state,
        cities: action.cities,
      };
    case types.FILTER_ALL_HOSPITAL_CITIES:
      return {
        ...state,
        hospitalCities: action.cities,
      };
    case types.FILTER_CLEAN:
      return {
        state,
      };

    case types.FILTER_CITY:
      return {
        ...state,
        cities: action.city,
      };

    //*************** Sorting
    case types.SORT_BY:
      return {
        ...state,
        sortBy: action.sort_by,
      };

    // case types.FILTER_BRAND:
    //     return {
    //         ...state,
    //         brand: action.brand
    //     };
    // case types.FILTER_COLOR:
    //     return {
    //         ...state,
    //         color: action.color
    //     };
    default:
      return state;
  }
};

export default filtersReducer;
