import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import '../../commonAll/index.scss';
import Slider from 'react-slick';
import {
    Slider4,
    svgFreeShipping,
    svgservice,
    svgoffer,
    svgpayment
} from '../../../services/script'

// Import custom components
import LogoBlocks from "../common/logo-block"
import BlogSection from "../common/blogsection"
import Trending from "./trending";
import TrandingCollection from "../common/collection"
import Special from "../common/special";
// import HospitalBlock from "../../blocks/hospitalBlock";
import CityBlock from "../../blocks/cityBlock";
// import Instagram from "../common/instagram"
import HeaderFive from "../../commonAll/headers/header-five"
import FooterFour from "../../commonAll/footers/footer-four";
// import ThemeSettings from "../../commonAll/theme-settings"
import Pace from "react-pace-progress";
import axios from "axios";
import appConfig from "../../../config";
import {Skeleton} from "antd";
import Select from "react-select";
import * as types from "../../../constants/ActionTypes";
import HeaderNew from "../../commonAll/headers/header-new";
import {getHomeContent} from "../../../api/apicalls";
import SearchCheck from "../../search/search";
import {conformsTo} from 'lodash';
import HeaderInside from "../../commonAll/headers/header-inside";

// import Select from 'react-select';

const components = {
    DropdownIndicator: null,
    //NoOptionsMessage: "Searching ..."
};

class Watch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            homeData: {},
            param: {},
            selectedDoctorOption: [],
            selectedHospitalOption: [],
            selectedSpecialOption: [],
            suggestionsDoc: [],
            suggestionsHos: [],
            suggestionsSpcl: appConfig.docspecial,
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color2.css`);
        // getHomeContent().then((homeData) => {
        //     this.setState({
        //         homeData
        //     });
        // });
        // this.handleDocInputChange("dr");
        // this.handleHospInputChange("a");
        this.getDoctor("varun-gupta");
        this.getHospital("indraprastha-apollo-hospital-delhi");
    };

    getDoctor(id) {
        // let param = "/" + id;
        let param = "?slug=" + id;
        // console.log("param is ", param)
        axios
            .get(appConfig.doctors + param)
            .then(response => {
                // Handle success.
                //console.log('DOCTOR/ITEM: Data Received', response.data);
                let doctor = response.data[0];
                this.setState({doctor, isLoading: false});
                // console.log("doctor",this.state.doctor);
            })

    }

    getHospital(id) {
        // let param = "/" + id;
        let param = "?Alias=" + id;
        axios
            .get(appConfig.hospitals + param)
            .then(response => {
                // Handle success.
                // console.log('HOSPITAL/ITEM: Data Received', response.data);
                let hospital = response.data[0] ? response.data[0] : [];
                if (hospital.length) {
                    this.setState({hospital}, this.getDoctors(hospital.id))
                }

            })
    }

    getDoctors(id) {
        let param = "?hospital=" + id;
        axios
            .get(appConfig.doctors + param)
            .then(response => {
                // Handle success.
                // console.log('HOSPITAL/Doctors: Data Received', response.data);
                let doctors = response.data;
                this.setState({doctors});

                this.state.hospital.doctorLength = doctors.length;

                // console.log("Hospitalqqq",this.state.hospital);
            })
    }

    handleChange(event) {
        // console.log('handleChange', this);
        const {name, value} = event.target;
        // console.log(name, value, event.target.value);
        // console.log('handleChange', this);
        const {user} = this.state;
        this.setState({
            [name]: value
        });
    }

    handleDocInputChange = doc => {
        // console.log(doc);
        if (doc.length >= 1) {
            axios
                .get(appConfig.searchDoctor + "?q=" + doc)
                .then(response => {
                    // Handle success.
                    let suggestionsDoc = response.data;
                    // console.log('DOCTOR/SEARCH: Data Received ', suggestionsDoc);
                    this.setState({suggestionsDoc})
                })
        }
    }

    handleHospInputChange = hos => {
        // console.log(hos);
        if (hos.length >= 1) {
            axios
                .get(appConfig.searchHospital + "?q=" + hos)
                .then(response => {
                    // Handle success.
                    let suggestionsHos = response.data;
                    // console.log('HOSPITAL/SEARCH: Data Received ', suggestionsHos);
                    this.setState({suggestionsHos})
                })
        }
    }

    handleDoctorChange = selectedDoctorOption => {
        // console.log("selectedOption ", selectedDoctorOption);

        let path = `${process.env.PUBLIC_URL}/doctor/${selectedDoctorOption.slug.toLowerCase()}`;

        this.setState(
            {selectedDoctorOption},
            () => {
                // console.log(`Option selected:`, this.state.selectedDoctorOption);
                window.location.href = path;
            }
        );
    };

    handleHospitalChange = selectedHospitalOption => {
        // console.log("selectedOption ", selectedHospitalOption);

        let path = `${process.env.PUBLIC_URL}/hospital/${selectedHospitalOption.slug.toLowerCase()}`;

        this.setState(
            {selectedHospitalOption},
            () => {
                // console.log(`Option selected:`, this.state.selectedHospitalOption);
                window.location.href = path;
            }
        );
    };

    handleSpeclChange = selectedSpecialOption => {
        // console.log("selectedOption ", selectedSpecialOption);
        this.setState(
            {selectedSpecialOption},
            () => console.log(`Option selected:`, this.state.selectedSpecialOption)
        );
    };

    getHomeContent() {
        axios
            .get(appConfig.home)
            .then(response => {
                // Handle success.
                // console.log('HOME-PAGE: Data Received', response.data);
                let homeData = response.data;
                this.setState({
                    homeData
                });
            })
    }

    //hospitals = [1,2,3,4,5,6,7,8,9,10,11,12];
    citylist1 = [
        {
            city: 'Delhi',
            picture: '/assets/images/city/delhi.jpg'
        },
        {
            city: 'Mumbai',
            picture: '/assets/images/city/mumbai.jpg'
        },
        {
            city: 'Chennai',
            picture: '/assets/images/city/Chennai.jpg'
        }];
    citylist2 = [
        {
            city: 'Hyderabad',
            picture: '/assets/images/city/hyderabad.jpg'
        },
        {
            city: 'Kolkata',
            picture: '/assets/images/city/kolkata.jpg'
        },
        {
            city: 'Bangalore',
            picture: '/assets/images/city/banglore.jpg'
        },
        {
            city: 'Ahmedabad',
            picture: '/assets/images/city/ahmedabad.jpg'
        }];
    docs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    render() {
        const {
            homeData,
            selectedDoctorOption,
            selectedHospitalOption,
            selectedSpecialOption,
            suggestionsDoc,
            suggestionsHos,
            suggestionsSpcl
        } = this.state;
        //const {tags, suggestions, freqOptions, selectedOption, selectedFreq, medecine} = this.state;
        return (
            <div style={{backgroundColor: '#ECECEC'}}>
                <Helmet>
                    <title>HOSPLAN</title>
                </Helmet>
                {/*<HeaderFive logoName={'logo-lite.png'}/>*/}
                {/*<HeaderNew logoName={'LOGO.png'}/>*/}
                <HeaderInside logoName={'LOGO.png'}/>


                {/*<section style={{ backgroundColor: '#ECECEC' }}>*/}

                {/*    <div className="container" style={{ height: '0' }}>*/}

                {/*    </div>*/}
                {/*</section>*/}


                {/* this is for Hospital */}
                {/*<SearchCheck type="Hospital" data={{Name:"Fortis",*/}
                {/*  Verified:true,*/}
                {/*  Pictures:[{url:"https://storage.googleapis.com/testatic/apollo-hospitals_308730e3f9/apollo-hospitals_apollo-hospitals_308730e3f9.jpeg"}],*/}
                {/* EstablishedYear:2000,*/}
                {/* Beds:200,*/}
                {/* TOIRank:20,*/}
                {/* doctorLength:50,*/}
                {/* Rating:4*/}
                {/*}}/>*/}

                {/*/!* This is for Doctor *!/*/}
                {/*<SearchCheck type="Doctor" data={{Name:"Dr Varun",*/}
                {/*  Verified:true,*/}
                {/*  Pictures:[],*/}
                {/*  // Designation:"CTO",*/}
                {/*  Experience:20,*/}
                {/*  Department:"CARDILOGY",*/}
                {/*  Designation:"Sergen",*/}
                {/*  Specialization:"Radiotherapy & Tonsil"*/}
                {/*}}/>*/}


                {/*{this.state.isLoading ? <Pace color="#27ae60"/> : null}*/}
                {/*{this.state.isLoading ? <Pace color="#636363"/> : null}*/}

                <section className="container shdowFrame">


                    <section className="section-b-space">
                        <div className="collection-wrapper">
                            <div className="container">


                                <LogoBlocks/>


                                {/*<div className="row">*/}
                                {/*    <div className="col-md-12">*/}

                                {/*        <img src={`${process.env.PUBLIC_URL}/assets/images/offer-banner.jpg`}*/}
                                {/*             className="img-fluid blur-up lazyload bg-img" alt=""/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<div className="row" style={{ height: "300px", marginTop: "25px" }}>*/}
                                {/*    <div className="col-lg-6" style={{ backgroundColor: "green" }}>*/}

                                {/*    </div>*/}
                                {/*    <div className="col-lg-6" style={{ backgroundColor: "yellow" }}>*/}

                                {/*    </div>*/}
                                {/*</div>*/}

                                <section className="ratio_45">

                                    <div className="row partition3">
                                        <div className="col-md-4">
                                            {/*<a href="/#">*/}
                                                <div className="collection-banner p-left">
                                                    {/*<div className="img-part">*/}
                                                    {/*    <img src={`${process.env.PUBLIC_URL}/assets/images/banner1.jpg`}*/}
                                                    {/*         className="img-fluid blur-up lazyload bg-img" alt=""/>*/}
                                                    {/*</div>*/}
                                                    <div style={{paddingLeft: '35px'}}>
                                                        <div>
                                                            <h4>Cancer</h4>
                                                            <div style={{paddingLeft:'15px'}}>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Brain Cancer</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Lung Cancer</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Breast Cancer</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Kidney Cancer</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Blood Cancer</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Mouth Cancer</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Eye Cancer</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Prostate Cancer</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Skin Cancer</a><br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            {/*</a>*/}
                                        </div>
                                        <div className="col-md-4">
                                            {/*<a href="/#">*/}
                                                <div className="collection-banner p-left text-left">
                                                    {/*<div className="img-part">*/}
                                                    {/*    <img src={`${process.env.PUBLIC_URL}/assets/images/banner2.jpg`}*/}
                                                    {/*         className="img-fluid blur-up lazyload bg-img" alt=""/>*/}
                                                    {/*</div>*/}
                                                    <div style={{paddingLeft: '35px'}}>
                                                        <div>
                                                            <h4>Organ Transplant</h4>
                                                            <div style={{paddingLeft:'15px'}}>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Kidney</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Heart</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Eye</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Lung</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Skin</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Knee</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Blood</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Intestine</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Bone Marrow</a><br/>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            {/*</a>*/}
                                        </div>
                                        <div className="col-md-4">
                                            {/*<a href="/#">*/}
                                                <div className="collection-banner p-left">
                                                    {/*<div className="img-part">*/}
                                                    {/*    <img src={`${process.env.PUBLIC_URL}/assets/images/banner.jpg`}*/}
                                                    {/*         className="img-fluid blur-up lazyload bg-img" alt=""/>*/}
                                                    {/*</div>*/}
                                                    <div style={{paddingLeft: '35px'}}>
                                                        <div>
                                                            <h4>Neurological</h4>
                                                            <div style={{paddingLeft:'15px'}}>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Brain Tumor</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Cerebral Vasculitis</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Brain-tumor`}>Intracerebral Hemorrhage</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Cerebral-Amyloid-Angiopathy`}>Cerebral Amyloid Angiopathy</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Antiphospholipid-syndrome`}>Antiphospholipid Syndrome</a><br/>
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Spinal-arteriovenous-malformation`}>Spinal Arteriovenous Malformation</a><br/>
                                                                {/*<a href={`${process.env.PUBLIC_URL}/search/india?Condition=Cerebral-arteriovenous-malformation`}>Cerebral Arteriovenous Malformation</a><br/>*/}
                                                                <a href={`${process.env.PUBLIC_URL}/search/india?Condition=Central-Nervous-System-(CNS)-vasculitis`}>Central Nervous System Vasculitis</a><br/>
                                                                {/*<a href={`${process.env.PUBLIC_URL}/search/india?Condition=Cerebral-microbleeds`}>Cerebral Microbleeds</a><br/>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*<div className="contain-banner banner-3">*/}
                                                    {/*    <div>*/}
                                                    {/*        <h4>minimum 50% off</h4>*/}
                                                    {/*        <h2>gold watch</h2>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>
                                            {/*</a>*/}
                                        </div>
                                    </div>

                                </section>


                                <section className="section-b-space ratio_portrait" style={{paddingBottom: '0px'}}>

                                    <div className="row">
                                        <div className="col">
                                            <Slider {...Slider4} className="slide-4 category-m no-arrow">
                                                {/*{ this.hospitals.map((v, i)=> <div key={v}><HospitalBlock /></div>)}*/}
                                                {this.citylist1.map((c, i) => <div key={i}><CityBlock city={c}/>
                                                </div>)}
                                            </Slider>
                                        </div>
                                    </div>

                                </section>

                                <section className="section-b-space ratio_portrait">

                                    <div className="row">
                                        <div className="col">
                                            <Slider {...Slider4} className="slide-4 category-m no-arrow">
                                                {/*{ this.hospitals.map((v, i)=> <div key={v}><HospitalBlock /></div>)}*/}
                                                {this.citylist2.map((c, i) => <div key={i}><CityBlock city={c}/>
                                                </div>)}
                                            </Slider>
                                        </div>
                                    </div>

                                </section>

                                {/*<div className="row">*/}
                                {/*    <img src={`${process.env.PUBLIC_URL}/assets/images/process.jpg`}*/}
                                {/*         className="img-fluid blur-up lazyload bg-img" alt=""/>*/}
                                {/*</div>*/}


                                {/*<div className="row">*/}
                                {/*    <img src={`${process.env.PUBLIC_URL}/assets/images/offer-banner.jpg`}*/}
                                {/*         className="img-fluid blur-up lazyload bg-img" alt=""/>*/}
                                {/*</div>*/}

                                {/*/!*<Trending type={'doctor'}/>*!/*/}
                                {/*{homeData.hospitals ? <Trending type={'doctor'} home={homeData}/> :*/}
                                {/*    <Skeleton active avatar paragraph={{rows: 5}}/>}*/}
                                {/*/!*Special Products End*!/*/}
                                {/* Container End   */}
                            </div>
                        </div>
                    </section>


                </section>

                {/*<section className="p-0 small-slider">*/}
                {/*    /!* left: 'calc(50% - 170px)' top: '150px', left: '20px' *!/*/}
                {/*    <div className="container" style={{height: '0'}}>*/}
                {/*        <div className="col">*/}
                {/*            <div style={{*/}
                {/*                position: 'absolute',*/}
                {/*                right: '20px',*/}
                {/*                zIndex: '8',*/}
                {/*                // zIndex: '90',*/}
                {/*                width: '310px',*/}
                {/*                top: '20px',*/}
                {/*                background: '#31303057',*/}
                {/*                padding: '10px',*/}
                {/*                borderRadius: '10px',*/}
                {/*            }}>*/}
                {/*                <label htmlFor="review"*/}
                {/*                       style={{color: '#fff', fontSize: 'xx-large', fontWeight: 'bolder'}}>*/}
                {/*                    Consult trusted specialists</label>*/}
                {/*                    /!*Tele / Video Consult a Specialist Doctor</label>*!/*/}
                {/*                <div className="col-md-12 searchItems">*/}
                {/*                    <div style={{color: '#000'}}>*/}
                {/*                        <Select*/}
                {/*                            value={selectedDoctorOption}*/}
                {/*                            onChange={this.handleDoctorChange}*/}
                {/*                            options={suggestionsDoc}*/}
                {/*                            // isMulti*/}
                {/*                            isSearchable*/}
                {/*                            isClearable*/}
                {/*                            // components={{DropdownIndicator: null}}*/}
                {/*                            components={components}*/}
                {/*                            onInputChange={this.handleDocInputChange}*/}
                {/*                            placeholder="Specialist’s name"*/}
                {/*                            style={{color: '#000', wideth: '250px'}}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="col-md-12 searchItems">*/}
                {/*                    <div style={{color: '#000'}}>*/}
                {/*                        <Select*/}
                {/*                            value={selectedHospitalOption}*/}
                {/*                            onChange={this.handleHospitalChange}*/}
                {/*                            options={suggestionsHos}*/}
                {/*                            // isMulti*/}
                {/*                            isSearchable*/}
                {/*                            isClearable*/}
                {/*                            components={components}*/}
                {/*                            onInputChange={this.handleHospInputChange}*/}
                {/*                            placeholder="Select Hospital"*/}
                {/*                            style={{color: '#000', wideth: '250px'}}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="col-md-12 searchItems">*/}
                {/*                    <div style={{color: '#000'}}>*/}
                {/*                        <Select*/}
                {/*                            value={selectedSpecialOption}*/}
                {/*                            onChange={this.handleSpeclChange}*/}
                {/*                            options={suggestionsSpcl}*/}
                {/*                            isMulti*/}
                {/*                            isSearchable*/}
                {/*                            isClearable*/}
                {/*                            placeholder="Select Specialization"*/}
                {/*                            style={{color: '#000', wideth: '250px'}}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                <div className="col-md-2" style={{height: '55px'}}>*/}
                {/*                    /!*<label htmlFor="review">Action</label>*!/*/}
                {/*                    <button className="btn btn-solid"*/}
                {/*                        // className="searchBtn"*/}
                {/*                            style={{marginTop: '6px', marginLeft: '10px'}}*/}
                {/*                            onClick={() => {*/}
                {/*                                this.setState({*/}
                {/*                                    param: {}*/}
                {/*                                })*/}
                {/*                                let param = "";*/}
                {/*                                selectedSpecialOption.forEach((itm, indx) => {*/}
                {/*                                    if(indx === 0 ){*/}
                {/*                                        param += "s=" + itm.value;*/}
                {/*                                    }else{*/}
                {/*                                        param += "," + itm.value;*/}
                {/*                                    }*/}

                {/*                                })*/}
                {/*                                let path = `${process.env.PUBLIC_URL}/doctors/india?${param}`;*/}
                {/*                                console.log("search clicked ", path);*/}
                {/*                                window.location.href = path;*/}
                {/*                            }}>*/}
                {/*                        Search*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}


                {/*    <Slider className="slide-1 home-slider">*/}
                {/*        <div>*/}
                {/*            <div className="home home2 text-left p-left">*/}
                {/*                <div className="container">*/}
                {/*                    <div className="row">*/}
                {/*                        <div className="col">*/}
                {/*                            <div className="slider-contain">*/}
                {/*                                /!*<div>*!/*/}
                {/*                                /!*    <h4 style={{color: '#fff', letterSpacing: '2px'}}>Comprehensive*!/*/}
                {/*                                /!*        Personal Care</h4>*!/*/}
                {/*                                /!*    <h2 style={{color: '#fff'}}>Highest Quality HealthCare, <br/>Affordable*!/*/}
                {/*                                /!*        Prices</h2>*!/*/}
                {/*                                /!*    /!*<a href="#" className="btn btn-outline btn-classic">shop now</a>*!/*!/*/}
                {/*                                /!*</div>*!/*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <div className="home home3 text-left p-left">*/}
                {/*                <div className="container">*/}
                {/*                    <div className="row">*/}
                {/*                        <div className="col">*/}
                {/*                            <div className="slider-contain">*/}
                {/*                                <div>*/}
                {/*                                    /!*<h4 style={{letterSpacing: '2px'}}>Comprehensive Personal Care</h4>*!/*/}
                {/*                                    /!*<h2>Highest Quality HealthCare, <br/>Affordable Prices</h2>*!/*/}
                {/*                                    /!*<a href="#" className="btn btn-outline btn-classic">shop now</a>*!/*!/*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <div className="home home9 text-left p-left">*/}
                {/*                <div className="container">*/}
                {/*                    <div className="row">*/}
                {/*                        <div className="col">*/}
                {/*                            <div className="slider-contain">*/}
                {/*                                <div>*/}
                {/*                                    /!*<h4 style={{color: '#fff', letterSpacing: '2px'}}>Comprehensive*!/*/}
                {/*                                    /!*    Personal Care</h4>*!/*/}
                {/*                                    /!*<h2 style={{color: '#fff'}}>Highest Quality HealthCare, <br/>Affordable*!/*/}
                {/*                                    /!*    Prices</h2>*!/*/}
                {/*                                    /!*<a href="#" className="btn btn-outline btn-classic">shop now</a>*!/*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </Slider>*/}
                {/*</section>*/}


                {/*Timer Banner*/}
                {/*<section className="pt-0">*/}
                {/*    <div className="container">*/}
                {/*        <br/>*/}
                {/*        <br/>*/}
                {/*        <img src={`${process.env.PUBLIC_URL}/assets/images/process.jpg`}*/}
                {/*             className="img-fluid blur-up lazyload bg-img" alt=""/>*/}
                {/*        /!*<div className="row banner-timer">*!/*/}
                {/*        /!*    <div className="col-md-6">*!/*/}
                {/*        /!*        /!*<img src={`${process.env.PUBLIC_URL}/assets/images/process.jpg`}*!/*!/*/}
                {/*        /!*        /!*     className="img-fluid blur-up lazyload bg-img" alt=""/>*!/*!/*/}
                {/*        /!*        /!*<div className="banner-text">*!/*!/*/}
                {/*        /!*        /!*    <h2>Save <span>30% off</span> During LockDown</h2>*!/*!/*/}
                {/*        /!*        /!*</div>*!/*!/*/}
                {/*        /!*    </div>*!/*/}
                {/*        /!*    /!*<div className="col-md-6">*!/*!/*/}
                {/*        /!*    /!*    <div className="timer-box">*!/*!/*/}
                {/*        /!*    /!*        <div className="timer">*!/*!/*/}
                {/*        /!*    /!*            <p id="demo"></p>*!/*!/*/}
                {/*        /!*    /!*        </div>*!/*!/*/}
                {/*        /!*    /!*    </div>*!/*!/*/}
                {/*        /!*    /!*</div>*!/*!/*/}
                {/*        /!*</div>*!/*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/*/!*Timer Banner End*!/*/}

                {/*/!*Logo Blocks section*!/*/}
                {/*<LogoBlocks/>*/}
                {/*/!*Logo Blocks section end*!/*/}

                {/*/!*category wrapper*!/*/}
                {/*<section className="section-b-space ratio_portrait" style={{paddingBottom: '0px'}}>*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col">*/}
                {/*                <Slider {...Slider4} className="slide-4 category-m no-arrow">*/}
                {/*                    /!*{ this.hospitals.map((v, i)=> <div key={v}><HospitalBlock /></div>)}*!/*/}
                {/*                    {this.citylist1.map((c, i) => <div key={i}><CityBlock city={c}/></div>)}*/}
                {/*                </Slider>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}

                {/*<section className="section-b-space ratio_portrait">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col">*/}
                {/*                <Slider {...Slider4} className="slide-4 category-m no-arrow">*/}
                {/*                    /!*{ this.hospitals.map((v, i)=> <div key={v}><HospitalBlock /></div>)}*!/*/}
                {/*                    {this.citylist2.map((c, i) => <div key={i}><CityBlock city={c}/></div>)}*/}
                {/*                </Slider>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/*category wrapper end*/}

                {/*/!*Special Products Start*!/*/}

                {/*/!*<Trending type={'doctor'}/>*!/*/}
                {/*{homeData.hospitals ? <Trending type={'doctor'} home={homeData}/> :*/}
                {/*    <Skeleton active avatar paragraph={{rows: 5}}/>}*/}
                {/*/!*Special Products End*!/*/}

                {/*/!* Parallax banner*!/*/}
                {/*{homeData.hospitals ? <TrandingCollection type={'hospital'} hospitals={homeData.hospitals}/> :*/}
                {/*    <Skeleton active avatar paragraph={{rows: 5}}/>}*/}
                {/*/!* Parallax banner end*!/*/}

                {/*Content Banner*/}
                {/*<section className="ratio_45">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row partition3">*/}
                {/*            <div className="col-md-4">*/}
                {/*                <a href="/#">*/}
                {/*                    <div className="collection-banner p-left">*/}
                {/*                        <div className="img-part">*/}
                {/*                            <img src={`${process.env.PUBLIC_URL}/assets/images/banner1.jpg`}*/}
                {/*                                 className="img-fluid blur-up lazyload bg-img" alt=""/>*/}
                {/*                        </div>*/}
                {/*                        /!*<div className="contain-banner banner-3">*!/*/}
                {/*                        /!*    <div>*!/*/}
                {/*                        /!*        <h4>minimum 10% off</h4>*!/*/}
                {/*                        /!*        <h2>new watch</h2>*!/*/}
                {/*                        /!*    </div>*!/*/}
                {/*                        /!*</div>*!/*/}
                {/*                    </div>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-4">*/}
                {/*                <a href="/#">*/}
                {/*                    <div className="collection-banner p-left text-left">*/}
                {/*                        <div className="img-part">*/}
                {/*                            <img src={`${process.env.PUBLIC_URL}/assets/images/banner2.jpg`}*/}
                {/*                                 className="img-fluid blur-up lazyload bg-img" alt=""/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-4">*/}
                {/*                <a href="/#">*/}
                {/*                    <div className="collection-banner p-left">*/}
                {/*                        <div className="img-part">*/}
                {/*                            <img src={`${process.env.PUBLIC_URL}/assets/images/banner.jpg`}*/}
                {/*                                 className="img-fluid blur-up lazyload bg-img" alt=""/>*/}
                {/*                        </div>*/}
                {/*                        /!*<div className="contain-banner banner-3">*!/*/}
                {/*                        /!*    <div>*!/*/}
                {/*                        /!*        <h4>minimum 50% off</h4>*!/*/}
                {/*                        /!*        <h2>gold watch</h2>*!/*/}
                {/*                        /!*    </div>*!/*/}
                {/*                        /!*</div>*!/*/}
                {/*                    </div>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/*Content Banner End*/}

                {/*{homeData.featuredDoctors ? <Special type={'doctor'} featured={homeData.featuredDoctors}/> :*/}
                {/*    <Skeleton active avatar paragraph={{rows: 15}}/>}*/}

                {/* Blog Section Section*/}
                {/*<section className="blog blog-bg section-b-space ratio2_3">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-12">*/}
                {/*                <div className="title4">*/}
                {/*                    <h4>From our Patients</h4>*/}
                {/*                    <h2 className="title-inner4" style={{color: '#000'}}>Testimonials</h2>*/}
                {/*                    <div className="line"><span></span></div>*/}
                {/*                </div>*/}
                {/*                <BlogSection/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/* Blog Section End*/}

                {/*Service Layout*/}
                {/*<div className="container">*/}
                {/*    <section className="service section-b-space border-section border-top-0">*/}
                {/*        <div className="row partition4">*/}
                {/*            <div className="col-lg-3 col-md-6 service-block1">*/}
                {/*                <div dangerouslySetInnerHTML={{__html: svgFreeShipping}}/>*/}
                {/*                <h4>No Going Out of Home</h4>*/}
                {/*                /!*<p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>*!/*/}
                {/*            </div>*/}
                {/*            <div className="col-lg-3 col-md-6 service-block1">*/}
                {/*                <div dangerouslySetInnerHTML={{__html: svgservice}}/>*/}
                {/*                <h4>24 X 7 service</h4>*/}
                {/*                /!*<p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>*!/*/}
                {/*            </div>*/}
                {/*            <div className="col-lg-3 col-md-6 service-block1">*/}
                {/*                <div dangerouslySetInnerHTML={{__html: svgoffer}}/>*/}
                {/*                <h4>Lockdown Offer</h4>*/}
                {/*                /!*<p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>*!/*/}
                {/*            </div>*/}
                {/*            <div className="col-lg-3 col-md-6 service-block1">*/}
                {/*                <div dangerouslySetInnerHTML={{__html: svgpayment}}/>*/}
                {/*                <h4>online payment</h4>*/}
                {/*                /!*<p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>*!/*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </section>*/}
                {/*</div>*/}
                {/*Service Layout End*/}

                {/*<Instagram type="watch"/>*/}
                {/*<ThemeSettings/>*/}

                <FooterFour logoName={'logo-dark.png'}/>

            </div>
        )
    }
}


export default Watch;
