import React, { Component } from 'react';

class Main extends Component {
    render(){
        return(
            <div>
                <img width="100%" height="750px" src="/assets/images/5070.jpg"/>
            </div> 
        );
    }
}
export default Main;