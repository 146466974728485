import React from 'react'
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import  SubmenuList from '../headerSubmenuList'
import {
    
    getSuggestionsConditions, getSuggestionsHospitals, getSuggestionsSpeciality,
    getSuggestionsTreatments
} from '../../../../redux/actions'


const HospitalList=({suggTreatments, suggConditions, suggSpecialisations, suggHospitals,treatment,handleSpeclChange,condition,hospital,specialisation})=>{
    const getFinderData = () => {
        
        if (!suggTreatments.length)
            getSuggestionsTreatments();

        if (!suggConditions.length)
            getSuggestionsConditions();

        if (!suggSpecialisations.length)
            getSuggestionsSpeciality();

        if (!suggHospitals.length)
            getSuggestionsHospitals();

       
    }
    React.useEffect(()=>{
        getFinderData();
       
    },[])

    return(
            <React.Fragment>
   
                <SubmenuList name="Hospital" value={hospital} handleSpeclChange={handleSpeclChange} optionValues={suggHospitals}  speclName="Hospital"/>
                <SubmenuList name="Treatment" value={treatment} handleSpeclChange={handleSpeclChange} optionValues={suggTreatments} speclName="Treatment"  />
             
                 <SubmenuList name="Condition" value={condition} handleSpeclChange={handleSpeclChange} optionValues={suggConditions}
                 speclName="Condition" />
              
                 <SubmenuList name="Specialisation" value={specialisation} handleSpeclChange={handleSpeclChange} optionValues={suggSpecialisations}  speclName="Speciality"/>
               
               
                  
            </React.Fragment>
           
       
    )
}



const mapStateToProps = (state) => ({
    

    suggTreatments: state.data.treatments,
    suggConditions: state.data.conditions,
    suggSpecialisations: state.data.specialisations,
    suggHospitals: state.data.hospitals,
   
})
export default connect(
    mapStateToProps, {
      
        getSuggestionsTreatments,
        getSuggestionsConditions,
        getSuggestionsSpeciality,
        getSuggestionsHospitals,
       
      
    }
)(withRouter(HospitalList));