import React, { Component } from 'react';
import appConfig from "../../config";
import axios from 'axios';

class Find extends Component {

  constructor(props) {
    super(props);
    this.state = {
      treatment: [],
      condition: [],
      selectedTreatment:[],
      selectedCondition:[],
      selectedTab:""
    }
  }

  componentDidMount() {

    axios.get(appConfig.treatment)
      .then(response => {
        this.setState({ treatment: response.data });
      })

    axios.get(appConfig.condition)
      .then(response => {
        this.setState({ condition: response.data });
      })


  }
  render() {
    console.log("find::treatment", this.state.treatment);
    console.log("find::condition", this.state.condition);
    console.log("find::selectedTreatment", this.state.selectedTreatment);
    console.log("find::selectedCondition", this.state.selectedCondition);
    let treatment = this.state.treatment;
    let condition = this.state.condition;
    let selectedTreatment = this.state.selectedTreatment;
    let selectedCondition = this.state.selectedCondition;
   
  
    return (
      <div style={{ backgroundColor: '#ECECEC' }}>
        <div style={{ marginLeft: "10%", marginRight: "10%", backgroundColor: "#fff" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <label style={{ fontSize: "xxx-large" }}>Hello Admin!</label>
          </div>
          <div className="row">
            <div className="col-md-5" style={{ padding: "30px" }}>
              <label style={{ fontSize: "larger" }}>Search For Treatment</label>
              <select className="form-control" onClick={(e)=>{
                  if(e.target.value!="Please Select")
                  {
                    console.log("find::treatmentSelected",e.target.value)
                    this.setState({
                      selectedTreatment:treatment[parseInt(e.target.value)],
                      selectedTab:"treatment"
                    });
                
                  }
                }}>
                <option disabled selected>Please Select</option>
                {treatment.map((item,index)=>                
                    <option key={index} value={index}>{item.name}</option>
                  )}
                
              </select>
            </div>
            <div className="col-md-2" style={{ padding: "30px 30px 30px 55px" }}>
              <label style={{ fontSize: "larger" }}>OR</label>
            </div>
            <div className="col-md-5" style={{ padding: "30px" }}>
              <label style={{ fontSize: "larger" }}>Search For Condition</label>
              <select className="form-control" onClick={(e)=>{
                  if(e.target.value!="Please Select")
                  {
                    console.log("find::conditionSelected",e.target.value)
                    this.setState({
                      selectedCondition:condition[parseInt(e.target.value)],
                      selectedTab:"condition"
                    });
                
                  }
                }}>
                <option disabled selected>Please Select</option>
                {condition.map((item,index)=>                
                    <option key={index} value={index}>{item.name}</option>
                  )}
              </select>
            </div>
          </div>
          <div 
          className="content-box" 
          style={{display:`${(this.state.selectedTab==="treatment" ? "block" : "none")}`}}>
            {(selectedTreatment.length != 0) ?
              <div>
                <label style={{ fontSize: "larger" }}>Treatment Name: {selectedTreatment.name}</label><br/>
                <label style={{ fontSize: "larger" }}>Condition:</label>
                <ol>
                 
                  {selectedTreatment.conditions.map((item,index)=>
                    <li key={index} style={{display:"list-item"}}>{item.name}</li>
                  )}
               
                </ol>
                <label style={{ fontSize: "larger" }}>Specialities:</label>
                <ol>
                 
                  {selectedTreatment.specialities.map((item,index)=>
                    <li key={index} style={{display:"list-item"}}>{item.name}</li>
                  )}
               
                </ol>
                <label style={{ fontSize: "larger" }}>Superspeciality: {selectedTreatment.superspeciality.name}</label><br/>
                <label style={{ fontSize: "larger" }}>Tests:</label>
                <ol>
                 
                  {selectedTreatment.tests.map((item,index)=>
                    <li key={index} style={{display:"list-item"}}>{item.Name}</li>
                  )}
               
                </ol>
              </div>
              : ""}
          </div>
          <div 
          className="content-box" 
          style={{display:`${(this.state.selectedTab==="condition" ? "block" : "none")}`}}>
            {(selectedCondition.length != 0) ?
              <div> 
                <label style={{ fontSize: "larger" }}>Condition Name: {selectedCondition.name}</label><br/>
                <label style={{ fontSize: "larger" }}>Common Name: {selectedCondition.commonname}</label><br/>
                <label style={{ fontSize: "larger" }}>Speciality: {selectedCondition.speciality.name}</label><br/>
                <label style={{ fontSize: "larger" }}>Superspeciality: {selectedCondition.superspeciality.name}</label><br/>
                <label style={{ fontSize: "larger" }}>Tests:</label>
                <ol>
                 
                  {selectedCondition.tests.map((item,index)=>
                    <li key={index} style={{display:"list-item"}}>{item.Name}</li>
                  )}
               
                </ol> 
                <label style={{ fontSize: "larger" }}>Treatments:</label>
                <ol>
                 
                  {selectedCondition.treatments.map((item,index)=>
                    <li key={index} style={{display:"list-item"}}>{item.name}</li>
                  )}
               
                </ol> 
                
              </div>
              : ""}
          </div>

        </div>
      </div>
    )
  }
}

export default Find;