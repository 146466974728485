import React, { Component } from 'react';
import Breadcrumb from "../commonAll/breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { getCartTotal, getUser } from "../../services";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { decrementQty, incrementQty, removeFromCart } from "../../redux/actions";
import { editUser} from "../../api/apicalls";
import { logout,setCurrentUser } from "../../redux/actions/authActions";
import doctor from '../products/doctor';
import appConfig from "../../config";
import axios from 'axios';
import { notification ,Upload, message} from 'antd';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
class DoctorProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: getUser(),
            name:'',
            loading: false,
            phone:'',
            email:'',
            doctor:"",
            editButton:'Edit',
            disabled:false,
            imageUrl:"",
            image:""
        }
        this.validator = new SimpleReactValidator();
        this.handleChange=this.handleChange.bind(this)
    }

    componentDidMount() {
         let userData=localStorage.getItem('user');
       
        this.props.setCurrentUser(JSON.parse(userData));
        console.log("doctorProfile",this.props.user);
        this.setState({name:this.props.user.user.user.name});
        this.setState({phone:this.props.user.user.user.phone});
        this.setState({email:this.props.user.user.user.email});
        if(this.state.user.user.Doctor){
            this.setState({doctor:this.state.user.user.Doctor})
            this.setState({image:this.state.user.user.Doctor.Pictures[0].url})
        }
    }
    editDoctor = async (id,data,jwt) => {
        this.setState({editButton:"Please Wait..."});
        this.setState({disabled:true});
       return await axios
           .put(appConfig.doctors+"/"+data.id,data, {
               headers: {
                   Authorization: 'Bearer ' + jwt
               }
           })
           .then(response => {
               // Handle success.
                axios
               .get(appConfig.users+"/"+id, {
                   headers: {
                       Authorization: 'Bearer ' + jwt
                   }
               })
               .then(res => {
                   let data={
                       jwt:jwt,
                       user:res.data
                   };
                   localStorage.setItem('user', JSON.stringify(data));
                   this.props.setCurrentUser(data);
                   notification.success({
                       message: "Doctor Updated Sucessfully",
                     });
                   //alert("UPDATED");
                   this.setState({editButton:"Edit"});
                   this.setState({disabled:false});
                   return res.data;
               })
              
           })
   }
   editUser(){
    this.editDoctor(this.props.user.user.user.id, this.state.doctor, this.props.user.user.jwt)
   }
   handleChange(event){
       let name=event.target.name;
       let value =event.target.type === 'number' ? parseInt(event.target.value) : event.target.value;
       value=event.target.type ==='checkbox'? event.target.checked:value;
       this.setState({doctor:{
           ...this.state.doctor,
        [name]:value
       }
       })

   }
   handleUpload=({file,onSuccess})=>{
       const jwt=this.props.user.user.jwt;
       const formData =new FormData();
       formData.append('files',file);
       axios.post("https://hosplanapi.html5.run/upload",formData,{
        headers: {
            Authorization: 'Bearer ' + jwt
        }
       }).then(res=>{
         
           this.setState({doctor:{
               ...this.state.doctor,
               Pictures:[res.data[0]]
           }})
           onSuccess("ok")
    }).catch(err=>console.log(err))
   }
   
   handleChangeImage = info => {
    
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
        
      getBase64(info.file.originFileObj, imageUrl =>{

          this.setState({
            imageUrl,
            loading: false,
          })
         
      }
        
      );
    }
  };

  


    render() {
        const { user } = this.props;
        const{doctor} =this.state;
        const { loading, imageUrl } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
        const{ image}=this.state;
      console.log("doctore",doctor)
      console.log("this.state",this.state)
        // console.log("doctorprofile::user",user);
        if (user.isLogedIn && user.user.user.type === "doctor") {
            // console.log("DASHBOARD/USER  user Object : ", user);
        } else {
            console.error("DASHBOARD/USER  user not allowed on this location : ", user.user.user.type);
        }


        return (
            <div>
                <Breadcrumb title={'Dashboard'} />


                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    <a className="popup-btn">
                                        my account
                                    </a>
                                </div>
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                        <span className="filter-back">
                                            <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li><a
                                                href={`${process.env.PUBLIC_URL}/pages/doctordashboard`}>Dashboard</a>
                                            </li>
                                            <li ><a
                                                href={`${process.env.PUBLIC_URL}/pages/doctorbookings`}>My Bookings</a>
                                            </li>
                                            <li><a href={`${process.env.PUBLIC_URL}/pages/doctorprofile`}>Edit Profile</a>
                                            </li>
                                            <li className="active"><a href={`${process.env.PUBLIC_URL}/pages/doctorprofileedit`}>Edit Doctor Profile</a>
                                            </li>
                                            {/*<li><a href="#">My Account</a></li>*/}
                                            {/*<li><a href="#">My Wishlist</a></li>*/}
                                            {/*<li><a href="#">Address Book</a></li>*/}
                                            {/*<li><a href="#">Newsletter</a></li>*/}
                                            {/*<li><a href="#">Change Password</a></li>*/}
                                            <li className="last"><a href="#" onClick={this.props.logout}>Log Out</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                       
                                            <section className="cart-section section-b-space">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-06">
                                                            <div className="page-title">
                                                                <h2>Edit Profile</h2>
                                                                {console.log(this.state.user,"hoooooooooooooooooooooooo")}
                                                            </div>
                                                            <div className="welcome-msg">
                                                                <p style={{ textTransform: 'capitalize' }}>Hello, {user.user.user.name} !</p>

                                                            </div>
                                                            <div style={{ padding: '5px' }}><br /></div>
                                                            {/*<h3 style={{marginBottom: '45px', textAlign: 'center'}}>My Bookings </h3>*/}
                                                            <Upload
                                                                name="avatar"
                                                                listType="picture-card"
                                                                className="avatar-uploader"
                                                                showUploadList={false}
                                                                customRequest={this.handleUpload}
                                                                
                                                                beforeUpload={beforeUpload}
                                                                onChange={this.handleChangeImage} 
                                                            >
                                                                {imageUrl ||image ? <img src={imageUrl||image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                                            </Upload>
                                                            <div className="hospitalEditProfileHeading">Basic Details</div>
                                                            <div   className="hospitalProfileEdit">
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Name">Name :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Name"
                                                                    name="Name"
                                                                    placeholder="Enter Your Name" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Name || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Phone">Phone :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Phone"
                                                                    name="Phone"
                                                                    placeholder=" Phone no" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Phone || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Email">Email :</label>
                                                                    <input 
                                                                    type="email" 
                                                                    id="Email"
                                                                    name="Email"
                                                                    placeholder="Enter Your Email" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Email || ""} />
                                                                </div>
                                                               </div>
                                                               <div className="hospitalEditProfileHeading">Education Details</div>
                                                            <div   className="hospitalProfileEdit">
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Department">Department :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Department"
                                                            name="Department"
                                                                    placeholder="Enter Your Department" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Department || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Designation">Designation :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Designation"
                                                                    name="Designation"
                                                                    placeholder="Enter Your Designation" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Designation || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Education">Education :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Education"
                                                                    name="Education"
                                                                    placeholder="Education" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Education || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Experience">Experience :</label>
                                                                    <input 
                                                                    type="number" 
                                                                    id="Experience"
                                                                    name="Experience"
                                                            
                                                                    placeholder="Enter Your Experience" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Experience || ""} />
                                                                </div>
                                                                </div>
                                                                <div className="hospitalEditProfileHeading">Address</div>
                                                                <div   className="hospitalProfileEdit">
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Address">Address :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Address"
                                                                    name="Address"
                                                                    placeholder="Enter Your Address" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Address || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="City">City :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="City"
                                                                name="City"
                                                                    placeholder="Enter Your City Name" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.City || ""} />
                                                                </div>
                                                                </div>
                                                                <div className="hospitalEditProfileHeading">Social</div>
                                                                <div   className="hospitalProfileEdit">
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Website">Website :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Website"
                                                                    name="Website"
                                                                    placeholder="Enter Your Website Url" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Website || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Facebook">Facebook :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Facebook"
                                                                    name="Facebook"
                                                                    placeholder="Enter Your Facebook Id" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Facebook || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Twitter">Twitter :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Twitter"
                                                                    name="Twitter"
                                                                    placeholder="Enter Your Twitter Handle" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Twitter || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Linkedin">Linkedin :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Linkedin"
                                                                    name="Linkedin"
                                                                    placeholder="Enter Your Linkedin Handle" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Linkedin || ""} />
                                                                </div>
                                                                </div>
                                                                <div className="hospitalEditProfileHeading">Pricing</div>
                                                                <div   className="hospitalProfileEdit">
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Price">Price :</label>
                                                                    <input 
                                                                    type="number" 
                                                                    id="Price"  
                                                                    name="Price"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Price || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="SalePrice">SalePrice :</label>
                                                                    <input 
                                                                    type="number" 
                                                                    id="SalePrice"
                                                                    name="SalePrice"
                                                                    placeholder="Enter Your Name" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.SalePrice || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Discount">Discount :</label>
                                                                    <input 
                                                                    type="number" 
                                                                    id="Discount"
                                                                    name="Discount"
                                                                    placeholder="Enter Your Name" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.Discount || ""} />
                                                                </div>
                                                                </div>
                                                                <div className="hospitalEditProfileHeading">Availability</div>
                                                                <div   className="hospitalProfileEdit">
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="DaysInAdvance">DaysInAdvance :</label>
                                                                    <input 
                                                                    type="number" 
                                                                    id="DaysInAdvance"
                                                                    name="DaysInAdvance"
                                                                    placeholder="Enter Your Days" 
                                                                    onChange={this.handleChange}
                                                                    value={doctor.DaysInAdvance || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="OnVideo">OnVideo :</label>
                                                                    <input 
                                                                    type="checkbox" 
                                                                    id="OnVideo"
                                                                name="OnVideo"
                                                                checked={doctor.OnVideo || ""}
                                                                    onChange={this.handleChange}
                                                                    value={doctor.OnVideo || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="OnPhone">OnPhone :</label>
                                                                    <input 
                                                                    type="checkbox" 
                                                                    id="OnPhone"
                                                                    name="OnPhone"
                                                                    checked={doctor.OnPhone || ""}
                                                                    onChange={this.handleChange}
                                                                    value={doctor.OnPhone || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="OnLocation">OnLocation :</label>
                                                                    <input 
                                                                    type="checkbox" 
                                                                    id="OnLocation"
                                                                    name="OnLocation"
                                                                    checked={doctor.OnLocation || ""}
                                                                    onChange={this.handleChange}
                                                                    value={doctor.OnLocation || ""} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="AvailableNow">AvailableNow :</label>
                                                                    <input 
                                                                    type="checkbox" 
                                                                    id="AvailableNow"
                                                                    name="AvailableNow"
                                                                    checked={doctor.AvailableNow || ""}
                                                                    onChange={this.handleChange}
                                                                    value={doctor.AvailableNow || ""} />
                                                                </div>
                                                                
                                                             </div>
                                                             <div className="hospitalProfileEditSingle">
                                                                    <button
                                                                        disabled={this.state.disabled}
                                                                        className="btn btn-solid"
                                                                        onClick={() => { this.editUser() }}
                                                                    >{this.state.editButton}</button>

                                                              </div>






                                                            {/*<table className="table cart-table table-responsive-md">*/}
                                                            {/*    <tfoot>*/}
                                                            {/*    <tr>*/}
                                                            {/*        <td>total price :</td>*/}
                                                            {/*        <td><h2>{symbol} {total} </h2></td>*/}
                                                            {/*    </tr>*/}
                                                            {/*    </tfoot>*/}
                                                            {/*</table>*/}
                                                        </div>
                                                    </div>

                                                </div>
                                            </section>
                                          
                                      

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

           

            </div>
        )
    }
}

const mapStateToProps = (state) => ({

    user: state.auth,
})

export default connect(
    mapStateToProps,
    {removeFromCart, incrementQty, decrementQty, logout,setCurrentUser}
)(DoctorProfile)


