import React, { Component } from 'react';
import Breadcrumb from "../commonAll/breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { getCartTotal, getUser } from "../../services";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { decrementQty, incrementQty, removeFromCart } from "../../redux/actions";
import appConfig from "../../config";
import axios from 'axios';
import { logout, setCurrentUser } from "../../redux/actions/authActions";
import { notification } from 'antd';
import { Collapse } from 'antd';

const { Panel } = Collapse;

function callback(key) {
    console.log(key);
}
class HospitalProfile extends Component {

    constructor(props) {
        super(props);
        this.eventHandle = this.eventHandle.bind(this)
        this.state = {
            user: getUser(),
            name: '',
            phone: '',
            email: '',
            userData: '',
            editButton: 'Edit',
            disabled: false,
            profileFiles: [],
            profileFileList: [],
            profilePhotoFlag: false

        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {


        this.setState({ name: this.props.user.user.user.hospital.name });
        this.setState({ phone: this.props.user.user.user.phone });
        this.setState({ email: this.props.user.user.user.email });
        this.setState({ userData: this.props.user.user.user.hospital });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setState({ name: this.props.user.user.user.hospital.name });
            this.setState({ phone: this.props.user.user.user.phone });
            this.setState({ email: this.props.user.user.user.email });
            this.setState({ userData: this.props.user.user.user.hospital });
        }
    }
    editHospital = async (id, data, jwt, resp = '') => {
        if (resp !== '') {
            console.log("Now create the report entry ", resp);
            data = { ...data, Pictures: resp.data[0] }
        }
        this.setState({ editButton: "Please Wait..." });
        this.setState({ disabled: true });
        return await axios
            .put(appConfig.hospitals + "/" + data.id, data, {
                headers: {
                    Authorization: 'Bearer ' + jwt
                }
            })
            .then(response => {
                // Handle success.
                axios
                    .get(appConfig.users + "/" + id, {
                        headers: {
                            Authorization: 'Bearer ' + jwt
                        }
                    })
                    .then(res => {
                        let data = {
                            jwt: jwt,
                            user: res.data
                        };
                        localStorage.setItem('user', JSON.stringify(data));
                        this.props.setCurrentUser(data);
                        notification.success({
                            message: "Hospital Updated Sucessfully",
                        });
                        //alert("UPDATED");
                        this.setState({ editButton: "Edit" });
                        this.setState({ disabled: false });
                        return res.data;
                    })

            })
    }
    editUser() {

        if (this.state.profilePhotoFlag)
            this.submitprofilePhoto();
        else
            this.editHospital(this.props.user.user.user.id, this.state.userData, this.props.user.user.jwt)


    }
    eventHandle(event) {
        let name = event.target.name;
        let value = event.target.type === 'number' ? parseInt(event.target.value) : event.target.value;

        this.setState({
            userData: {
                ...this.state.userData,
                [name]: value
            }
        })
    }
    eventRoomHandle(event) {
        let name = event.target.name;
        let value = event.target.type === 'number' ? parseInt(event.target.value) : event.target.value;
        
        this.setState({
            userData: {
                ...this.state.userData,
                Rooms: value
            }
        })
    }

    onChange = (event) => {
        console.log(event.target.files);

        this.setState({
            profileFiles: event.target.files,
            profileFileList: Array.from(event.target.files),
            profilePhotoFlag: true
        });
    }

    submitprofilePhoto = () => {
        //e.preventDefault();
        console.log("FILES : ", this.state.profileFiles);
        console.log("User : ", this.props.user.user);
        console.log("fileList : ", this.state.profileFileList);


        const formData = new FormData();
        Array.from(this.state.profileFiles).forEach(file => {
            formData.append('files', file);
        });

        const config = {
            headers: {
                Authorization: 'Bearer ' + this.props.user.user.jwt
            },
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                console.log(percentCompleted);

            }.bind(this)
        }

        axios
            .post(appConfig.upload, formData, config)
            .then(res => {

                this.editHospital(this.props.user.user.user.id, this.state.userData, this.props.user.user.jwt, res)
            })
            .catch(err => {
                console.log(err);
            });
    }




    render() {
        const { user } = this.props;


        console.log("doctorprofile::user", user);
        if (user.isLogedIn && user.user.user.type === "hospital") {
            console.log("DASHBOARD/USER  user Object : ", user);
        } else {
            console.error("DASHBOARD/USER  user not allowed on this location : ", user.user.user.type);
        }

        let rooms = [];
        if (this.state.userData.Rooms) {
            let count = 0;
            this.state.userData.Rooms.forEach(element => {
                rooms.push(
                    <Panel header={element.id} key={count}>
                        <div className="hospitalProfileEdit">
                            <div className="hospitalProfileEditSingle">
                                <label htmlFor="phn">Type :</label>
                                <input
                                    type="text"
                                    name="SalePrice"
                                    onChange={this.eventHandle}
                                    value={this.state.userData.Rooms[count].Type} />
                            </div>
                            <div className="hospitalProfileEditSingle">
                                <label htmlFor="phn">Charges :</label>
                                <input
                                    type="number"
                                    name="SalePrice"
                                    onChange={this.eventHandle}
                                    value={this.state.userData.Rooms[count].Charges} />
                            </div>
                        </div>
                        <div className="hospitalProfileEditSingle">
                            <label htmlFor="phn">Room Description :</label>
                            <textarea style={{ minHeight: "294px", maxHeight: "555px" }}
                                onChange={this.eventHandle}
                                name="NeuroDescription"
                                value={this.state.userData.Rooms[count++].Description} />
                        </div>
                    </Panel>
                )
            });
        }
        console.log("rooms", rooms)

        return (
            <div>
                <Breadcrumb title={'Dashboard'} />


                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    <a className="popup-btn ">
                                        my account
                                    </a>
                                </div>
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                        <span className="filter-back">
                                            <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li ><a
                                                href={`${process.env.PUBLIC_URL}/pages/hospitaldashboard`}>Dashboard</a></li>
                                            <li><a href={`${process.env.PUBLIC_URL}/pages/hospitalbookings`}>My Bookings</a>
                                            </li>
                                            <li className="active"><a href={`${process.env.PUBLIC_URL}/pages/hospitalprofile`}>Edit Profile</a>
                                            </li>
                                            {/*<li><a href="#">My Account</a></li>*/}
                                            {/*<li><a href="#">My Wishlist</a></li>*/}
                                            {/*<li><a href="#">Address Book</a></li>*/}
                                            {/*<li><a href="#">Newsletter</a></li>*/}
                                            {/*<li><a href="#">Change Password</a></li>*/}
                                            <li className="last"><a href="#" onClick={this.props.logout}>Log Out</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">

                                        <section className="cart-section section-b-space">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-06">
                                                        <div className="page-title">
                                                            <h2>Edit Profile</h2>
                                                        </div>
                                                        <div className="welcome-msg">
                                                            <p style={{ textTransform: 'capitalize' }}>Hello, {user.user.user.name} !</p>

                                                        </div>
                                                        <div style={{ padding: '5px' }}><br /></div>
                                                        {/*<h3 style={{marginBottom: '45px', textAlign: 'center'}}>My Bookings </h3>*/}
                                                        <div className="hospitalProfileEdit">
                                                            {/* <div className="hospitalProfileEditSingle" >
                                                                    <label htmlFor="name">User's Name :</label>
                                                                   
                                                                    <input 
                                                                    type="text" 
                                                                    id="name"
                                                                 
                                                                    placeholder="Enter Your Name" 
                                                                    onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={this.state.name} />
                                                                </div>
                                                                <div  className="hospitalProfileEditSingle">
                                                                    <label htmlFor="email">User's Email :</label>
                                                                  
                                                                    <input 
                                                                    type="text" 
                                                                   
                                                                    placeholder="Enter Your Email"
                                                                    onChange={(e)=>{this.setState({email:e.target.value})}}
                                                                    value={this.state.email} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                    <label htmlFor="phn">User's Phone No :</label>
                                                                    
                                                                    <input 
                                                                    type="text" 
                                                                 
                                                                    placeholder="Enter Your Phone" 
                                                                    onChange={(e)=>{this.setState({phone:e.target.value})}}
                                                                    value={this.state.phone} />
                                                                </div> */}
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Name :</label>

                                                                <input
                                                                    type="text"
                                                                    // style={{border:"solid rgb(190,190,190) 1px",paddingLeft:"10px"}} 
                                                                    onChange={this.eventHandle}
                                                                    name="Name"
                                                                    value={this.state.userData.Name} />
                                                            </div>
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Beds :</label>

                                                                <input
                                                                    type="number"
                                                                    // style={{border:"solid rgb(190,190,190) 1px",paddingLeft:"10px"}} 
                                                                    name="Beds"
                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.Beds} />
                                                            </div>

                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Established Year :</label>

                                                                <input
                                                                    type="number"
                                                                    name="EstablishedYear"
                                                                    // style={{border:"solid rgb(190,190,190) 1px",paddingLeft:"10px"}} 

                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.EstablishedYear} />
                                                            </div>
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Address :</label>

                                                                <input
                                                                    type="text"
                                                                    name="Address"
                                                                    // style={{border:"solid rgb(190,190,190) 1px",paddingLeft:"10px"}} 

                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.Address} />
                                                            </div>
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">City :</label>

                                                                <input
                                                                    type="text"
                                                                    name="City"
                                                                    // style={{border:"solid rgb(190,190,190) 1px",paddingLeft:"10px"}} 

                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.City} />
                                                            </div>
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Phone :</label>

                                                                <input
                                                                    type="text"
                                                                    name="Phone"
                                                                    // style={{border:"solid rgb(190,190,190) 1px",paddingLeft:"10px"}} 

                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.Phone} />
                                                            </div>
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Email :</label>

                                                                <input
                                                                    type="text"
                                                                    name="Email"
                                                                    // style={{border:"solid rgb(190,190,190) 1px",paddingLeft:"10px"}} 

                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.Email} />
                                                            </div>
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Website :</label>

                                                                <input
                                                                    type="text"
                                                                    name="Website"


                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.Website} />
                                                            </div>
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Video :</label>
                                                                <input
                                                                    type="text"
                                                                    name="Video"
                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.Video} />
                                                            </div>
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Logo :</label>
                                                                <input
                                                                    type="text"
                                                                    name="Logo"
                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.Logo} />
                                                            </div>
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Price :</label>
                                                                <input
                                                                    type="number"
                                                                    name="Price"
                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.Price} />
                                                            </div>
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">SalePrice :</label>
                                                                <input
                                                                    type="number"
                                                                    name="SalePrice"
                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.SalePrice} />
                                                            </div>
                                                            <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Discount :</label>
                                                                <input
                                                                    type="number"
                                                                    name="Discount"
                                                                    onChange={this.eventHandle}
                                                                    value={this.state.userData.Discount} />
                                                            </div>







                                                        </div>
                                                        <div style={{ padding: '5px' }}><br /></div>


                                                        <div className="hospitalProfileEditSingle">
                                                            <label htmlFor="phn">Short Details :</label>
                                                            <textarea style={{ minHeight: "294px", maxHeight: "555px" }}
                                                                onChange={this.eventHandle}
                                                                name="ShortDetails"
                                                                value={this.state.userData.ShortDetails} />
                                                        </div>
                                                        <div className="hospitalProfileEditSingle">
                                                            <label htmlFor="phn">Description :</label>
                                                            <textarea style={{ minHeight: "294px", maxHeight: "555px" }}
                                                                onChange={this.eventHandle}
                                                                name="Description"
                                                                value={this.state.userData.Description} />
                                                        </div>
                                                        <div className="hospitalProfileEditSingle">
                                                            <label htmlFor="phn">Cardio Description :</label>
                                                            <textarea style={{ minHeight: "294px", maxHeight: "555px" }}
                                                                onChange={this.eventHandle}
                                                                name="CardioDescription"
                                                                value={this.state.userData.CardioDescription} />
                                                        </div>
                                                        <div className="hospitalProfileEditSingle">
                                                            <label htmlFor="phn">Ortho Description :</label>
                                                            <textarea style={{ minHeight: "294px", maxHeight: "555px" }}
                                                                onChange={this.eventHandle}
                                                                name="OrthoDescription"
                                                                value={this.state.userData.OrthoDescription} />
                                                        </div>

                                                        <div className="hospitalProfileEditSingle">
                                                            <label htmlFor="phn">Transplat Description :</label>
                                                            <textarea style={{ minHeight: "294px", maxHeight: "555px" }}
                                                                onChange={this.eventHandle}
                                                                name="TransplatDescription"
                                                                value={this.state.userData.TransplatDescription} />
                                                        </div>
                                                        <div className="hospitalProfileEditSingle">
                                                            <label htmlFor="phn">Onco Description :</label>
                                                            <textarea style={{ minHeight: "294px", maxHeight: "555px" }}
                                                                onChange={this.eventHandle}
                                                                name="OncoDescription"
                                                                value={this.state.userData.OncoDescription} />
                                                        </div>
                                                        <div className="hospitalProfileEditSingle">
                                                            <label htmlFor="phn">Neuro Description :</label>
                                                            <textarea style={{ minHeight: "294px", maxHeight: "555px" }}
                                                                onChange={this.eventHandle}
                                                                name="NeuroDescription"
                                                                value={this.state.userData.NeuroDescription} />
                                                        </div>
                                                        <div className="hospitalProfileEditSingle">
                                                            <label htmlFor="phn">Picture :</label>
                                                            <div className="row" style={{ border: "1px solid black" }}>
                                                                <div className="col-sm-6">
                                                                    {(this.state.userData.Pictures) ?
                                                                        <img height="400px" width="400px" src={this.state.userData.Pictures[0].url} />
                                                                        : null
                                                                    }

                                                                </div>
                                                                <div className="col-sm-6" style={{ paddingTop: "137px" }}>
                                                                    Upload New Picture
                                                                    <input

                                                                        type="file"
                                                                        onChange={this.onChange} />
                                                                </div>
                                                            </div>
                                                            <br />
                                                            Rooms:
                                                            <Collapse onChange={callback}>
                                                               {rooms}
                                                            </Collapse>


                                                        </div>
                                                        <div className="hospitalProfileEditSingle">
                                                            <button
                                                                disabled={this.state.disabled}
                                                                className="btn btn-solid"
                                                                onClick={() => { this.editUser() }}
                                                            >{this.state.editButton}</button>
                                                        </div>







                                                        {/*<table className="table cart-table table-responsive-md">*/}
                                                        {/*    <tfoot>*/}
                                                        {/*    <tr>*/}
                                                        {/*        <td>total price :</td>*/}
                                                        {/*        <td><h2>{symbol} {total} </h2></td>*/}
                                                        {/*    </tr>*/}
                                                        {/*    </tfoot>*/}
                                                        {/*</table>*/}
                                                    </div>
                                                </div>

                                            </div>
                                        </section>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </div>
        )
    }
}

const mapStateToProps = (state) => ({

    user: state.auth,
})

export default connect(
    mapStateToProps,
    { removeFromCart, incrementQty, decrementQty, logout, setCurrentUser }
)(HospitalProfile)


