import React, {Component} from 'react';
import { withTranslate } from 'react-redux-multilingual'

// Custom Components
import HeaderOne from './commonAll/headers/header-one';
import HeaderTwo from './commonAll/headers/header-two';
import HeaderThree from './commonAll/headers/header-three';

import FooterOne from "./commonAll/footers/footer-one";
import FooterTwo from "./commonAll/footers/footer-two";
import FooterThree from "./commonAll/footers/footer-three";

// ThemeSettings
import ThemeSettings from "./commonAll/theme-settings"
import HeaderFive from "./commonAll/headers/header-five";
import FooterFour from "./commonAll/footers/footer-four";
import HeaderNew from "./commonAll/headers/header-new";
import HeaderInside from "./commonAll/headers/header-inside";



class App extends Component {

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color2.css`);
    };

    render() {
        return (
            <div>
                {/*<HeaderFive logoName={'logo-lite.png'} />*/}
                <HeaderInside logoName={'logo-lite.png'}/>
                {this.props.children}
                {/*<FooterOne logoName={'logo.png'}/>*/}
                <FooterFour logoName={'logo-dark.png'}/>

                {/*<ThemeSettings />*/}

            </div>
        );
    }
}

export default withTranslate(App);
