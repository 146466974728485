import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class DoctorBooking extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,

            quantity: 1,

            bookingType: "",
            bookingTime: "",

            stock: 'InStock',
            nav3: null,
            onVideoConf: false,
            onPhone: false,
            onLocation: false,



            onVideoEnable: 'none',
            onPhoneEnable: 'none',
            onLocationEnable: 'none',

            bookTypeColor: "#000",
            bookTimeColor: "#000",
        }
    }
    render() {

        const { symbol, item } = this.props;

        if (!item.Price) {

            item.Price = 500;

            // console.log("price",item.price);
        }
        if (item.OnVideo) {
            //this.setState({onVideoEnable:'block'})
            // this.setState({onVideoEnable : 'inline'});
            this.state.onVideoEnable = 'inline';
        }
        if (item.OnPhone) {
            //this.setState({onPhoneEnable:'block'})
            // this.setState({onPhoneEnable:'inline'})
            this.state.onPhoneEnable = 'inline';
        }
        if (item.OnLocation) {
            //this.setState({onLocationEnable:'block'})
            // this.setState({onLocationEnable:'inline'})
            this.state.onLocationEnable = 'inline';
        }

        return (
            <div>
                {item.Price ?
                    <div >

                        {(item.Discount) ?
                            <section style={{ backgroundColor: "rgb(19 162 19)",display:"flex",flexDirection:"column",alignItems:"center",margin:"0 -15px 0 -15px" }}>
                                <h4 style={{ color: "white"}}>Fee:
                                    <del style={{ color: "white" }}> &nbsp;{symbol}{item.Price}</del>
                                    <span> {item.Discount}% off</span>
                                </h4>
                                <h3 style={{ color: "white"}}>{symbol}{item.Price - (item.Price * item.Discount / 100)} </h3>
                            </section>
                            : <section style={{ backgroundColor: "rgb(19 162 19)",display:"flex",flexDirection:"column",alignItems:"center",margin:"0 -15px 0 -15px"  }}>
                                <h3 style={{ color: "white"}}>Fee: {symbol}{item.Price} </h3>
                            </section>
                        }
                        <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <ul className="color-variant">
                                        <span>Available On : </span>
                                        {item.OnVideo ? <img src="/assets/images/icons/videocam.svg" alt="React Logo"
                                            style={{
                                                width: '30px',
                                                height: '30px',
                                                padding: '5px',
                                                paddingBottom: '9px'
                                            }} /> :
                                            <img src="/assets/images/icons/videocam_off.svg" alt="React Logo"
                                                style={{ width: '30px', height: '30px', padding: '5px', paddingBottom: '9px' }} />}
                                        {item.OnPhone ? <img src="/assets/images/icons/phone.svg" alt="React Logo"
                                            style={{
                                                width: '30px',
                                                height: '30px',
                                                padding: '5px',
                                                paddingBottom: '9px'
                                            }} /> :
                                            <img src="/assets/images/icons/phone_disabled.svg" alt="React Logo"
                                                style={{ width: '30px', height: '30px', padding: '5px', paddingBottom: '9px' }} />}
                                        {item.OnLocation ? <img src="/assets/images/icons/location_on.svg" alt="React Logo"
                                            style={{
                                                width: '30px',
                                                height: '30px',
                                                padding: '5px',
                                                paddingBottom: '9px'
                                            }} /> :
                                            <img src="/assets/images/icons/location_off.svg" alt="React Logo"
                                                style={{ width: '30px', height: '30px', padding: '5px', paddingBottom: '9px' }} />}
                                    </ul>
                                    <Link to={`${process.env.PUBLIC_URL}/doctor/${item.slug}`}>
                                    <button className="btn btn-solid cardBtn"
                                        style={{ padding: "10px",width:"100%"}}
                                    >Book Consultation
                                            </button>
                                            </Link>
                        </div>
                    </div>
                    : null}
            </div>
        );
    }

}

export default DoctorBooking;
