import React, { Component } from 'react';
import Breadcrumb from "../commonAll/breadcrumb";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            airAmbulanceData: {
                Country: '',
                City: '',
                Destination: '',
                Hospital: '',
                Date: ''
            }
        }
    }

    componentDidMount() {
        let href = window.location.href;
        let result = href.split("=");
        let data = JSON.parse(decodeURIComponent(result[1]));
        this.setState({
            airAmbulanceData: data,
        })
        console.log("ambulance::main", data);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {

            let href = window.location.href;
            let result = href.split("=");
            let data = JSON.parse(decodeURIComponent(result[1]));
            this.setState({
                airAmbulanceData: data,
            })

        }

    }


    render() {
        return (
            <div>
                {/* <img width="100%" height="750px" src="/assets/images/5070.jpg"/> */}

                <Breadcrumb title={'Air Ambulance'} />



                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="theme-card">
                                    <form className="theme-form">

                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label>Country</label>
                                                <input type="text" className="form-control" value={this.state.airAmbulanceData.Country}
                                                    placeholder="Name" required="" readOnly />


                                            </div>
                                            <div className="col-md-6">
                                                <label>City</label>
                                                <input type="text" className="form-control" value={this.state.airAmbulanceData.City}
                                                    placeholder="Phone" required="" readOnly />

                                            </div>
                                            <div className="col-md-6">
                                                <label>Destination</label>
                                                <input type="text" className="form-control" value={this.state.airAmbulanceData.Destination}
                                                    placeholder="Name" required="" readOnly />


                                            </div>
                                            <div className="col-md-6">
                                                <label>Hospital</label>
                                                <input type="text" className="form-control" value={this.state.airAmbulanceData.Hospital}
                                                    placeholder="Phone" required="" readOnly />

                                            </div>
                                            <div className="col-md-6">
                                                <label>Date</label>
                                                <input type="text" className="form-control" value={this.state.airAmbulanceData.Date}
                                                    placeholder="Phone" required="" readOnly />

                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label>Name</label>
                                                <input type="text" className="form-control"
                                                    placeholder="Phone" required="" />

                                            </div>
                                            <div className="col-md-6">
                                                <label>Phone No.</label>
                                                <input type="text" className="form-control"
                                                    placeholder="Phone" required="" />

                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="btn btn-solid"
                                                style={{ marginLeft: '10px', marginTop: "20px" }}>Submit</div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </div>
        );
    }
}
export default Main;