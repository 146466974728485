import * as types from '../../constants/ActionTypes'

const hospitalReducerDefaultState = {
    isLoading: false,
    hospitals: [],
    hospitalDetails: {}
};

const hospitalReducer = (state = hospitalReducerDefaultState, action) => {
    //console.log('HOSPITAL/REDUCER : ', action);
    //console.log(action);
    switch (action.type) {
        case types.FETCH_HOSPITALS_BEGIN:
            return {
                isLoading: true,
                hospitals: [],
                hospitalDetails: {}
            }
        case types.RECEIVE_HOSPITALS:
            return {
                isLoading: false,
                hospitals: action.hospitals,
            };
        case types.FETCH_SINGLE_HOSPITALS:
            return {
                isLoading: false,
                hospitalDetails: action.hospital
            };
        default:
            return state;
    }
}

export default hospitalReducer;