import React, {Component} from 'react';
import Slider from 'react-slick';
import {connect} from 'react-redux'

import {getTrendingCollection} from '../../../services/index'
import {Product4, Product5} from '../../../services/script'
import {addToCart, addToWishlist, addToCompare} from "../../../redux/actions/index";
import ProductItem from '../../_old/features/product/common/product-style-five';

class TopCollection extends Component {

    render() {

        const {symbol, addToCart, addToWishlist, addToCompare, type, hospitals} = this.props;

        console.log("COLLECTIONS HOME : ", hospitals);

        let properties;
        if (type === 'kids') {
            properties = Product5
        } else {
            properties = Product4
        }

        return (
            <div>
                {/*Paragraph*/}
                {this.props.showHeading === "No" ?
                    "" :
                    <div className="title1  section-t-space">
                        <h4>Featured Hospitals</h4>
                        <h2 className="title-inner1">Top Rated</h2>
                    </div>
                }

                {/*Paragraph End*/}
                <section className="section-b-space p-t-0">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <Slider {...properties} className="product-4 product-m no-arrow">
                                    {hospitals.hospitals.map((product, index) =>
                                        <div key={index}>
                                            <ProductItem product={product} symbol={symbol}
                                                         onAddToCompareClicked={() => addToCompare(product)}
                                                         onAddToWishlistClicked={() => addToWishlist(product)}
                                                         onAddToCartClicked={() => addToCart(product, 1)} key={index}/>
                                        </div>)
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: getTrendingCollection(state.data.products, ownProps.type),
    symbol: state.data.symbol
})

export default connect(mapStateToProps, {addToCart, addToWishlist, addToCompare})(TopCollection);