import React, {Component} from 'react';
// import Slider from 'react-slick';
// import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

import {getSpecialCollection} from '../../services'
import {
    addToCart,
    addToWishlist,
    addToCompare,
    incrementQty,
    decrementQty,
    removeFromCart
} from "../../redux/actions";
import {Link} from "react-router-dom";

// import ProductItem from '../layouts/common/special-product-item';

class CityBlock extends Component {

    constructor() {
        super();
        this.state = {
            city: {}
        }
    }

    render() {

        const {city} = this.props;
        //console.log("city recived ", city);

        return (<div>
                <div className="category-wrapper" style={{
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    borderRadius: '8px',
                    borderColor: '#ffffff00',
                    position: 'sticky'
                }}>
                    <div>
                        <a href={`${process.env.PUBLIC_URL}/hospitals/${city.city.toLowerCase()}`}>


                            <div className="collection-banner" style={{borderRadius: '8px', position: 'relative'}}>
                                <img
                                    src={`${city.picture}`}
                                    className="img-fluid blur-up lazyload bg-img" alt=""/>
                            </div>
                            <div style={{
                                width: '100%',
                                height: '35%',
                                background: 'linear-gradient(180deg,rgba(6,27,65,0) 0,rgba(6,27,65,.95))',
                                position: 'absolute',
                                bottom: '0px',
                                borderRadius: '8px',
                                pointerEvents: 'none'
                            }}/>
                            <div
                                style={{
                                    textAlign: 'left',
                                    paddingLeft: '5px',
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                    position: 'absolute',
                                    bottom: '0px',
                                    fontSize: '22px',
                                    color: '#fff',
                                    left: '0px',
                                    fontWeight: '700'
                                }}>{city.city}
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state, Ownprops) => ({
    product: getSpecialCollection(state.data.products, Ownprops.type),
    symbol: state.data.symbol
})

export default connect(mapStateToProps,
    {
        addToCart,
        addToWishlist,
        addToCompare,
        incrementQty,
        decrementQty,
        removeFromCart
    })(CityBlock);