import appConfig from "../config";
import axios from "axios";
// import {getLoggedInUser} from "./authUtils";
// import {isUserAuthenticated} from "./authUtils";
// import qs from "qs";

const editUser = async (id, data, jwt) => {
  return await axios
    .put(appConfig.users + "/" + id, data, {
      headers: {
        Authorization: "Bearer " + jwt,
      },
    })
    .then((response) => {
      // Handle success.
      axios
        .get(appConfig.users + "/" + id, {
          headers: {
            Authorization: "Bearer " + jwt,
          },
        })
        .then((res) => {
          let data = {
            jwt: jwt,
            user: res.data,
          };
          localStorage.setItem("user", JSON.stringify(data));
          alert("UPDATED");
          return res.data;
        });
    });
};

const editHospital = async (id, data, jwt) => {
  return await axios
    .put(appConfig.hospitals + "/" + data.id, data, {
      headers: {
        Authorization: "Bearer " + jwt,
      },
    })
    .then((response) => {
      // Handle success.
      axios
        .get(appConfig.users + "/" + id, {
          headers: {
            Authorization: "Bearer " + jwt,
          },
        })
        .then((res) => {
          let data = {
            jwt: jwt,
            user: res.data,
          };
          localStorage.setItem("user", JSON.stringify(data));
          alert("UPDATED");
          return res.data;
        });
    });
};

const getMyLocation = async () => {
  return await axios.get(appConfig.locationApi).then((response) => {
    // Handle success.
    // console.log("Location Received", response.data);
    return response.data;
  });
};

const getSearchData = async (cat) => {
  return await axios
    .get(appConfig.home + "/search?cat=" + cat)
    .then((response) => {
      // Handle success.
      // console.log(cat, ': Data Received', response.data);
      return response.data;
    });
};

const getHospitalList = async () => {
  
  return await axios
    .get(appConfig.hospitals)
    .then((response) => {
      // Handle success.
       // console.log(': getHospitalList Received', response.data);
      return response.data;
    });
};

const getSearchParamID = async (cat, q) => {
  let query = "?q=" + q.split("(")[0] + "&cat=" + cat;

  return await axios
    .get(appConfig.home + "/search" + query)
    .then((response) => {
      // Handle success.
      // console.log(cat, ': Data Received', response.data);
      return response.data;
    });
};

const getHomeContent = async () => {
  return await axios.get(appConfig.home).then((response) => {
    // Handle success.
    // console.log('HOME-PAGE: Data Received', response.data);
    return response.data;
  });
};

const getParams = (url) => {
  let params = {};
  let parser = document.createElement("a");
  parser.href = url;
  let query = parser.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

const getDoctors = async (query) => {
  // let query = "?q=" + q + "&cat=" + cat;
  // console.log("SEARCH :: Query is : ", query)
  let newQuery = query.replace("doctor", "id");
  //   //////////////////////////////////////////// iam going to change error here in the query
  return await axios.get(appConfig.doctors + newQuery).then((response) => {
    // Handle success.
    // console.log('SEARCH :: Doctors Received', query, response.data);
    return response.data;
  });
};

const getHospitals = async (query) => {
  // let query = "?q=" + q + "&cat=" + cat;
  console.log("Query is : ", query);
  let newQuery = query.replace("hospital", "id");
  //   //////////////////////////////////////////// iam going to change error here in the query
  return await axios.get(appConfig.hospitals + newQuery).then((response) => {
    // Handle success.
    // console.log(': Data Received', query, response.data);
    return response.data;
  });
};

export {
  getHospitalList,
  getMyLocation,
  editUser,
  getSearchData,
  getHomeContent,
  getParams,
  getSearchParamID,
  getDoctors,
  getHospitals,
  editHospital,
};
