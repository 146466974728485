import * as types from "../../constants/ActionTypes";
import axios from "axios";
import appConfig from "../../config";

// Hospital Service
export const getDoctors = data => dispatch => {
    // console.log("DOCTOR/ACTIONS: getHospitals ", data);
    let param = getFilterDoctorParams(data);
    // console.log("DOCTOR/ACTIONS: PARAMS ARE : ", param);
    axios
        .get(appConfig.doctors + param)
        .then(response => {
            // Handle success.
            // console.log('SEARCH :: DOCTOR/ACTIONS: Data Received', response);
            let doctors = response.data;
            dispatch({
                type: types.RECEIVE_DOCTORS,
                doctors
            })
        })
}

export const getDoctor = () => dispatch => {
    // console.log('DOCTOR/ACTIONS : Single');
    //localStorage.removeItem('user');
    dispatch({
        type: types.LOGOUT
    })
}

export const getFilterDoctorParams = (filters) => {
    let speciality = filters.docspecial ? filters.docspecial : [];
    let city = filters.dcity ? filters.dcity : [];
    let rate = filters.drate ? filters.drate : {min: 0, max: 5};
    let price = filters.dprices ? filters.dprices : {min: 0, max: 1500};

    let str = "?Rating_lte=" + rate.max + "&Rating_gte=" + rate.min;
    //str += "&Price_lte=" + price.max + "&Price_gte=" + price.min;

    if (city.length) {
        city.forEach(c => {
            str += "&City=" + c;
        })
    }
    if (speciality.length) {
        speciality.forEach(s => {
            str += "&Specialization_in=" + s;
        })
    }
    return str;
}
