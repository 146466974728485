import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { Card } from 'antd';

//const { Meta } = Card;
import { Rate } from 'antd';

const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

class HospitalListItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            image: ''
        }
    }

    onOpenModal = () => {
        this.setState({open: true});
    };

    onCloseModal = () => {
        this.setState({open: false});
    };

    onClickHandle(img) {
        this.setState({image: img});
    }

    minusQty = () => {
        if (this.state.quantity > 1) {
            this.setState({stock: 'InStock'})
            this.setState({quantity: this.state.quantity - 1})
        }
    }

    plusQty = () => {
        if (this.props.product.stock >= this.state.quantity) {
            this.setState({quantity: this.state.quantity + 1})
        } else {
            this.setState({stock: 'Out of Stock !'})
        }
    }
    changeQty = (e) => {
        this.setState({quantity: parseInt(e.target.value)})
    }


    render() {
        const {product, symbol, onAddToCartClicked, onAddToWishlistClicked, onAddToCompareClicked} = this.props;
        const {open} = this.state;
        //console.log("HOSPITAL/LIST-ITEM : ", product);
        let RatingStars = []
        for (var i = 0; i < product.Rating; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }

        return (

            //style={{boxShadow: '0px 0px 28px -4px rgba(0,0,0,0.75)'}}
            <div className="product-box" >
                <Link to={`${process.env.PUBLIC_URL}/hospital/${product.Alias}`}>
                <Card
                    hoverable
                    style={{ width: 240 }}
                    // title={<Rate disabled value={product.Rating} style={{ fontSize: 14, color: '#ffa201' }}/>}
                    cover={<img alt="example" src={product.variants ?
                        this.state.image ? this.state.image : product.variants[0].images
                        : product.Pictures[0].url} />}
                >
                    {/*<Meta title="Europe Street beat" description="www.instagram.com" />*/}
                    {/*<div className="img-wrapper">*/}
                    {/*    <div className="front">*/}
                    {/*        <Link to={`${process.env.PUBLIC_URL}/hospital/product/${product.id}`}><img*/}
                    {/*            src={product.variants ?*/}
                    {/*                this.state.image ? this.state.image : product.variants[0].images*/}
                    {/*                : product.Pictures[0].url}*/}
                    {/*            className="img-fluid"*/}
                    {/*            alt=""/></Link>*/}
                    {/*    </div>*/}
                        <div className="cart-info cart-wrap">
                            {/*<button title="Add to cart" onClick={() => onAddToCartClicked(product, 1)}>*/}
                            {/*    <i className="fa fa-shopping-cart" aria-hidden="true"></i>*/}
                            {/*</button>*/}
                            <div title="Add to Wishlist" onClick={onAddToWishlistClicked}>
                                <i className="fa fa-heart" aria-hidden="true"/>
                            </div>
                            {/*<a href="javascript:void(0)" data-toggle="modal"*/}
                            {/*   data-target="#quick-view"*/}
                            {/*   title="Quick View"*/}
                            {/*   onClick={this.onOpenModal}><i className="fa fa-search" aria-hidden="true"></i></a>*/}
                            {/*<Link to={`${process.env.PUBLIC_URL}/compare`} title="Compare" onClick={onAddToCompareClicked}>*/}
                            {/*    <i className="fa fa-refresh" aria-hidden="true"></i></Link>*/}
                        </div>
                    {/*    {product.variants ?*/}
                    {/*        <ul className="product-thumb-list">*/}
                    {/*            {product.variants.map((vari, i) =>*/}
                    {/*                <li className={`grid_thumb_img ${(vari.images === this.state.image) ? 'active' : ''}`}*/}
                    {/*                    key={i}>*/}
                    {/*                    <a href="javascript:void(0)" title="Add to Wishlist">*/}
                    {/*                        <img src={`${vari.images}`} onClick={() => this.onClickHandle(vari.images)}/>*/}
                    {/*                    </a>*/}
                    {/*                </li>)*/}
                    {/*            }*/}
                    {/*        </ul> : ''}*/}

                    {/*</div>*/}
                    <div className="product-detail">
                        <div>
                            {/*<div className="rating">*/}
                            {/*    {RatingStars}*/}
                            {/*</div>*/}
                            <Rate disabled value={product.Rating} style={{ fontSize: 14, color: '#ffa201' }}/>
                            {/*<Link to={`${process.env.PUBLIC_URL}/hospital/product/${product.id}`}>*/}
                                <h6>{product.Name}</h6>
                            {/*</Link>*/}
                            {/*<h4>{symbol}{product.price-(product.price*product.discount/100)}*/}
                            {/*    <del><span className="money">{symbol}{product.price}</span></del></h4>*/}
                            {/*{product.variants?*/}
                            {/*<ul className="color-variant">*/}
                            {/*    {product.variants.map((vari, i) => {*/}
                            {/*        return (*/}
                            {/*            <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)*/}
                            {/*    })}*/}
                            {/*</ul>:''}*/}
                        </div>
                    </div>
                </Card>
                </Link>

                {/*<Modal open={open} onClose={this.onCloseModal} center>*/}
                {/*    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">*/}
                {/*        <div className="modal-content quick-view-modal">*/}
                {/*            <div className="modal-body">*/}
                {/*                <div className="row">*/}
                {/*                    <div className="col-lg-6  col-xs-12">*/}
                {/*                        <div className="quick-view-img">*/}
                {/*                            <img src={product.variants ?*/}
                {/*                                this.state.image ? this.state.image : product.variants[0].images*/}
                {/*                                : product.Pictures[0].Picture} alt="" className="img-fluid"/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className="col-lg-6 rtl-text">*/}
                {/*                        <div className="product-right">*/}
                {/*                            <h2> {product.name} </h2>*/}
                {/*                            <h3>{symbol}{product.price - (product.price * product.discount / 100)}*/}
                {/*                                <del><span className="money">{symbol}{product.price}</span></del>*/}
                {/*                            </h3>*/}
                {/*                            {product.variants ?*/}
                {/*                                <ul className="color-variant">*/}
                {/*                                    {product.variants.map((vari, i) =>*/}
                {/*                                        <li className={vari.color} key={i} title={vari.color}*/}
                {/*                                            onClick={() => this.onClickHandle(vari.images)}></li>)*/}
                {/*                                    }*/}
                {/*                                </ul> : ''}*/}
                {/*                            <div className="border-product">*/}
                {/*                                <h6 className="product-title">product details</h6>*/}
                {/*                                <p>{product.shortDetails}</p>*/}
                {/*                            </div>*/}
                {/*                            <div className="product-description border-product">*/}
                {/*                                {product.size ?*/}
                {/*                                    <div className="size-box">*/}
                {/*                                        <ul>*/}
                {/*                                            {product.size.map((size, i) => {*/}
                {/*                                                return <li key={i}><a href="#">{size}</a></li>*/}
                {/*                                            })}*/}
                {/*                                        </ul>*/}
                {/*                                    </div> : ''}*/}
                {/*                                <h6 className="product-title">quantity</h6>*/}
                {/*                                <div className="qty-box">*/}
                {/*                                    <div className="input-group">*/}
                {/*                                              <span className="input-group-prepend">*/}
                {/*                                                <button type="button"*/}
                {/*                                                        className="btn quantity-left-minus"*/}
                {/*                                                        onClick={this.minusQty} data-type="minus"*/}
                {/*                                                        data-field="">*/}
                {/*                                                 <i className="fa fa-angle-left"></i>*/}
                {/*                                                </button>*/}
                {/*                                              </span>*/}
                {/*                                        <input type="text" name="quantity" value={this.state.quantity}*/}
                {/*                                               onChange={this.changeQty}*/}
                {/*                                               className="form-control input-number"/>*/}
                {/*                                        <span className="input-group-prepend">*/}
                {/*                                                <button type="button"*/}
                {/*                                                        className="btn quantity-right-plus"*/}
                {/*                                                        onClick={this.plusQty} data-type="plus"*/}
                {/*                                                        data-field="">*/}
                {/*                                                <i className="fa fa-angle-right"></i>*/}
                {/*                                                </button>*/}
                {/*                                               </span>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className="product-buttons">*/}
                {/*                                <button className="btn btn-solid"*/}
                {/*                                        onClick={() => onAddToCartClicked(product, this.state.quantity)}>add*/}
                {/*                                    to cart*/}
                {/*                                </button>*/}
                {/*                                <Link to={`${process.env.PUBLIC_URL}/hospital/product/${product.id}`}*/}
                {/*                                      className="btn btn-solid">view detail</Link>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Modal>*/}
            </div>
        )
    }
}

export default HospitalListItem;