import React, { Component } from "react";

import { IntlActions } from "react-redux-multilingual";

import { withRouter } from "react-router-dom";
import moment from "moment";
// Import custom components
import store from "../../../redux/store";

import { Layout, Menu } from "antd";
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import {
  addToCart,
  addToCompare,
  addToWishlist,
  changeCurrency,
  filterSearchString,
  getSuggestionsConditions,
  getSuggestionsDoctors,
  getSuggestionsHospitals,
  getSuggestionsSpeciality,
  getSuggestionsTreatments,
  filterClean,
} from "../../../redux/actions";
import { connect } from "react-redux";
import TopBarDark from "./common/topbar-dark";
import LogoImage from "./common/logo";
import { Input, Select, Skeleton, InputNumber, DatePicker } from "antd";

import {
  faUserMd,
  faHospital,
  faBriefcaseMedical,
  faGlobe,
  faHotel,
  faPlane,
  faHeartbeat,
  faTimes,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
// import Select from "react-select";

import { getParams, getSearchData } from "../../../api/apicalls";
// import history from "../../../services/history";

import { getDoctors } from "../../../redux/actions/doctorActions";
import { getSuggestions } from "../../../redux/actions/index";
import Tab from "./header-tabs";
import SubmenuList from "./headerSubmenuList";
import TreatmentList from "./lists/treatmentList";
import HospitalList from "./lists/hospitalList";
import DoctorList from "./lists/doctorList";
import PreventionList from "./lists/preventionList";
import axios from "axios";
import appConfig from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Sider } = Layout;

const { Option } = Select;
const components = {
  DropdownIndicator: null,
  //NoOptionsMessage: "Searching ..."
};

function disabledDate(current) {
  // Can not select days before today and today

  return current && current < moment().startOf("day");
}

class HeaderInside extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderOpen: true,
      isLoading: false,
      showForm: false,
      searchName: "search",
      tabSelected: "treatment",
      selectedDoctorOption: [],
      selectedHospitalOption: [],
      selectedSpecialOption: [],
      suggestionsDoc: [],
      suggestionsHos: [],
      conditionpcl: [],
      treatmentSpcl: [],
      hospitalSpcl: [],
      doctorSpcl: [],
      // suggestionsSpcl: appConfig.docspecial,
      suggestionsSpcl: [],
      children: [],
      menu: 1,
      menuVisible: false,
      defaultTreatment: "",
      defaultCondition: "",
      defaultSpecialisation: "",
      defaultHospital: "",
      defaultDoctor: "",

      medicalVisaData: {
        CountryofOrigin: "",
        DestinationCountry: "",
        DestinationHospital: "",
        TravelDate: "",
        NoofAttendents: "",
      },
      airAmbulanceData: {
        Country: "",
        City: "",
        Destination: "",
        Hospital: "",
        Date: "",
      },
    };
  }

  getHospitalList() {
    // let param = "/" + id;

    axios.get(appConfig.hospitals).then((response) => {
      // console.log("response", response.data);
      let hospitalList = [];
      hospitalList.push(
        <option value="" key="please" disabled>
          Please Select
        </option>
      );
      response.data.forEach((element) => {
        // console.log("Element is : ", element);
        hospitalList.push(
          <option value={element.name} key={element.id}>
            {element.Name}
          </option>
        );
      });
      this.setState({ hospitalList });
    });
  }

  /*=====================
             Pre loader
        ==========================*/
  componentDidMount() {
    // setTimeout(function() {
    //     document.querySelector(".loader-wrapper").style = "display: none";
    // }, 2000);

    this.getFinderData();
    this.getSearchParam();
    let href = window.location.href;
    let result = href.split("/");
    let Param = result[result.length - 2];

    if (Param === "search") {
      this.setState({ tabSelected: "treatment" });
      localStorage.removeItem("state");
    }
    if (Param === "hos") {
      this.setState({ tabSelected: "hospital" });
      localStorage.removeItem("state");
    }
    if (Param === "doct") {
      this.setState({ tabSelected: "doctor" });
      localStorage.removeItem("state");
    }
    if (Param === "medicalvisa") {
      this.setState({ tabSelected: "medicalvisa" });

      localStorage.removeItem("state");
    }

    if (Param === "accomodation") {
      this.setState({ tabSelected: "accomodation" });
      localStorage.removeItem("state");
    }
    if (Param === "airambulance") {
      this.setState({ tabSelected: "airambulance" });
      localStorage.removeItem("state");
    }

    this.getHospitalList();
  }

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.searchString !== this.props.searchString) {
      // console.log("HEADER :: searchString Updated");
      this.setState(
        {
          menuVisible: false,
        },
        this.getSearchParam
      );
      // this.getSearchParam();
    }
  }

  //new code
  handleTab = (tabSelected, search) => {
    // console.log("tabSelected", tabSelected);
    this.setState({
      tabSelected: tabSelected,
      searchName: search,
    });
  };
  handleTab1 = (tabSelected, search) => {
    // console.log("tabSelected", tabSelected);
    this.setState({
      tabSelected: tabSelected,
      searchName: search,
      showForm: true,
    });
  };

  handleInputFirst = (event) => {
    if (event.target.value === "") {
      document.getElementById(event.target.name).style.display = "inline";
      this.setState({
        medicalVisaData: {
          ...this.state.medicalVisaData,
          [event.target.name]: event.target.value,
        },
      });
    } else {
      document.getElementById(event.target.name).style.display = "none";
      this.setState({
        medicalVisaData: {
          ...this.state.medicalVisaData,
          [event.target.name]: event.target.value,
        },
      });
    }
    this.medicalVisaHandler(event);
    //  console.log("med-visa",document.getElementById('med-visa').childNodes[0].childNodes[1].value);
  };

  handleAirAmbulanceInputFirst = (event) => {
    if (event.target.value === "") {
      document.getElementById(event.target.name).style.display = "inline";
      this.setState({
        medicalVisaData: {
          ...this.state.medicalVisaData,
          [event.target.name]: event.target.value,
        },
      });
    } else {
      document.getElementById(event.target.name).style.display = "none";
      this.setState({
        medicalVisaData: {
          ...this.state.medicalVisaData,
          [event.target.name]: event.target.value,
        },
      });
    }
    this.airAmbulanceHandler(event);
    //  console.log("med-visa",document.getElementById('med-visa').childNodes[0].childNodes[1].value);
  };

  handleTravelDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({
      medicalVisaData: {
        ...this.state.medicalVisaData,
        TravelDate: dateString,
      },
    });
  };

  handleAirAmbulanceDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({
      airAmbulanceData: { ...this.state.airAmbulanceData, Date: dateString },
    });
  };

  handleInput = (event) => {
    if (event.target.value === "") {
      document.getElementById(event.target.name).style.display = "inline";
      this.setState({
        medicalVisaData: {
          ...this.state.medicalVisaData,
          [event.target.name]: event.target.value,
        },
      });
    } else {
      document.getElementById(event.target.name).style.display = "none";
      this.setState({
        medicalVisaData: {
          ...this.state.medicalVisaData,
          [event.target.name]: event.target.value,
        },
      });
    }

    //  console.log("med-visa",document.getElementById('med-visa').childNodes[0].childNodes[1].value);
  };

  handleAirAmbulanceInput = (event) => {
    if (event.target.value === "") {
      document.getElementById(event.target.name).style.display = "inline";
      this.setState({
        airAmbulanceData: {
          ...this.state.airAmbulanceData,
          [event.target.name]: event.target.value,
        },
      });
    } else {
      document.getElementById(event.target.name).style.display = "none";
      this.setState({
        airAmbulanceData: {
          ...this.state.airAmbulanceData,
          [event.target.name]: event.target.value,
        },
      });
    }

    //  console.log("med-visa",document.getElementById('med-visa').childNodes[0].childNodes[1].value);
  };

  medicalVisaHandler = (tab) => {
    let data = this.state.medicalVisaData;
    let flag = true;

    for (let x in data) {
      if (data[x] === "") {
        flag = false;
        break;
      }
    }

    if (flag) {
      console.log("we Are Ready to Go to Next Page with Data", data);
      let stringifyData = JSON.stringify(data);
      let path = "/medicalvisa/city?data=" + stringifyData;
      this.props.history.push(path);
      // eslint-disable-next-line no-restricted-globals
      // location.href = path;
      // window.open(path,"_self")
    }

    // let medVisa=document.getElementById('med-visa').childNodes;
    // let allvalueEnterd=true;
    // let valueToPass=[];
    // for (let i = 0; i < medVisa.length; i++) {
    //     let inputValue=medVisa[i].childNodes[1].value;
    //     let inputName=medVisa[i].childNodes[1].name;
    //     if(inputValue==='')
    //     {
    //         allvalueEnterd=false;
    //         break;
    //     }
    //     valueToPass.push({name:inputName,value:inputValue})
    //    // console.log("med-visa",medVisa[i].childNodes[1].name);
    //   }
    //   if(allvalueEnterd)
    //   {
    //     //Write Code Here For Next Page
    //     console.log("ready to Go with Following Data",valueToPass);
    //   }
  };

  airAmbulanceHandler = (tab) => {
    let data = this.state.airAmbulanceData;
    let flag = true;

    for (let x in data) {
      if (data[x] === "") {
        flag = false;
        break;
      }
    }

    if (flag) {
      console.log("we Are Ready to Go to Next Page with Data", data);
      let stringifyData = JSON.stringify(data);
      let path = "/airambulance/city?data=" + stringifyData;
      this.props.history.push(path);
    }

    // let medVisa=document.getElementById('med-visa').childNodes;
    // let allvalueEnterd=true;
    // let valueToPass=[];
    // for (let i = 0; i < medVisa.length; i++) {
    //     let inputValue=medVisa[i].childNodes[1].value;
    //     let inputName=medVisa[i].childNodes[1].name;
    //     if(inputValue==='')
    //     {
    //         allvalueEnterd=false;
    //         break;
    //     }
    //     valueToPass.push({name:inputName,value:inputValue})
    //    // console.log("med-visa",medVisa[i].childNodes[1].name);
    //   }
    //   if(allvalueEnterd)
    //   {
    //     //Write Code Here For Next Page
    //     console.log("ready to Go with Following Data",valueToPass);
    //   }
  };

  getSearchParam = () => {
    // console.log("SEARCH :: Get Search Header Parameter : ")
    // eslint-disable-next-line no-restricted-globals
    let url = location.href;

    let params = getParams(url);
    console.log(url, params);
    // console.log("SEARCH :: Get Search Header Parameter : ", params)
    if (params["Treatment"]) {
      this.setState({
        // defaultTreatment: params['Treatment'].replace('-', ' ')
        defaultTreatment: params["Treatment"].replaceAll("-", " "),
        // menuVisible: true,
      });
    }
    if (params["Condition"]) {
      this.setState({
        defaultCondition: params["Condition"].replaceAll("-", " "),
        // menuVisible: true,
      });
    }
    if (params["Speciality"]) {
      this.setState({
        defaultSpecialisation: params["Speciality"].replaceAll("-", " "),
        // menuVisible: true,
      });
    }
    if (params["Hospital"]) {
      this.setState({
        defaultHospital: params["Hospital"].replaceAll("-", " "),
        // menuVisible: true,
      });
    }
    if (params["Doctor"]) {
      this.setState({
        defaultDoctor: params["Doctor"].replaceAll("-", " "),
        // menuVisible: true,
      });
    }
    this.setState({
      menuVisible: true,
    });
  };

  getFinderData = () => {
    if (!this.props.suggTreatments || !this.props.suggTreatments.length)
      this.props.getSuggestionsTreatments();

    if (!this.props.suggConditions || !this.props.suggConditions.length)
      this.props.getSuggestionsConditions();

    if (
      !this.props.suggSpecialisations ||
      !this.props.suggSpecialisations.length
    )
      this.props.getSuggestionsSpeciality();

    if (!this.props.suggHospitals || !this.props.suggHospitals.length)
      this.props.getSuggestionsHospitals();

    if (!this.props.suggDoctors || !this.props.suggDoctors.length)
      this.props.getSuggestionsDoctors();
  };

  handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    // console.log("the Number is ", number);
    if (number >= 300) {
      if (window.innerWidth < 576) {
        document.getElementById("sticky").classList.remove("hidden");
        document.getElementById("sticky").classList.add("fixed");
      } else document.getElementById("sticky").classList.add("fixed");

      document.getElementById("sticky").classList.remove("hidden");
    } else {
      document.getElementById("sticky").classList.remove("fixed");

      document.getElementById("sticky").classList.add("hidden");
    }
    if (number >= 150) {
      document.querySelector(".mobile-menu").classList.add("fixed");
    } else {
      document.querySelector(".mobile-menu").classList.remove("fixed");
    }
  };

  handleSpeclChange = (selectedSpecialOption, item, type) => {
    console.log(
      "SEARCH :: Header Param recived : ",
      selectedSpecialOption,
      item,
      type
    );
    this.setDefaultState(type, selectedSpecialOption, item);
  };

  setDefaultState = (cat, value, item) => {
    // console.log("HEADER :: setDefaultState : ", cat, value)
    if (cat === "Treatment") {
      this.setState(
        {
          // defaultTreatment: params['Treatment'].replace('-', ' ')
          defaultTreatment: value ? value : "",
          // menuVisible: true,
        },
        () => this.makeSearchURL(value, item, cat)
      );
    }
    if (cat === "Condition") {
      this.setState(
        {
          defaultCondition: value ? value : "",
          // menuVisible: true,
        },
        () => this.makeSearchURL(value, item, cat)
      );
    }
    if (cat === "Speciality") {
      this.setState(
        {
          defaultSpecialisation: value ? value : "",
          // menuVisible: true,
        },
        () => this.makeSearchURL(value, item, cat)
      );
    }
    if (cat === "Hospital") {
      console.log("REDIRECT TO HOSPITAL ", value, item, cat);
      let path = `${process.env.PUBLIC_URL}/hospital/${value}`;
      // this.props.history.push(path);
      // this.props.history.push(path);
      // eslint-disable-next-line no-restricted-globals
      location.href = path;
      // this.setState(
      //     {
      //         defaultHospital: value ? value : "",
      //         // menuVisible: true,
      //     },
      //     () => this.makeSearchURL(value, item, cat)
      // );
    }
    if (cat === "Doctor") {
      console.log("REDIRECT TO HOSPITAL ", value, item, cat);
      let path = `${process.env.PUBLIC_URL}/doctor/${value}`;
      // eslint-disable-next-line no-restricted-globals
      location.href = path;
      // this.setState(
      //     {
      //         defaultDoctor: value ? value : "",
      //         // menuVisible: true,
      //     },
      //     () => this.makeSearchURL(value, item, cat)
      // );
    }
    this.setState({
      menuVisible: true,
    });
  };

  //             defaultTreatment: '',
  //             defaultCondition: '',
  //             defaultSpecialisation: '',
  //             defaultHospital: '',
  //             defaultDoctor: '',

  makeSearchURL = (selectedSpecialOption, item, type) => {
    console.log(
      "SEARCH :: Header Param recived : ",
      selectedSpecialOption,
      item,
      type
    );
    // this.setDefaultState(type, selectedSpecialOption);
    // if (selectedSpecialOption && type) {
    let search = this.state.searchName;
    let path = `${process.env.PUBLIC_URL}/${search}/india`;
    let srcObj = {};
    let str = "";

    if (this.state.defaultTreatment)
      str = "?Treatment=" + this.state.defaultTreatment.replace(/\s+/g, "-");

    if (this.state.defaultCondition)
      str = str.length
        ? str + "&Condition=" + this.state.defaultCondition.replace(/\s+/g, "-")
        : "?Condition=" + this.state.defaultCondition.replace(/\s+/g, "-");

    if (this.state.defaultSpecialisation)
      str = str.length
        ? str +
          "&Speciality=" +
          this.state.defaultSpecialisation.replace(/\s+/g, "-")
        : "?Speciality=" +
          this.state.defaultSpecialisation.replace(/\s+/g, "-");

    if (this.state.defaultHospital)
      str = str.length
        ? str + "&Hospital=" + this.state.defaultHospital.replace(/\s+/g, "-")
        : "?Hospital=" + this.state.defaultHospital.replace(/\s+/g, "-");

    if (this.state.defaultDoctor)
      str = str.length
        ? str + "&Doctor=" + this.state.defaultDoctor.replace(/\s+/g, "-")
        : "?Doctor=" + this.state.defaultDoctor.replace(/\s+/g, "-");

    // this.setState(
    //     {selectedSpecialOption},
    //     () => console.log(`Option selected:`, this.state.selectedSpecialOption)
    // );

    this.setState({
      param: {},
    });

    // let str = selectedSpecialOption.replace(/\s+/g, '-')
    // let param = type + "=" + str;

    path = `${path}${str}`;
    // console.log("SEARCH :: URL Path ", path);
    console.log("what is this path", path);

    this.props.filterSearchString(path);

    // window.location.href = path;
    this.props.history.push(path);
    // }
  };

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    if (openmyslide) {
      openmyslide.classList.add("open-side");
    }
  }

  openSearch() {
    document.getElementById("search-overlay").style.display = "block";
  }

  closeSearch() {
    document.getElementById("search-overlay").style.display = "none";
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  render() {
    // console.log("hospitalList", this.state.hospitalList);
    // console.log("this.state.medicalVisaData", this.state.medicalVisaData);
    // console.log("this.state.airAmbulanceData", this.state.airAmbulanceData);
    const countries = appConfig.countries;

    console.log(countries);

    const countryOption = [];
    countries.forEach((element) => {
      countryOption.push(
        <option
          value={element}
          key={element}
          disabled={
            this.state.medicalVisaData.CountryofOrigin === element ||
            this.state.medicalVisaData.DestinationCountry === element
          }
        >
          {element}
        </option>
      );
    });
    const {
      selectedDoctorOption,
      suggestionsDoc,
      tabSelected,
      defaultCondition,
      defaultDoctor,
      defaultHospital,
      defaultSpecialisation,
      defaultTreatment,
    } = this.state;

    window.headerProps = this.props;
    window.headerState = this.state;

    // console.log("SEARCH :: defaultTreatment : ", this.state.defaultTreatment)
    return (
      <div>
        <header id="minor" className="minor">
          <TopBarDark
            tabSelected={tabSelected}
            handleTab={this.handleTab}
            handleSpeclChange={this.handleSpeclChange}
            defaultTreatment={defaultTreatment}
            defaultHospital={defaultHospital}
            defaultDoctor={defaultDoctor}
            defaultCondition={defaultCondition}
            defaultSpecialisation={defaultSpecialisation}
          />
          <div className="mobile-menu">
            {this.state.showForm ? (
              <div className="showForm">
                <div className="showForm__header">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={() => {
                      this.setState({
                        showForm: false,
                      });
                    }}
                  />
                  <span>{this.state.tabSelected} </span>
                </div>
                <ul
                  className="ul"
                  style={{
                    display: `${tabSelected === "treatment" ? "flex" : "none"}`,
                    flexDirection: "column",
                  }}
                >
                  <TreatmentList
                    treatment={defaultTreatment}
                    handleSpeclChange={this.handleSpeclChange}
                    condition={defaultCondition}
                    specialisation={defaultSpecialisation}
                    hospital={defaultHospital}
                    doctor={defaultDoctor}
                  />
                </ul>

                <ul
                  className="ul"
                  style={{
                    display: `${tabSelected === "hospital" ? "flex" : "none"}`,
                    flexDirection: "column",
                  }}
                >
                  <HospitalList
                    treatment={defaultTreatment}
                    handleSpeclChange={this.handleSpeclChange}
                    condition={defaultCondition}
                    specialisation={defaultSpecialisation}
                    hospital={defaultHospital}
                  />
                </ul>

                <ul
                  className="ul"
                  style={{
                    display: `${tabSelected === "doctor" ? "flex" : "none"}`,
                    flexDirection: "column",
                  }}
                >
                  <DoctorList
                    treatment={defaultTreatment}
                    handleSpeclChange={this.handleSpeclChange}
                    condition={defaultCondition}
                    specialisation={defaultSpecialisation}
                    hospital={defaultHospital}
                    doctor={defaultDoctor}
                  />
                </ul>

                <ul
                  className="ul"
                  onClick={() => {
                    this.state.medicalVisaData.DestinationCountry === ""
                      ? (document.getElementById(
                          "DestinationCountry"
                        ).style.display = "inline")
                      : (document.getElementById(
                          "DestinationCountry"
                        ).style.display = "none");

                    this.state.medicalVisaData.DestinationHospital === ""
                      ? (document.getElementById(
                          "DestinationHospital"
                        ).style.display = "inline")
                      : (document.getElementById(
                          "DestinationHospital"
                        ).style.display = "none");

                    this.state.medicalVisaData.TravelDate === ""
                      ? (document.getElementById("TravelDate").style.display =
                          "inline")
                      : (document.getElementById("TravelDate").style.display =
                          "none");

                    this.state.medicalVisaData.NoofAttendents === ""
                      ? (document.getElementById(
                          "NoofAttendents"
                        ).style.display = "inline")
                      : (document.getElementById(
                          "NoofAttendents"
                        ).style.display = "none");
                  }}
                  style={{
                    display: `${
                      tabSelected === "medicalvisa" ? "flex" : "none"
                    }`,
                    flexDirection: "column",
                  }}
                >
                  <li className="li-list">
                    <h4>
                      Country of Origin
                      <img
                        id="CountryofOrigin"
                        style={{ display: "none" }}
                        src="/assets/images/danger-icon.png"
                        alt=""
                        width="20"
                        height="20"
                      />
                    </h4>
                    {/* <Input
                                                style={{ border: "0" }}
                                                name="CountryofOrigin"
                                                onBlur={this.handleInputFirst}
                                                onChange={this.handleInput}
                                                value={this.state.medicalVisaData.CountryofOrigin}
                                                placeholder="Country of Origin" /> */}
                    <select
                      name="CountryofOrigin"
                      onBlur={this.handleInputFirst}
                      onChange={this.handleInput}
                      value={this.state.medicalVisaData.CountryofOrigin}
                      style={{
                        paddingLeft: "20px",
                        border: "none",
                        width: "100%",
                      }}
                    >
                      <option value="" key="please" disabled>
                        Please Select
                      </option>
                      {countryOption}
                    </select>
                  </li>
                  <li className="li-list">
                    <h4>
                      Destination Country
                      <img
                        id="DestinationCountry"
                        style={{ display: "none" }}
                        src="/assets/images/danger-icon.png"
                        alt=""
                        width="20"
                        height="20"
                      />
                    </h4>
                    {/* <Input
                                                style={{ border: "0" }}
                                                name="DestinationCountry"
                                                value={this.state.medicalVisaData.DestinationCountry}
                                                onChange={this.handleInput}
                                                onBlur={this.medicalVisaHandler}
                                                placeholder="Destination Country" /> */}
                    <select
                      name="DestinationCountry"
                      onBlur={this.handleInputFirst}
                      onChange={this.handleInput}
                      value={this.state.medicalVisaData.DestinationCountry}
                      style={{
                        paddingLeft: "20px",
                        border: "none",
                        width: "100%",
                      }}
                    >
                      <option value="" key="please" disabled>
                        Please Select
                      </option>
                      {countryOption}
                    </select>
                  </li>

                  <li className="li-list">
                    <h4>
                      Destination Hospital
                      <img
                        id="DestinationHospital"
                        style={{ display: "none" }}
                        src="/assets/images/danger-icon.png"
                        alt=""
                        width="20"
                        height="20"
                      />
                    </h4>
                    {/* <Input
                                                style={{ border: "0" }}
                                                name="DestinationHospital"
                                                value={this.state.medicalVisaData.DestinationHospital}
                                                onChange={this.handleInput}
                                                onBlur={this.medicalVisaHandler}
                                                placeholder="Destination Hospital" /> */}

                    <select
                      name="DestinationHospital"
                      onBlur={this.handleInputFirst}
                      onChange={this.handleInput}
                      value={this.state.medicalVisaData.DestinationHospital}
                      style={{
                        paddingLeft: "20px",
                        border: "none",
                        width: "100%",
                      }}
                    >
                      {this.state.hospitalList}
                    </select>
                  </li>
                  <li className="li-list">
                    <h4>
                      Travel Date
                      <img
                        id="TravelDate"
                        style={{ display: "none" }}
                        src="/assets/images/danger-icon.png"
                        alt=""
                        width="20"
                        height="20"
                      />
                    </h4>
                    <DatePicker
                      style={{ border: "0" }}
                      disabledDate={disabledDate}
                      name="TravelDate"
                      // value={this.state.medicalVisaData.TravelDate}
                      onBlur={this.medicalVisaHandler}
                      onChange={this.handleTravelDate}
                    />
                    {/* <Input
                                            style={{border: "0"}} 
                                            name="TravelDate"
                                            onChange={this.handleInput}
                                            onBlur={this.medicalVisaHandler}
                                            placeholder="Travel Date"/> */}
                  </li>
                  <li className="li-list">
                    <h4>
                      No. of Attendents
                      <img
                        id="NoofAttendents"
                        style={{ display: "none" }}
                        src="/assets/images/danger-icon.png"
                        alt=""
                        width="20"
                        height="20"
                      />
                    </h4>
                    <Input
                      style={{ border: "0" }}
                      name="NoofAttendents"
                      value={this.state.medicalVisaData.NoofAttendents}
                      onChange={this.handleInput}
                      onBlur={this.medicalVisaHandler}
                      placeholder="No. of Attendents"
                    />
                  </li>
                </ul>

                <ul
                  className="ul"
                  style={{
                    display: `${
                      tabSelected === "accomodation" ? "flex" : "none"
                    }`,
                    flexDirection: "column",
                  }}
                >
                  <li className="li-list">
                    <h4>City</h4>
                    <Input style={{ border: "none" }} placeholder="Condition" />
                  </li>
                  <li className="li-list">
                    <h4>Hospital</h4>
                    <Input style={{ border: "none" }} placeholder="Condition" />
                  </li>
                  <li className="li-list">
                    <h4>Espected Days to Stay</h4>
                    <Input style={{ border: "none" }} placeholder="Condition" />
                  </li>
                  <li className="li-list">
                    <h4>Travel Date</h4>
                    <Input style={{ border: "none" }} placeholder="Condition" />
                  </li>
                  <li className="li-list">
                    <h4>No. Attendents</h4>
                    <Input style={{ border: "none" }} placeholder="Condition" />
                  </li>
                </ul>
              </div>
            ) : (
              <ul
                className="ul"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                <Tab
                  handleTab={this.handleTab1}
                  icon={faUserMd}
                  name="treatment"
                  tabName="TREATMENT"
                  tabSelected={tabSelected}
                  searchName="search"
                />

                <Tab
                  handleTab={this.handleTab1}
                  icon={faHospital}
                  name="hospital"
                  tabName="HOSPITAL"
                  tabSelected={tabSelected}
                  searchName="hos"
                />

                <Tab
                  handleTab={this.handleTab1}
                  icon={faBriefcaseMedical}
                  name="doctor"
                  tabName="DOCTOR"
                  tabSelected={tabSelected}
                  searchName="doct"
                />

                <Tab
                  handleTab={this.handleTab1}
                  icon={faGlobe}
                  name="medicalvisa"
                  tabName="MEDICAL VISA"
                  tabSelected={tabSelected}
                  searchName="visa"
                />
              </ul>
            )}
          </div>
          <section className="hide-me">
            {this.state.menuVisible ? (
              <section className="header-new">
                <div className="main-menu">
                  <ul
                    className="ul"
                    style={{
                      display: "flex",
                    }}
                  >
                    <Tab
                      handleTab={this.handleTab}
                      icon={faUserMd}
                      name="treatment"
                      tabName="TREATMENT"
                      tabSelected={tabSelected}
                      searchName="search"
                    />

                    <Tab
                      handleTab={this.handleTab}
                      icon={faHospital}
                      name="hospital"
                      tabName="HOSPITAL"
                      tabSelected={tabSelected}
                      searchName="hos"
                    />

                    <Tab
                      handleTab={this.handleTab}
                      icon={faBriefcaseMedical}
                      name="doctor"
                      tabName="DOCTOR"
                      tabSelected={tabSelected}
                      searchName="doct"
                    />

                    <Tab
                      handleTab={this.handleTab}
                      icon={faHeartbeat}
                      name="prevention"
                      tabName="PREVENTION"
                      tabSelected={tabSelected}
                      searchName="prev"
                    />

                    <Tab
                      handleTab={this.handleTab}
                      icon={faGlobe}
                      name="medicalvisa"
                      tabName="MEDICAL VISA"
                      tabSelected={tabSelected}
                      searchName="visa"
                    />

                    {/* <Tab
                                            handleTab={this.handleTab}
                                            icon={faHotel}
                                            name="accomodation"
                                            tabName="ACCOMODATION"
                                            tabSelected={tabSelected}
                                            searchName="accomodation"
                    /> */}

                    <Tab
                      handleTab={this.handleTab}
                      icon={faPlane}
                      name="airambulance"
                      tabName="AIR AMBULANCE"
                      tabSelected={tabSelected}
                      searchName="ambulance"
                    />
                  </ul>
                </div>

                <div className="sub-menu">
                  <ul
                    className="ul"
                    style={{
                      display: `${
                        tabSelected === "treatment" ? "flex" : "none"
                      }`,
                    }}
                  >
                    <TreatmentList
                      treatment={defaultTreatment}
                      handleSpeclChange={this.handleSpeclChange}
                      condition={defaultCondition}
                      specialisation={defaultSpecialisation}
                      hospital={defaultHospital}
                      doctor={defaultDoctor}
                    />
                  </ul>

                  <ul
                    className="ul"
                    style={{
                      display: `${
                        tabSelected === "hospital" ? "flex" : "none"
                      }`,
                    }}
                  >
                    <HospitalList
                      treatment={defaultTreatment}
                      handleSpeclChange={this.handleSpeclChange}
                      condition={defaultCondition}
                      specialisation={defaultSpecialisation}
                      hospital={defaultHospital}
                    />
                  </ul>

                  <ul
                    className="ul"
                    style={{
                      display: `${tabSelected === "doctor" ? "flex" : "none"}`,
                    }}
                  >
                    <DoctorList
                      treatment={defaultTreatment}
                      handleSpeclChange={this.handleSpeclChange}
                      condition={defaultCondition}
                      specialisation={defaultSpecialisation}
                      hospital={defaultHospital}
                      doctor={defaultDoctor}
                    />
                  </ul>

                  <ul
                    className="ul"
                    style={{
                      display: `${
                        tabSelected === "prevention" ? "flex" : "none"
                      }`,
                    }}
                  >
                    <PreventionList
                      treatment={defaultTreatment}
                      handleSpeclChange={this.handleSpeclChange}
                      condition={defaultCondition}
                      specialisation={defaultSpecialisation}
                      hospital={defaultHospital}
                      doctor={defaultDoctor}
                    />
                  </ul>

                  <ul
                    className="ul"
                    onClick={() => {
                      this.state.medicalVisaData.DestinationCountry === ""
                        ? (document.getElementById(
                            "DestinationCountry"
                          ).style.display = "inline")
                        : (document.getElementById(
                            "DestinationCountry"
                          ).style.display = "none");

                      this.state.medicalVisaData.DestinationHospital === ""
                        ? (document.getElementById(
                            "DestinationHospital"
                          ).style.display = "inline")
                        : (document.getElementById(
                            "DestinationHospital"
                          ).style.display = "none");

                      this.state.medicalVisaData.TravelDate === ""
                        ? (document.getElementById("TravelDate").style.display =
                            "inline")
                        : (document.getElementById("TravelDate").style.display =
                            "none");

                      this.state.medicalVisaData.NoofAttendents === ""
                        ? (document.getElementById(
                            "NoofAttendents"
                          ).style.display = "inline")
                        : (document.getElementById(
                            "NoofAttendents"
                          ).style.display = "none");
                    }}
                    style={{
                      display: `${
                        tabSelected === "medicalvisa" ? "flex" : "none"
                      }`,
                    }}
                  >
                    <li className="li-list">
                      <h4>
                        Country of Origin
                        <img
                          id="CountryofOrigin"
                          style={{ display: "none" }}
                          src="/assets/images/danger-icon.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </h4>
                      {/* <Input
                                                style={{ border: "0" }}
                                                name="CountryofOrigin"
                                                onBlur={this.handleInputFirst}
                                                onChange={this.handleInput}
                                                value={this.state.medicalVisaData.CountryofOrigin}
                                                placeholder="Country of Origin" /> */}
                      <select
                        name="CountryofOrigin"
                        onBlur={this.handleInputFirst}
                        onChange={this.handleInput}
                        value={this.state.medicalVisaData.CountryofOrigin}
                        style={{
                          paddingLeft: "20px",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        <option value="" key="please" disabled>
                          Please Select
                        </option>
                        {countryOption}
                      </select>
                    </li>
                    <li className="li-list">
                      <h4>
                        Destination Country
                        <img
                          id="DestinationCountry"
                          style={{ display: "none" }}
                          src="/assets/images/danger-icon.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </h4>
                      {/* <Input
                                                style={{ border: "0" }}
                                                name="DestinationCountry"
                                                value={this.state.medicalVisaData.DestinationCountry}
                                                onChange={this.handleInput}
                                                onBlur={this.medicalVisaHandler}
                                                placeholder="Destination Country" /> */}
                      <select
                        name="DestinationCountry"
                        onBlur={this.handleInputFirst}
                        onChange={this.handleInput}
                        value={this.state.medicalVisaData.DestinationCountry}
                        style={{
                          paddingLeft: "20px",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        <option value="" key="please" disabled>
                          Please Select
                        </option>
                        {countryOption}
                      </select>
                    </li>

                    <li className="li-list">
                      <h4>
                        Destination Hospital
                        <img
                          id="DestinationHospital"
                          style={{ display: "none" }}
                          src="/assets/images/danger-icon.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </h4>
                      {/* <Input
                                                style={{ border: "0" }}
                                                name="DestinationHospital"
                                                value={this.state.medicalVisaData.DestinationHospital}
                                                onChange={this.handleInput}
                                                onBlur={this.medicalVisaHandler}
                                                placeholder="Destination Hospital" /> */}

                      <select
                        name="DestinationHospital"
                        onBlur={this.handleInputFirst}
                        onChange={this.handleInput}
                        value={this.state.medicalVisaData.DestinationHospital}
                        style={{
                          paddingLeft: "20px",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        {this.state.hospitalList}
                      </select>
                    </li>
                    <li className="li-list">
                      <h4>
                        Travel Date
                        <img
                          id="TravelDate"
                          style={{ display: "none" }}
                          src="/assets/images/danger-icon.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </h4>
                      <DatePicker
                        style={{ border: "0" }}
                        disabledDate={disabledDate}
                        name="TravelDate"
                        // value={this.state.medicalVisaData.TravelDate}
                        onBlur={this.medicalVisaHandler}
                        onChange={this.handleTravelDate}
                      />
                      {/* <Input
                                            style={{border: "0"}} 
                                            name="TravelDate"
                                            onChange={this.handleInput}
                                            onBlur={this.medicalVisaHandler}
                                            placeholder="Travel Date"/> */}
                    </li>
                    <li className="li-list">
                      <h4>
                        No. of Attendents
                        <img
                          id="NoofAttendents"
                          style={{ display: "none" }}
                          src="/assets/images/danger-icon.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </h4>
                      <Input
                        style={{ border: "0" }}
                        name="NoofAttendents"
                        value={this.state.medicalVisaData.NoofAttendents}
                        onChange={this.handleInput}
                        onBlur={this.medicalVisaHandler}
                        placeholder="No. of Attendents"
                      />
                    </li>
                  </ul>

                  <ul
                    className="ul"
                    style={{
                      display: `${
                        tabSelected === "accomodation" ? "flex" : "none"
                      }`,
                    }}
                  >
                    <li className="li-list">
                      <h4>City</h4>
                      <Input
                        style={{ border: "none" }}
                        placeholder="Condition"
                      />
                    </li>
                    <li className="li-list">
                      <h4>Hospital</h4>
                      <Input
                        style={{ border: "none" }}
                        placeholder="Condition"
                      />
                    </li>
                    <li className="li-list">
                      <h4>Espected Days to Stay</h4>
                      <Input
                        style={{ border: "none" }}
                        placeholder="Condition"
                      />
                    </li>
                    <li className="li-list">
                      <h4>Travel Date</h4>
                      <Input
                        style={{ border: "none" }}
                        placeholder="Condition"
                      />
                    </li>
                    <li className="li-list">
                      <h4>No. Attendents</h4>
                      <Input
                        style={{ border: "none" }}
                        placeholder="Condition"
                      />
                    </li>
                  </ul>

                  <ul
                    className="ul"
                    onClick={() => {
                      this.state.airAmbulanceData.City === ""
                        ? (document.getElementById("City").style.display =
                            "inline")
                        : (document.getElementById("City").style.display =
                            "none");

                      this.state.airAmbulanceData.Destination === ""
                        ? (document.getElementById(
                            "Destination"
                          ).style.display = "inline")
                        : (document.getElementById(
                            "Destination"
                          ).style.display = "none");

                      this.state.airAmbulanceData.Hospital === ""
                        ? (document.getElementById("Hospital").style.display =
                            "inline")
                        : (document.getElementById("Hospital").style.display =
                            "none");

                      this.state.airAmbulanceData.Date === ""
                        ? (document.getElementById("Date").style.display =
                            "inline")
                        : (document.getElementById("Date").style.display =
                            "none");
                    }}
                    style={{
                      display: `${
                        tabSelected === "airambulance" ? "flex" : "none"
                      }`,
                    }}
                  >
                    <li className="li-list">
                      <h4>
                        Country
                        <img
                          id="Country"
                          style={{ display: "none" }}
                          src="/assets/images/danger-icon.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </h4>
                      <Input
                        style={{ border: "0" }}
                        name="Country"
                        onBlur={this.handleAirAmbulanceInputFirst}
                        onChange={this.handleAirAmbulanceInput}
                        value={this.state.airAmbulanceData.Country}
                        placeholder="Country"
                      />
                    </li>
                    <li className="li-list">
                      <h4>
                        City
                        <img
                          id="City"
                          style={{ display: "none" }}
                          src="/assets/images/danger-icon.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </h4>
                      <Input
                        style={{ border: "0" }}
                        name="City"
                        value={this.state.airAmbulanceData.City}
                        onChange={this.handleAirAmbulanceInput}
                        onBlur={this.airAmbulanceHandler}
                        placeholder="City"
                      />
                    </li>

                    <li className="li-list">
                      <h4>
                        Destination
                        <img
                          id="Destination"
                          style={{ display: "none" }}
                          src="/assets/images/danger-icon.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </h4>
                      <Input
                        style={{ border: "0" }}
                        name="Destination"
                        value={this.state.airAmbulanceData.Destination}
                        onChange={this.handleAirAmbulanceInput}
                        onBlur={this.airAmbulanceHandler}
                        placeholder="Destination"
                      />
                    </li>
                    <li className="li-list">
                      <h4>
                        Date
                        <img
                          id="Date"
                          style={{ display: "none" }}
                          src="/assets/images/danger-icon.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </h4>
                      <DatePicker
                        style={{ border: "0" }}
                        disabledDate={disabledDate}
                        name="Date"
                        // value={this.state.medicalVisaData.TravelDate}
                        onBlur={this.airAmbulanceHandler}
                        onChange={this.handleAirAmbulanceDate}
                      />
                    </li>
                    <li className="li-list">
                      <h4>
                        Hospital
                        <img
                          id="Hospital"
                          style={{ display: "none" }}
                          src="/assets/images/danger-icon.png"
                          alt=""
                          width="20"
                          height="20"
                        />
                      </h4>
                      {/* <Input
                                                style={{ border: "0" }}
                                                name="Hospital"
                                                value={this.state.airAmbulanceData.Hospital}
                                                onChange={this.handleAirAmbulanceInput}
                                                onBlur={this.airAmbulanceHandler}
                                                placeholder="Hospital" /> */}
                      <select
                        name="Hospital"
                        onChange={this.handleAirAmbulanceInput}
                        onBlur={this.airAmbulanceHandler}
                        value={this.state.airAmbulanceData.Hospital}
                        style={{
                          paddingLeft: "20px",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        {this.state.hospitalList}
                      </select>
                    </li>
                  </ul>
                </div>
              </section>
            ) : null}
          </section>
        </header>
        <header id="sticky" className="sticky hidden hide-me">
          {this.state.menuVisible ? (
            <div className="row container-fluid">
              <div className="col-sm-12">
                <div className="main-menu">
                  <div className="collection-content" style={{ width: "100%" }}>
                    <div className="headerInside" style={{ width: "100%" }}>
                      <ul
                        className="ul row"
                        style={{
                          display: `${
                            tabSelected === "treatment" ? "flex" : "none"
                          }`,
                        }}
                      >
                        <TreatmentList
                          treatment={defaultTreatment}
                          handleSpeclChange={this.handleSpeclChange}
                          condition={defaultCondition}
                          specialisation={defaultSpecialisation}
                          hospital={defaultHospital}
                          doctor={defaultDoctor}
                        />
                      </ul>
                      <ul
                        className="ul row"
                        style={{
                          display: `${
                            tabSelected === "hospital" ? "flex" : "none"
                          }`,
                        }}
                      >
                        <HospitalList
                          treatment={defaultTreatment}
                          handleSpeclChange={this.handleSpeclChange}
                          condition={defaultCondition}
                          specialisation={defaultSpecialisation}
                          hospital={defaultHospital}
                        />
                      </ul>
                      <ul
                        className="ul row"
                        style={{
                          display: `${
                            tabSelected === "doctor" ? "flex" : "none"
                          }`,
                        }}
                      >
                        <DoctorList
                          treatment={defaultTreatment}
                          handleSpeclChange={this.handleSpeclChange}
                          condition={defaultCondition}
                          specialisation={defaultSpecialisation}
                          hospital={defaultHospital}
                          doctor={defaultDoctor}
                        />
                      </ul>
                      <ul
                        className="ul row"
                        style={{
                          display: `${
                            tabSelected === "medicalvisa" ? "flex" : "none"
                          }`,
                        }}
                      >
                        <li className="li-list">
                          <h4>Country of Origin</h4>
                          <Input
                            style={{ border: "0" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>Destination Country</h4>
                          <Input
                            style={{ border: "0" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>Destination Hospital</h4>
                          <Input
                            style={{ border: "0" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>Travel Date</h4>
                          <Input
                            style={{ border: "0" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>No. of Attendents</h4>
                          <Input
                            style={{ border: "0" }}
                            placeholder="Condition"
                          />
                        </li>
                      </ul>
                      <ul
                        className="ul row"
                        style={{
                          display: `${
                            tabSelected === "accomodation" ? "flex" : "none"
                          }`,
                        }}
                      >
                        <li className="li-list">
                          <h4>City</h4>
                          <Input
                            style={{ border: "none" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>Hospital</h4>
                          <Input
                            style={{ border: "none" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>Espected Days to Stay</h4>
                          <Input
                            style={{ border: "none" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>Travel Date</h4>
                          <Input
                            style={{ border: "none" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>No. Attendents</h4>
                          <Input
                            style={{ border: "none" }}
                            placeholder="Condition"
                          />
                        </li>
                      </ul>
                      <ul
                        className="ul row"
                        style={{
                          display: `${
                            tabSelected === "airambulance" ? "flex" : "none"
                          }`,
                        }}
                      >
                        <li className="li-list">
                          <h4>Country</h4>
                          <Input
                            style={{ border: "0" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>City</h4>
                          <Input
                            style={{ border: "0" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>Destination</h4>
                          <Input
                            style={{ border: "0" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>Hospital</h4>
                          <Input
                            style={{ border: "0" }}
                            placeholder="Condition"
                          />
                        </li>
                        <li className="li-list">
                          <h4>Date</h4>
                          <Input
                            style={{ border: "0" }}
                            placeholder="Condition"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </header>

        <div id="search-overlay" className="search-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.closeSearch}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Search a Product"
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-search" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // products: getVisibleDoctors(state.data, state.filters),
  // eslint-disable-next-line no-restricted-globals
  // products: getDoctorsForSearchParam(getParams(location.href)),
  doctors: state.doctors,
  filters: state.filters,
  symbol: state.data.symbol,

  searchString: state.filters.searchString,

  suggTreatments: state.data.treatments,
  suggConditions: state.data.conditions,
  suggSpecialisations: state.data.specialisations,
  suggHospitals: state.data.hospitals,
  suggDoctors: state.data.doctors,
});
export default connect(
  mapStateToProps,
  {
    addToCart,
    addToWishlist,
    addToCompare,
    getDoctors,
    getSuggestions,
    getSuggestionsTreatments,
    getSuggestionsConditions,
    getSuggestionsSpeciality,
    getSuggestionsHospitals,
    getSuggestionsDoctors,
    filterSearchString,
    filterClean,
  }
)(withRouter(HeaderInside));
// export default connect(null,
//     {changeCurrency}
// )(withRouter(HeaderInside));
