import React, { Component } from 'react';
import Breadcrumb from "../commonAll/breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { getCartTotal, getUser } from "../../services";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { decrementQty, incrementQty, removeFromCart } from "../../redux/actions";
import { editUser} from "../../api/apicalls";
import { logout,setCurrentUser } from "../../redux/actions/authActions";


class DoctorProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: getUser(),
            name:'',
            phone:'',
            email:'',
            doctor:""
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
         let userData=localStorage.getItem('user');
       
        this.props.setCurrentUser(JSON.parse(userData));
        console.log("doctorProfile",this.props.user);
        this.setState({name:this.props.user.user.user.name});
        this.setState({phone:this.props.user.user.user.phone});
        this.setState({email:this.props.user.user.user.email});
        if(this.state.user.user.Doctor){
            this.setState({doctor:this.state.user.user.Doctor})
        }
    }

   editUser(){
    let data={
        name:this.state.name,
        email:this.state.email,
        phone:this.state.phone
    }
    console.log("ID",this.props.user.user.user.id);
    editUser(this.props.user.user.user.id,data,this.props.user.user.jwt);
   }

  


    render() {
        const { user } = this.props;
        const{doctor} =this.state;
       
      console.log("doctore",doctor)
        console.log("doctorprofile::user",user);
        if (user.isLogedIn && user.user.user.type === "doctor") {
            console.log("DASHBOARD/USER  user Object : ", user);
        } else {
            console.error("DASHBOARD/USER  user not allowed on this location : ", user.user.user.type);
        }


        return (
            <div>
                <Breadcrumb title={'Dashboard'} />


                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    <a className="popup-btn">
                                        my account
                                    </a>
                                </div>
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                        <span className="filter-back">
                                            <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li><a
                                                href={`${process.env.PUBLIC_URL}/pages/doctordashboard`}>Dashboard</a>
                                            </li>
                                            <li ><a
                                                href={`${process.env.PUBLIC_URL}/pages/doctorbookings`}>My Bookings</a>
                                            </li>
                                            <li className="active"><a href={`${process.env.PUBLIC_URL}/pages/doctorprofile`}>Edit Profile</a>
                                            </li>
                                            <li><a href={`${process.env.PUBLIC_URL}/pages/doctorprofileedit`}>Edit Doctor Profile</a>
                                            </li>
                                            {/*<li><a href="#">My Account</a></li>*/}
                                            {/*<li><a href="#">My Wishlist</a></li>*/}
                                            {/*<li><a href="#">Address Book</a></li>*/}
                                            {/*<li><a href="#">Newsletter</a></li>*/}
                                            {/*<li><a href="#">Change Password</a></li>*/}
                                            <li className="last"><a href="#" onClick={this.props.logout}>Log Out</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                       
                                            <section className="cart-section section-b-space">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-06">
                                                            <div className="page-title">
                                                                <h2>Edit Profile</h2>
                                                                {console.log(this.state.user,"hoooooooooooooooooooooooo")}
                                                            </div>
                                                            <div className="welcome-msg">
                                                                <p style={{ textTransform: 'capitalize' }}>Hello, {user.user.user.name} !</p>

                                                            </div>
                                                            <div style={{ padding: '5px' }}><br /></div>
                                                            {/*<h3 style={{marginBottom: '45px', textAlign: 'center'}}>My Bookings </h3>*/}
                                                            <div   className="hospitalProfileEdit">
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="name">Name :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="name"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={this.state.name} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="email">Email :</label>
                                                                    <input 
                                                                    type="text" 
                                                                     id="email"
                                                                    placeholder="Enter Your Email"
                                                                    onChange={(e)=>{this.setState({email:e.target.value})}}
                                                                    value={this.state.email} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="phn">Phone No :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="phn"
                                                                    placeholder="Enter Your Phone" 
                                                                    onChange={(e)=>{this.setState({phone:e.target.value})}}
                                                                    value={this.state.phone} />
                                                                </div>
                                                                {/* yyyyyyyyyyyyyyyyoooooooooooooooooooooooo
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Department">Department :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Department"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Department} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Designation">Designation :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Designation"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Designation} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Education">Education :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Education"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Education} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Experience">Experience :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Experience"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Experience} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Address">Address :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Address"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Address} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="City">City :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="City"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.City} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Phone">Phone :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Phone"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Phone} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Email">Email :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Email"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Email} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Website">Website :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Website"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Website} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Facebook">Facebook :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Facebook"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Facebook} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Twitter">Twitter :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Twitter"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Twitter} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Linkedin">Linkedin :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Linkedin"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Linkedin} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Price">Price :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Price"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Price} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="SalePrice">SalePrice :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="SalePrice"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.SalePrice} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="Discount">Discount :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="Discount"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.Discount} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="DaysInAdvance">DaysInAdvance :</label>
                                                                    <input 
                                                                    type="text" 
                                                                    id="DaysInAdvance"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.DaysInAdvance} />
                                                                </div>
                                                                <div className="hospitalProfileEditSingle">
                                                                <label htmlFor="OnVideo">OnVideo :</label>
                                                                    <input 
                                                                    type="checkbox" 
                                                                    id="OnVideo"
                                                            
                                                                    placeholder="Enter Your Name" 
                                                                    // onChange={(e)=>{this.setState({name:e.target.value})}}
                                                                    value={doctor.OnVideo} />
                                                                </div> */}
                                                                
                                                             </div>
                                                             <div className="hospitalProfileEditSingle">
                                                                <button 
                                                                className="btn btn-solid" 
                                                                onClick={()=>{this.editUser()}}
                                                                >Edit</button>
                                                                </div>








                                                            {/*<table className="table cart-table table-responsive-md">*/}
                                                            {/*    <tfoot>*/}
                                                            {/*    <tr>*/}
                                                            {/*        <td>total price :</td>*/}
                                                            {/*        <td><h2>{symbol} {total} </h2></td>*/}
                                                            {/*    </tr>*/}
                                                            {/*    </tfoot>*/}
                                                            {/*</table>*/}
                                                        </div>
                                                    </div>

                                                </div>
                                            </section>
                                          
                                      

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

           

            </div>
        )
    }
}

const mapStateToProps = (state) => ({

    user: state.auth,
})

export default connect(
    mapStateToProps,
    {removeFromCart, incrementQty, decrementQty, logout,setCurrentUser}
)(DoctorProfile)


