import React, { Component } from 'react';
import Doctor from "./doctor-card";
import Hospital from "./hospital-card";

class SearchCheck extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            data: this.props.data
        }
    }
    componentDidMount() {
        this.setState({ type: this.props.type });
        this.setState({ data: this.props.data });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("props::data-old",prevProps.data);
        console.log("props::data-new",this.props.data);
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
         
            this.setState({ type: this.props.type });
            this.setState({ data: this.props.data });
        }

    }


    render() {
        console.log("search::state", this.state);
        return (
            <div style={{ marginBottom: '30px' }}>

                {(this.state.type.toLowerCase() === "hospital") ? <Hospital data={this.state.data} /> : ''}
                {(this.state.type.toLowerCase() === "doctor") ? <Doctor data={this.state.data} /> : ''}
            </div>
        )
    }
}

export default SearchCheck;
