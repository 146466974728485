import React, { Component } from "react";
import Breadcrumb from "../commonAll/breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { getCartTotal, getUser } from "../../services";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  decrementQty,
  incrementQty,
  removeFromCart,
} from "../../redux/actions";
import axios from "axios";
import appConfig from "../../config";
import { logout } from "../../redux/actions/authActions";
//import {FileUploader} from reactjs-file-uploader;
//import FileUploader from "../commonAll/fieUploader";
import { DatePicker } from "antd";

//import {DatePicker} from 'antd';
// import {Upload, Button} from 'antd';
// import {UploadOutlined} from '@ant-design/icons';

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: getUser(),
      reports: [],
      files: [],
      fileList: [],
      percentCompleted: 0,
      submitted: false,
      report: {
        rdate: "",
        rname: "",
      },
      reportStatus: "Finalising",
    };
    this.validator = new SimpleReactValidator();
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let user = getUser();
    if (!user) {
      window.open(`${process.env.PUBLIC_URL}/`, "_self");
    } else {
      this.getReports(user);
    }
  }

  printDateTime = (dt) => {
    let x = new Date(dt);
    let dtStr = x.toDateString();
    return dtStr;
  };

  getReports = (user) => {
    axios
      .get(appConfig.testReports + "?user=" + user.user.id, {
        headers: {
          Authorization: "Bearer " + user.jwt,
        },
      })
      .then((response) => {
        // Handle success.
        // console.log('Well done!');
        console.log("reports data", response.data);
        this.setState({ reports: response.data });
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error);
      });
  };

  onChange = (event) => {
    console.log(event.target.files);

    this.setState({
      files: event.target.files,
      fileList: Array.from(event.target.files),
      reportStatus: "Finalising",
      percentCompleted: 0,
    });
  };

  handleChange(event) {
    const { name, value } = event.target;
    console.log(name, value, event.target.value);
    const { report } = this.state;
    this.setState({
      report: {
        ...report,
        [name]: value,
      },
    });
  }

  submitReport = (e) => {
    this.setState({ submitted: true });
    if (
      this.state.report.rdate &&
      this.state.report.rname &&
      this.state.fileList
    ) {
      this.submitReportOld();
    }
  };

  submitReportOld = () => {
    //e.preventDefault();
    console.log("FILES : ", this.state.files);
    console.log("User : ", this.props.user.user);
    console.log("fileList : ", this.state.fileList);
    console.log("fileList : ", this.state.report);

    const formData = new FormData();
    Array.from(this.state.files).forEach((file) => {
      formData.append("files", file);
    });

    const config = {
      headers: {
        Authorization: "Bearer " + this.props.user.user.jwt,
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(percentCompleted);
        this.setState({ percentCompleted });
      }.bind(this),
    };

    axios
      .post(appConfig.upload, formData, config)
      .then((res) => {
        this.createReport(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  createReport(res) {
    console.log("Now create the report entry ", res);
    let user = this.props.user.user;
    let formData = {
      ReportName: this.state.report.rname,
      Date: this.state.report.rdate,
      Report: res.data[0].id,
      user: user.user.id,
    };

    axios
      .post(appConfig.testReports, formData, {
        headers: {
          Authorization: "Bearer " + user.jwt,
        },
      })
      .then((res) => {
        //this.createReport(res)
        this.setState({
          reportStatus: "Completed",
          report: {
            rdate: "",
            rname: "",
          },
          submitted: false,
        });
        this.getReports(user);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { cartItems, symbol, total, user } = this.props;
    //console.log("cart item", cartItems);
    let uplderConfig = this.uplderConfig;

    const { reports, fileList, percentCompleted } = this.state;
    // console.log("DASHBOARD/USER  user : ", user);
    // console.log("DASHBOARD/USER  props : ", this.props);
    console.log("DASHBOARD/USER  state : ", this.state);
    // console.log("state is ", this.state);

    if (user.isLogedIn && user.user.user.type === "user") {
      console.log("DASHBOARD/USER  user Object : ", user);
    } else {
      console.error(
        "DASHBOARD/USER  user not allowed on this location : ",
        user.user.user.type
      );
    }

    return (
      <div>
        <Breadcrumb title={"Dashboard"} />

        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="account-sidebar">
                  <a className="popup-btn">my account</a>
                </div>
                <div className="dashboard-left">
                  <div className="collection-mobile-back">
                    <span className="filter-back">
                      <i className="fa fa-angle-left" aria-hidden="true" /> back
                    </span>
                  </div>
                  <div className="block-content">
                    <ul>
                      <li>
                        <a href={`${process.env.PUBLIC_URL}/pages/dashboard`}>
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a href={`${process.env.PUBLIC_URL}/pages/bookings`}>
                          My Bookings
                        </a>
                      </li>
                      <li className="active">
                        <a href={`${process.env.PUBLIC_URL}/pages/reports`}>
                          My Reports
                        </a>
                      </li>
                      <li>
                        <a
                          href={`${process.env.PUBLIC_URL}/pages/prescriptions`}
                        >
                          My Prescriptions
                        </a>
                      </li>
                      <li className="last">
                        <a href="#" onClick={this.props.logout}>
                          Log Out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    {/*{reports.length > 0 ?*/}
                    <section className="cart-section section-b-space">
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="page-title">
                              <h2>My Reports</h2>
                            </div>
                            <div className="welcome-msg">
                              <p style={{ textTransform: "capitalize" }}>
                                Hello, {user.user.user.name} !
                              </p>
                              <p>
                                Access the appointment by clicking Start Session
                              </p>
                            </div>
                            <div style={{ padding: "20px" }}>
                              <br />
                            </div>
                            <div style={{ paddingBottom: "20px" }}>
                              <div className="form-row">
                                <div className="col-md-6">
                                  <label htmlFor="email">Report Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="rname"
                                    name="rname"
                                    value={this.state.report.rname}
                                    onChange={this.handleChange}
                                    placeholder="Report Name"
                                    required=""
                                  />
                                  {this.state.submitted &&
                                    !this.state.report.rname && (
                                      <div
                                        className="help-block"
                                        style={{ marginTop: "0px" }}
                                      >
                                        Report Name is required
                                      </div>
                                    )}
                                </div>
                                <div className="col-md-6">
                                  <label htmlFor="review">Report Date</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="rdate"
                                    name="rdate"
                                    value={this.state.report.rdate}
                                    onChange={this.handleChange}
                                    placeholder="Report Date"
                                    required=""
                                  />
                                  {this.state.submitted &&
                                    !this.state.report.rdate && (
                                      <div
                                        className="help-block"
                                        style={{ marginTop: "0px" }}
                                      >
                                        Report Date is required
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                padding: "20px",
                                border: "1px solid #ddd",
                              }}
                            >
                              <div className="form-row">
                                <input type="file" onChange={this.onChange} />
                                {fileList.map((fil, index) => {
                                  console.log(fil.name, index);
                                })}
                                {fileList.length ? (
                                  <div>
                                    {percentCompleted > 0 &&
                                    percentCompleted < 100 ? (
                                      <div style={{ color: "#ff9e22" }}>
                                        Progress : {percentCompleted}%
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {percentCompleted === 100 ? (
                                      <div style={{ color: "#05ad0b" }}>
                                        {this.state.reportStatus}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              {this.state.submitted && !fileList.length && (
                                <div
                                  className="help-block"
                                  style={{ marginTop: "0px" }}
                                >
                                  Report File is required
                                </div>
                              )}
                            </div>

                            <div style={{ paddingBottom: "20px" }}>
                              <div className="form-row">
                                <div
                                  className="btn btn-solid cardBtn"
                                  style={{
                                    marginLeft: "5px",
                                    marginTop: "20px",
                                  }}
                                  onClick={this.submitReport}
                                >
                                  Submit
                                </div>
                              </div>
                            </div>

                            {/*<div style={{paddingBottom: '20px'}}>*/}
                            {/*    <div className="form-row">*/}
                            {/*        <DatePicker />*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {reports.length > 0 ? (
                              <div className="table-responsive">
                                <table
                                  className="table"
                                  style={{ border: "1px solid #ddd" }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        style={{ paddingTop: "20px" }}
                                      >
                                        Report
                                      </th>
                                      <th
                                        scope="col"
                                        style={{ paddingTop: "20px" }}
                                      >
                                        Name
                                      </th>
                                      {/*<th scope="col" style={{paddingTop: '20px'}}>Type</th>*/}
                                      <th
                                        scope="col"
                                        style={{ paddingTop: "20px" }}
                                      >
                                        Date
                                      </th>
                                      <th
                                        scope="col"
                                        style={{ paddingTop: "20px" }}
                                      >
                                        Link
                                      </th>
                                      {/*<th scope="col"></th>*/}
                                    </tr>
                                  </thead>
                                  {reports.map((itm, index) => {
                                    let item = itm;
                                    //console.log("item is ", item);
                                    return (
                                      <tbody key={index}>
                                        <tr>
                                          <td>
                                            <img
                                              src={item.Report[0].url}
                                              style={{ width: "70px" }}
                                              alt=""
                                            />
                                          </td>
                                          <td>{item.ReportName}</td>
                                          <td>
                                            <span>
                                              {this.printDateTime(item.Date)}
                                            </span>
                                            <br />
                                          </td>
                                          <td>
                                            <a
                                              href={item.BookingURL}
                                              target="_blank"
                                            >
                                              View
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })}
                                </table>
                              </div>
                            ) : (
                              <section className="cart-section section-b-space">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <div>
                                        <div className="col-sm-12 empty-cart-cls text-center">
                                          <img
                                            src={`${
                                              process.env.PUBLIC_URL
                                            }/assets/images/icon-empty-cart.png`}
                                            className="img-fluid mb-4"
                                            alt=""
                                          />
                                          <h3>
                                            <strong>
                                              Your Reports List is Empty
                                            </strong>
                                          </h3>
                                          <h4>
                                            Upload Your reports to share them
                                            with your doctor.
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                            {/*<h3 style={{marginBottom: '45px', textAlign: 'center'}}>My Bookings </h3>*/}
                            {/*<table className="table cart-table table-responsive-xs"*/}
                            {/*       style={{border: '1px solid #ddd'}}>*/}
                            {/*    <thead>*/}
                            {/*    <tr className="table-head">*/}
                            {/*        <th scope="col"*/}
                            {/*            style={{paddingTop: '20px'}}>Report*/}
                            {/*        </th>*/}
                            {/*        <th scope="col" style={{paddingTop: '20px'}}>Name*/}
                            {/*        </th>*/}
                            {/*        /!*<th scope="col" style={{paddingTop: '20px'}}>Type</th>*!/*/}
                            {/*        <th scope="col" style={{paddingTop: '20px'}}>Date*/}
                            {/*        </th>*/}
                            {/*        <th scope="col" style={{paddingTop: '20px'}}>Link*/}
                            {/*        </th>*/}
                            {/*        /!*<th scope="col"></th>*!/*/}
                            {/*    </tr>*/}
                            {/*    </thead>*/}
                            {/*    {reports.map((itm, index) => {*/}
                            {/*        let item = itm;*/}
                            {/*        //console.log("item is ", item);*/}
                            {/*        return (*/}
                            {/*            <tbody key={index}>*/}
                            {/*            <tr>*/}
                            {/*                <td>*/}

                            {/*                    <img src={item.Report[0].url}*/}
                            {/*                         style={{width: '70px'}} alt=""/>*/}

                            {/*                </td>*/}
                            {/*                <td>*/}
                            {/*                    {item.ReportName}*/}
                            {/*                </td>*/}
                            {/*                <td>*/}
                            {/*                    <span>{this.printDateTime(item.Date)}</span><br/>*/}

                            {/*                </td>*/}
                            {/*                <td>*/}
                            {/*                    <a href={item.BookingURL}*/}
                            {/*                       target="_blank">*/}
                            {/*                        View*/}
                            {/*                    </a>*/}

                            {/*                </td>*/}
                            {/*            </tr>*/}
                            {/*            </tbody>)*/}
                            {/*    })}*/}
                            {/*</table>*/}
                          </div>
                        </div>
                      </div>
                    </section>
                    {/*    :*/}
                    {/*    <section className="cart-section section-b-space">*/}
                    {/*        <div className="container">*/}
                    {/*            <div className="row">*/}
                    {/*                <div className="col-sm-12">*/}
                    {/*                    <div>*/}
                    {/*                        <div className="col-sm-12 empty-cart-cls text-center">*/}
                    {/*                            <img*/}
                    {/*                                src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`}*/}
                    {/*                                className="img-fluid mb-4" alt=""/>*/}
                    {/*                            <h3>*/}
                    {/*                                <strong>Your Booking List is Empty</strong>*/}
                    {/*                            </h3>*/}
                    {/*                            <h4>Chat with us for free. To help you find the most*/}
                    {/*                                suitable*/}
                    {/*                                treatment.</h4>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </section>*/}
                    {/*}*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  total: getCartTotal(state.cartList.cart),
  user: state.auth,
});

export default connect(
  mapStateToProps,
  { removeFromCart, incrementQty, decrementQty, logout }
)(Reports);

//export default Dashboard
