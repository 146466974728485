import React, { Component } from 'react';

import { Rate, Typography } from "antd";
const { Text, Link, Title } = Typography;




class Doctordetail extends Component {

    render() {
        const { item, addToWishlistClicked } = this.props

        let RatingStars = []
        for (var i = 0; i < item.Rating; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }

        return (
            <div style={{ width:"32%",marginTop:"30px",fontSize:"17px",letterSpacing:"2px"}}>

                {/* <h3 style={{letterSpacing:'2px',color:'#888'}}><strong style={{color:'#000'}}>{item.Designation}</strong></h3>
                <h3 style={{letterSpacing:'2px',color:'#888'}}>Experience :<strong style={{color:'#000'}}>{item.Experience}</strong></h3>
                <h3 style={{letterSpacing:'2px',color:'#888'}}>Department :<strong style={{color:'#000'}}>{item.Department}</strong></h3>
                <h3 style={{letterSpacing:'2px',color:'#888'}}>Designation :<strong style={{color:'#000'}}>{item.Designation}</strong></h3>
                <h3 style={{letterSpacing:'2px',color:'#888',fontSize:"20px"}}>Specialities <strong style={{color:'#000'}}>{item.Specialization}</strong></h3>
                */}
               <Title  level={4}>{item.Designation}</Title>
               
                <Text >Experience:<Text strong>{item.Experience} Years</Text></Text><br/>
              
                <Text >Department:<Text strong>{item.Department}</Text></Text><br/>
               
                <Text >Designation:<Text strong>{item.Designation}</Text></Text><br/>
                
                <Text >Specialities:</Text><br/>
                <Text strong>{item.Specialization}</Text>
            </div>
        )
    }
}


export default Doctordetail;