import React, {Component} from 'react';
// import { Link, NavLink} from 'react-router-dom';
import {IntlActions} from 'react-redux-multilingual'
import Pace from 'react-pace-progress'

// Import custom components
import store from '../../../redux/store';
import axios from "axios";
import NavBar from "./common/navbar";
import SideBar from "./common/sidebar";
import CartContainer from "./../../../containers/CartContainer";
import TopBar from "./common/topbar";
import {changeCurrency} from '../../../redux/actions'
import {connect} from "react-redux";
import TopBarDark from "./common/topbar-dark";
import LogoImage from "./common/logo";
import {Input, Select} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserMd, faHospital, faBriefcaseMedical, faGlobe, faHotel, faPlane} from '@fortawesome/free-solid-svg-icons'
// import Select from "react-select";
import appConfig from "../../../config";
import {getSearchData} from "../../../api/apicalls";

const {Option} = Select;
const components = {
    DropdownIndicator: null,
    //NoOptionsMessage: "Searching ..."
};

class HeaderNew extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            dis: {f: 1, s: 0, t: 0, fo: 0, fi: 0, si: 0},
            selectedDoctorOption: [],
            selectedHospitalOption: [],
            selectedSpecialOption: [],
            suggestionsDoc: [],
            suggestionsHos: [],
            conditionpcl: [],
            treatmentSpcl: [],
            hospitalSpcl: [],
            doctorSpcl: [],
            // suggestionsSpcl: appConfig.docspecial,
            suggestionsSpcl: [],
            children: []
        }
    }

    /*=====================
         Pre loader
         ==========================*/
    componentDidMount() {
        // setTimeout(function() {
        //     document.querySelector(".loader-wrapper").style = "display: none";
        // }, 2000);
        this.getFinderData()
    }

    componentWillMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }


    // 'Treatment',
    // 'Hospital',
    // 'Doctor',

    getFinderData = () => {
        getSearchData('Speciality').then(suggestionsSpcl => this.setState({suggestionsSpcl}));
        getSearchData('Condition').then(conditionpcl => this.setState({conditionpcl}));
        getSearchData('Treatment').then(treatmentSpcl => this.setState({treatmentSpcl}));
        getSearchData('Hospital').then(hospitalSpcl => this.setState({hospitalSpcl}));
        getSearchData('Doctor').then(doctorSpcl => this.setState({doctorSpcl}));
    }

    handleScroll = () => {
        let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if (number >= 300) {
            if (window.innerWidth < 576) {
                document.getElementById("sticky").classList.remove('fixed');
                document.getElementById("sticky").classList.add('hidden');
            } else
                document.getElementById("sticky").classList.add('fixed');
            document.getElementById("sticky").classList.remove('hidden');
        } else {
            document.getElementById("sticky").classList.remove('fixed');
            document.getElementById("sticky").classList.add('hidden');
        }
    }

    handleSpeclChange = (selectedSpecialOption, type) => {
        // return true;
        if (selectedSpecialOption && type) {
            console.log("selectedOption ", selectedSpecialOption, type);
            this.setState(
                {selectedSpecialOption},
                () => console.log(`Option selected:`, this.state.selectedSpecialOption)
            );

            this.setState({
                param: {}
            })
            let str = selectedSpecialOption.replace(/\s+/g, '-')
            let param = type + "=" + str;
            // selectedSpecialOption.forEach((itm, indx) => {
            //     if (indx === 0) {
            //         param += type + "=" + itm;
            //     } else {
            //         param += "," + itm;
            //     }
            //
            // })
            // let path = `${process.env.PUBLIC_URL}/doctors/india?${param}`;
            let path = `${process.env.PUBLIC_URL}/treatment/india?${param}`;
            console.log("search clicked ", path);
            window.location.href = path;
            // this.props.history.push(`/doctors/india?${param}`);
        }

    };

    changeLanguage(lang) {
        store.dispatch(IntlActions.setLocale(lang))
    }

    openNav() {
        var openmyslide = document.getElementById("mySidenav");
        if (openmyslide) {
            openmyslide.classList.add('open-side')
        }
    }

    openSearch() {
        document.getElementById("search-overlay").style.display = "block";
    }

    closeSearch() {
        document.getElementById("search-overlay").style.display = "none";
    }

    load = () => {
        this.setState({isLoading: true});
        fetch().then(() => {
            // deal with data fetched
            this.setState({isLoading: false})
        })
    };

    render() {
        const {selectedDoctorOption, suggestionsDoc} = this.state;
        var disf = this.state.dis.f;
        var diss = this.state.dis.s;
        var dist = this.state.dis.t;
        var disfo = this.state.dis.fo;
        var disfi = this.state.dis.fi;
        var dissi = this.state.dis.si;
        return (
            <div>
                <header id="minor" className="minor header-new">
                    <TopBarDark/>

                    <section>
                        <div className="main-menu">
                            <ul className="ul"
                                style={{
                                    display: "flex",
                                }}>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.setState({dis: {f: 1, s: 0, t: 0, fo: 0, fi: 0, si: 0}})
                                }}>
                                    <li className="li" style={{

                                        borderBottom: (disf === 1) ? "5px solid #607da9" : "none"
                                    }}>
                                        <FontAwesomeIcon icon={faUserMd} className="font" style={{

                                            color: `${(disf === 1) ? "#607da9" : "#000"}`
                                        }}/>
                                        <p style={{color: `${(disf === 1) ? "#607da9" : "#000"}`}}>
                                            TREATMENT
                                        </p>
                                    </li>
                                </a>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.setState({dis: {f: 0, s: 1, t: 0, fo: 0, fi: 0, si: 0}})
                                }}>
                                    <li className="li" style={{

                                        borderBottom: (diss === 1) ? "5px solid #607da9" : "none"
                                    }}>
                                        <FontAwesomeIcon icon={faHospital} className="font" style={{

                                            color: `${(diss == 1) ? "#607da9" : "#000"}`
                                        }}/>
                                        <p>HOSPITAL</p>
                                    </li>
                                </a>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.setState({dis: {f: 0, s: 0, t: 1, fo: 0, fi: 0, si: 0}})
                                }}>
                                    <li className="li" style={{

                                        borderBottom: (dist === 1) ? "5px solid #607da9" : "none"
                                    }}>
                                        <FontAwesomeIcon icon={faBriefcaseMedical} className="font" style={{

                                            color: `${(dist == 1) ? "#607da9" : "#000"}`
                                        }}/>
                                        <p>DOCTOR</p></li>
                                </a>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.setState({dis: {f: 0, s: 0, t: 0, fo: 1, fi: 0, si: 0}})
                                }}>
                                    <li className="li" style={{

                                        borderBottom: (disfo === 1) ? "5px solid #607da9" : "none"
                                    }}>
                                        <FontAwesomeIcon icon={faGlobe} className="font" style={{

                                            color: `${(disfo == 1) ? "#607da9" : "#000"}`
                                        }}/>
                                        <p>MEDICAL VISA</p></li>
                                </a>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.setState({dis: {f: 0, s: 0, t: 0, fo: 0, fi: 1, si: 0}})
                                }}>
                                    <li className="li" style={{

                                        borderBottom: (disfi === 1) ? "5px solid #607da9" : "none"
                                    }}>
                                        <FontAwesomeIcon icon={faHotel} className="font" style={{

                                            color: `${(disfi == 1) ? "#607da9" : "#000"}`
                                        }}/>
                                        <p>ACCOMODATION</p></li>
                                </a>
                                <a href="javascript:void(0)" onClick={() => {
                                    this.setState({dis: {f: 0, s: 0, t: 0, fo: 0, fi: 0, si: 1}})
                                }}>
                                    <li className="li" style={{

                                        borderBottom: (dissi === 1) ? "5px solid #607da9" : "none"
                                    }}>
                                        <FontAwesomeIcon icon={faPlane} className="font" style={{

                                            color: `${(dissi == 1) ? "#607da9" : "#000"}`
                                        }}/>
                                        <p>AIR AMBULANCE</p></li>
                                </a>
                            </ul>
                        </div>
                        <div className="sub-menu">

                            <ul className="ul" style={{
                                display: `${(disf == 1) ? "flex" : "none"}`,
                            }}>
                                <li className="li-list">
                                    <h4>Treatment</h4>
                                    <Select
                                        // mode="multiple"
                                        allowClear
                                        showSearch
                                        bordered={false}
                                        showArrow={false}
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={(e) => this.handleSpeclChange(e, "Treatment")}
                                        className={'headerSearch'}
                                    >
                                        {/*{this.state.children}*/}
                                        {this.state.treatmentSpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.slug} label={item.label}>
                                                    {item.label}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </li>

                                <li className="li-list">
                                    <h4>Condition</h4>
                                    <Select
                                        // mode="multiple"
                                        allowClear
                                        showSearch
                                        bordered={false}
                                        showArrow={false}
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={(e) => this.handleSpeclChange(e, "Condition")}
                                        className={'headerSearch'}
                                    >
                                        {/*{this.state.children}*/}
                                        {this.state.conditionpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.slug} label={item.label}>
                                                    {item.label}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </li>
                                <li className="li-list">
                                    <h4>Specialisation</h4>
                                    {/*<Input style={{border: "0"}} placeholder="Condition"/>*/}
                                    <Select
                                        // mode="multiple"
                                        allowClear
                                        showSearch
                                        bordered={false}
                                        showArrow={false}
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={(e) => this.handleSpeclChange(e, "speciality")}
                                        className={'headerSearch'}
                                    >  {this.state.children}
                                        {this.state.suggestionsSpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.slug} label={item.label}>
                                                    {item.label}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                    {/*<Select*/}
                                    {/*	value={this.state.selectedSpecialOption}*/}
                                    {/*	onChange={this.handleSpeclChange}*/}
                                    {/*	options={this.state.suggestionsSpcl}*/}
                                    {/*	isMulti*/}
                                    {/*	isSearchable*/}
                                    {/*	isClearable*/}
                                    {/*	placeholder="Select Specialization"*/}
                                    {/*	style={{color: '#000', width: '100%'}}*/}
                                    {/*/>*/}
                                </li>

                                <li className="li-list">
                                    <h4>Hospital</h4>
                                    <Select
                                        // mode="multiple"
                                        allowClear
                                        showSearch
                                        bordered={false}
                                        showArrow={false}
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={(e) => this.handleSpeclChange(e, "Hospital")}
                                        className={'headerSearch'}
                                    >  {this.state.children}
                                        {this.state.hospitalSpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.label} label={item.slug}>
                                                    {item.label}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </li>
                                <li className="li-list">
                                    <h4>Doctor</h4>
                                    <Select
                                        // mode="multiple"
                                        bordered={false}
                                        showArrow={false}
                                        allowClear
                                        showSearch
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={(e) => this.handleSpeclChange(e, "Doctor")}
                                        className={'headerSearch'}
                                    >  {this.state.children}
                                        {this.state.doctorSpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.label} label={item.slug}>
                                                    {item.label}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                    {/*<Select*/}
                                    {/*	value={selectedDoctorOption}*/}
                                    {/*	onChange={this.handleDoctorChange}*/}
                                    {/*	options={suggestionsDoc}*/}
                                    {/*	// isMulti*/}
                                    {/*	isSearchable*/}
                                    {/*	isClearable*/}
                                    {/*	// components={{DropdownIndicator: null}}*/}
                                    {/*	components={components}*/}
                                    {/*	onInputChange={this.handleDocInputChange}*/}
                                    {/*	placeholder="Specialist’s name"*/}
                                    {/*	style={{color: '#000', wideth: '250px'}}*/}
                                    {/*/>*/}
                                </li>
                            </ul>

                            <ul style={{
                                display: `${(diss == 1) ? "flex" : "none"}`,
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "0",
                                marginTop: "10px",
                                listStyleType: "none",
                                flex: "1"
                            }}>
                                <li className="li-list">
                                    <h4>Condition</h4>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={this.handleSpeclChange}
                                        className={'headerSearch'}
                                    >
                                        {/*{this.state.children}*/}
                                        {this.state.conditionpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.slug} label={item.label}>
                                                    {item.label}
                                                </Option>
                                            )
                                        })}
                                    </Select>


                                </li>
                                <li className="li-list">
                                    <h4>Specialisation</h4>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={this.handleSpeclChange}
                                        className={'headerSearch'}
                                    >  {this.state.children}
                                        {this.state.suggestionsSpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.slug} label={item.label}>
                                                    {item.label}
                                                </Option>
                                            )
                                        })}
                                    </Select>


                                </li>
                                <li className="li-list">
                                    <h4>Treatment</h4>

                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={this.handleSpeclChange}
                                        className={'headerSearch'}
                                    >
                                        {/*{this.state.children}*/}
                                        {this.state.treatmentSpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.slug} label={item.label}>
                                                    {item.label}
                                                </Option>
                                            )
                                        })}
                                    </Select>

                                </li>
                                <li className="li-list">
                                    <h4>Hospital</h4>

                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={this.handleSpeclChange}
                                        className={'headerSearch'}
                                    >  {this.state.children}
                                        {this.state.hospitalSpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.label} label={item.slug}>
                                                    {item.label}
                                                </Option>
                                            )
                                        })}
                                    </Select>

                                </li>

                            </ul>

                            <ul style={{
                                display: `${(dist == 1) ? "flex" : "none"}`,
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "0",
                                marginTop: "10px",
                                listStyleType: "none",
                                flex: "1"
                            }}>
                                <li className="li-list">
                                    <h4>Condition</h4>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={this.handleSpeclChange}
                                        className={'headerSearch'}
                                    >
                                        {/*{this.state.children}*/}
                                        {this.state.conditionpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.slug} label={item.label}/>
                                            )
                                        })}
                                    </Select>
                                </li>
                                <li className="li-list">
                                    <h4>Specialisation</h4><Select
                                    mode="multiple"
                                    allowClear
                                    style={{width: '100%', border: 'none'}}
                                    placeholder="Please select"
                                    defaultValue={[]}
                                    onChange={this.handleSpeclChange}
                                    className={'headerSearch'}
                                >  {this.state.children}
                                    {this.state.suggestionsSpcl.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.slug} label={item.label}/>
                                        )
                                    })}
                                </Select>
                                </li>
                                <li className="li-list">
                                    <h4>Treatment</h4>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={this.handleSpeclChange}
                                        className={'headerSearch'}
                                    >
                                        {/*{this.state.children}*/}
                                        {this.state.treatmentSpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.slug} label={item.label}/>
                                            )
                                        })}
                                    </Select></li>

                                <li className="li-list">
                                    <h4>Doctor</h4>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={this.handleSpeclChange}
                                        className={'headerSearch'}
                                    >  {this.state.children}
                                        {this.state.doctorSpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.label} label={item.slug}/>
                                            )
                                        })}
                                    </Select>
                                </li>
                            </ul>

                            <ul style={{
                                display: `${(disfo == 1) ? "flex" : "none"}`,
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "0",
                                marginTop: "10px",
                                listStyleType: "none",
                                flex: "1"
                            }}>
                                <li className="li-list">
                                    <h4>Source Country</h4><Input style={{border: "0"}} placeholder="Condition"/></li>
                                <li style={{
                                    display: "flex",
                                    height: "100px",
                                    alignItems: "start",
                                    flex: "0 0 18%",
                                    flexDirection: "column",
                                    borderRadius: "5px",
                                    backgroundColor: "white",
                                    padding: "10px",
                                    boxShadow: "0px 3px 2px 2px rgba(0,0,0,0.15)"
                                }}><h4>City</h4><Input style={{border: "0"}} placeholder="Condition"/></li>
                                <li className="li-list">
                                    <h4>Destination Hospital</h4><Input style={{border: "0"}} placeholder="Condition"/>
                                </li>
                                <li className="li-list">
                                    <h4>Travel Date</h4><Input style={{border: "0"}} placeholder="Condition"/></li>
                                <li className="li-list">
                                    <h4>No. Attendent</h4><Input style={{border: "0"}} placeholder="Condition"/></li>
                            </ul>

                            <ul style={{
                                display: `${(disfi == 1) ? "flex" : "none"}`,
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "0",
                                marginTop: "10px",
                                listStyleType: "none",
                                flex: "1"
                            }}>
                                <li className="li-list">
                                    <h4>City</h4><Input style={{border: "0"}} placeholder="Condition"/></li>
                                <li className="li-list">
                                    <h4>Hospital</h4>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={this.handleSpeclChange}
                                        className={'headerSearch'}
                                    >  {this.state.children}
                                        {this.state.hospitalSpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.label} label={item.slug}/>
                                            )
                                        })}
                                    </Select></li>
                                <li className="li-list">
                                    <h4>Espected Days to Stay</h4><Input style={{border: "0"}} placeholder="Condition"/>
                                </li>
                                <li className="li-list">
                                    <h4>Travel Date</h4><Input style={{border: "0"}} placeholder="Condition"/></li>
                                <li className="li-list">
                                    <h4>No. Attendent</h4><Input style={{border: "0"}} placeholder="Condition"/></li>
                            </ul>

                            <ul style={{
                                display: `${(dissi == 1) ? "flex" : "none"}`,
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "0",
                                marginTop: "10px",
                                listStyleType: "none",
                                flex: "1"
                            }}>
                                <li className="li-list">
                                    <h4>Country</h4><Input style={{border: "0"}} placeholder="Condition"/></li>
                                <li className="li-list">
                                    <h4>City</h4><Input style={{border: "0"}} placeholder="Condition"/></li>
                                <li className="li-list">
                                    <h4>Destination</h4><Input style={{border: "0"}} placeholder="Condition"/></li>
                                <li className="li-list">
                                    <h4>Hospital</h4>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%', border: 'none'}}
                                        placeholder="Please select"
                                        defaultValue={[]}
                                        onChange={this.handleSpeclChange}
                                        className={'headerSearch'}
                                    >  {this.state.children}
                                        {this.state.hospitalSpcl.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.label} label={item.slug}/>
                                            )
                                        })}
                                    </Select>
                                </li>
                                <li className="li-list">
                                    <h4>Date</h4><Input style={{border: "0"}} placeholder="Condition"/></li>
                            </ul>

                        </div>
                    </section>

                </header>
                <header id="sticky" className="sticky hidden">
                    {this.state.isLoading ? <Pace color="#27ae60"/> : null}
                    <div className="mobile-fix-option"></div>
                    {/*Top Header Component*/}
                    <TopBarDark/>

                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="main-menu">
                                    <div className="menu-left category-nav-right">
                                        <div className="brand-logo">
                                            <LogoImage logo={this.props.logoName}/>
                                        </div>
                                        {/*<div className="navbar">*/}
                                        {/*	<a href="javascript:void(0)" onClick={this.openNav}>*/}
                                        {/*		<div className="bar-style"> <i className="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>*/}
                                        {/*	</a>*/}
                                        {/*	/!*SideBar Navigation Component*!/*/}
                                        {/*	/!*<SideBar/>*!/*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="menu-right pull-right">
                                        {/*Top Navigation Bar Component*/}
                                        <NavBar/>

                                        <div>
                                            <div className="icon-nav">
                                                <ul>
                                                    <li className="onhover-div mobile-search">
                                                        <div>
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/icons/search.svg`}
                                                                onClick={this.openSearch} className="img-fluid" alt=""/>
                                                            <i className="fa fa-search" onClick={this.openSearch}/>
                                                        </div>
                                                    </li>
                                                    <li className="onhover-div mobile-setting">
                                                        <div>
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/icons/settings.svg`}
                                                                className="img-fluid" alt=""/>
                                                            <i className="fa fa-cog"/>
                                                        </div>
                                                        <div className="show-div setting">
                                                            <h6>language</h6>
                                                            <ul>
                                                                <li><a href="/#"
                                                                       onClick={() => this.changeLanguage('en')}>English</a>
                                                                </li>
                                                                <li><a href="/#"
                                                                       onClick={() => this.changeLanguage('fn')}>French</a>
                                                                </li>
                                                            </ul>
                                                            <h6>currency</h6>
                                                            <ul className="list-inline">
                                                                <li><a href="/#"
                                                                       onClick={() => this.props.changeCurrency('€')}>euro</a>
                                                                </li>
                                                                <li><a href="/#"
                                                                       onClick={() => this.props.changeCurrency('₹')}>rupees</a>
                                                                </li>
                                                                <li><a href="/#"
                                                                       onClick={() => this.props.changeCurrency('£')}>pound</a>
                                                                </li>
                                                                <li><a href="/#"
                                                                       onClick={() => this.props.changeCurrency('$')}>doller</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    {/*Header Cart Component */}
                                                    <CartContainer/>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div id="search-overlay" className="search-overlay">
                    <div>
                        <span className="closebtn" onClick={this.closeSearch} title="Close Overlay">×</span>
                        <div className="overlay-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <form>
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="exampleInputPassword1"
                                                       placeholder="Search a Product"/>
                                            </div>
                                            <button type="submit" className="btn btn-primary"><i
                                                className="fa fa-search"/></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null,
    {changeCurrency}
)(HeaderNew);
