// import axios from "axios";
import _ from 'lodash';
import {getDoctors, getHospitals, getSearchParamID} from "../api/apicalls";

// Get Unique Brands from Json Data
export const getBrands = (products) => {
    var uniqueBrands = [];
    products.map((product, index) => {
        if (product.tags) {
            product.tags.map((tag) => {
                if (uniqueBrands.indexOf(tag) === -1) {
                    uniqueBrands.push(tag);
                }
            })
        }
    })
    //console.log(uniqueBrands)
    return uniqueBrands;
}


export const printDateTime = (dt) => {
    let x = new Date(dt);
    let dtStr = x.toDateString() + " , " + x.toLocaleTimeString();
    return dtStr;
}

export const getUser = () => {
    let userData = localStorage.getItem('user');
    let user = JSON.parse(userData);
    return user;
}

export const getSpecialityOld = (products) => {
    var speciality = [];
    products.map((product, index) => {
        if (product.speciality) {
            product.speciality.map((tag) => {
                if (speciality.indexOf(tag) === -1) {
                    speciality.push(tag);
                }
            })
        }
    })
    //console.log(speciality)
    return speciality;
}

export const getSpeciality = (products) => {
    // var speciality = [];
    // products.map((product, index) => {
    //     if (product.speciality) {
    //         product.speciality.map((tag) => {
    //             if (speciality.indexOf(tag) === -1) {
    //                 speciality.push(tag);
    //             }
    //         })
    //     }
    // })
    // //console.log(speciality)
    // return speciality;
    if (products.length) {
        let spcl = [];
        let s = [];
        _.map(products, (t) => {
            if (t.Special.length)
                s.push(t.Special)
        });
        let k = _.flatten(s)
        spcl = _.uniq(k)
        //console.log("docspecial ",spcl);
        return spcl;
    } else {
        let docspecial;
        docspecial = ["Bariatric", "Cardiology", "Dental", "Division of Urology", "Gastroentrology", "General Surgery", "MBBS MD", "Nephrology", "Orthopedics", "Oncology", "Neurology", "Transplant", "Gynaecology", "ENT", "Eye", "IVF", "oncology", "Gastroenterology", "Infertility", "Bariatric Surgeries", "Gastric Bypass", "Laparoscopic Sleeve Gastrectomy", "Laparoscopic Gastric Banding", "Weight Loss Surgery", "Diabetes Surgery", "DERMATOLOGY", "ADVANCED LAPAROSCOPY", "MAXILLO FACIAL SURGERY", "HORMONE CLINIC", "DENTAL CLINIC", "Laparoscopic Surgery", "Gastrornterology", "Eye Care", "Ophthalmology", "Spine", "Rehabilitation", "Adult Immunisation Clinic", "Allergy", "Anesthesiology", "Bone Marrow Transplantation", "Breast Cancer", "Cancer Chemotherapy", "Cancer Radiation Therapy", "Cancer Surgery", "Cancer Urology", "Cardiovascular and Thoracic Surgery", "Clinical Genetics", "Clinical Haematology", "Neonatology"];
        return docspecial;
    }
}

export const getCity = (products) => {
    if (products.length) {
        let spcl = [];
        let s = [];
        _.map(products, (t) => {
            if (t.City)
                s.push(t.City)
        });
        let k = _.flatten(s)
        spcl = _.uniq(k)
        //console.log("docspecial ",spcl);
        return spcl;
    } else {
        let cities = ["Delhi", "Gurgaon", "Kolkata", "Chennai", "Hyderabad", "Bangalore", "Noida", "Mumbai", "Mohali", "Ahmedabad", "Ahemdabad", "Chandigarh", "Thrissur", "Udaipur", "Indore", "Coimbatore", "Madurai", "Pune"];
        return cities;
    }
}

export const getDocspecial = (products) => {
    if (products.length) {
        let spcl = [];
        let s = [];
        _.map(products, (t) => {
            if (t.Specialization && t.Specialization.length)
                s.push(t.Specialization)
        });
        let k = _.flatten(s)
        spcl = _.uniq(k)
        //console.log("docspecial ",spcl);
        return spcl;
    } else {
        let docspecial;
        docspecial = ["Neurosurgery", "Neurosciences", "Brain Tumour Surgery", "Radio Surgery", "Gastroenterology", "Hepatology", " Paediatrics", "Interventional Cardiology", "Cardiology", "Stroke Neurologist", "Neurology", "Senior Neurologist", "Orthopaedics", "Orthopaedics and Joint Replacement", "Therapeutic Endoscopy", "Cancer", "Oncology", "Medical Oncology", "Gastrointestinal Surgery", "Surgical Oncology", "ICardiology", "Neuro", "Spine", "Neuro Physiology", "Robotic Surgery", "General  and Advanced Laparoscopic Surgery", "GI Surgery", "Radiation Oncology", "Urology", "Bone Marrow Transplant", "Endoscopy", "Cardio Thoracic", "Vascular Surgery", "Pediatric Urology", "Pediatric Surgery", "E.N.T", "Head and Neck Surgery", "Orthopedics and Joint Replacement", "Obstetrics & Gynecology", "Obstetrics & Gynaecology", "Non Invasive Cardiology", "Brain", "Joint Replacement", "Bariatric Surgery", "General & Advanced Laparoscopic Surgery", "Kidney Transplant", "Transplant", "Liver Transplant", "Paediatric Cardiothoracic Surgery", "Laparoscopy", "VATS Thoracoscopy", "Endoscopic and Robotic Surgery"];
        return docspecial;
    }
}

export const getDocspecialOld = (products) => {
    var docspecial = [];
    products.map((product, index) => {
        if (product.specialication) {
            product.specialication.map((tag) => {
                if (docspecial.indexOf(tag) === -1) {
                    docspecial.push(tag);
                }
            })
        }
    })
    //console.log(docspecial)
    return docspecial;
}

// Get Unique Colors from Json Data
export const getColors = (products) => {
    var uniqueColors = [];
    products.map((product, index) => {
        if (product.colors) {
            product.colors.map((color) => {
                if (uniqueColors.indexOf(color) === -1) {
                    uniqueColors.push(color);
                }
            })
        }
    })
    //console.log(uniqueBrands)
    return uniqueColors;
}

// Get Minimum and Maximum Prices from Json Data
export const getMinMaxPrice = (products) => {
    let min = 100, max = 1000;

    products.map((product, index) => {
        let v = product.price;
        min = (v < min) ? v : min;
        max = (v > max) ? v : max;
    })

    return {'min': min, 'max': max};
}

// Get Minimum and Maximum Prices from Json Data
export const getMinMaxRate = (products) => {
    let min = 0, max = 5;

    products.map((product, index) => {
        let v = product.rating;
        min = (v < min) ? v : min;
        max = (v > max) ? v : max;
    })

    //console.log("rating min max ", min, max);
    return {'min': min, 'max': max};
}

export const getVisibleHospitals = (data, {brand, color, speciality, value, rate, sortBy}) => {
    //console.log("hospital filter content", brand, color, speciality, value, rate, sortBy);
    return data.products.filter(product => {

        let specialityMatch;
        if (product.speciality && speciality.length !== 0)
            specialityMatch = product.speciality.some(tag => speciality.includes(tag))
        else
            specialityMatch = true;

        // let colorMatch;
        // if(color && product.colors) {
        //     colorMatch = product.colors.includes(color)
        // }else{
        //     colorMatch = true;
        // }

        let catMatch = product.category === "hospital";

        if (!rate) {
            rate = {min: 0, max: 5}
        }

        const startPriceMatch = typeof value.min !== 'number' || value.min <= product.price;
        const endPriceMatch = typeof value.max !== 'number' || product.price <= value.max;

        const startRateMatch = typeof rate.min !== 'number' || rate.min <= product.rating;
        const endRateMatch = typeof rate.max !== 'number' || product.rating <= rate.max;

        //return brandMatch && colorMatch && startRateMatch && endRateMatch;
        return specialityMatch && startRateMatch && endRateMatch && catMatch;

    }).sort((product1, product2) => {
        if (sortBy === 'HighToLow') {
            return product2.price < product1.price ? -1 : 1;
        } else if (sortBy === 'LowToHigh') {
            return product2.price > product1.price ? -1 : 1;
        } else if (sortBy === 'Newest') {
            return product2.id < product1.id ? -1 : 1;
        } else if (sortBy === 'AscOrder') {
            return product1.name.localeCompare(product2.name);
        } else if (sortBy === 'DescOrder') {
            return product2.name.localeCompare(product1.name);
        } else {
            return product2.id > product1.id ? -1 : 1;
        }
    });
}
export const getDoctorsForSearchParam = async (param) => {
    console.log("SEARCH :: getDoctorsForSearchParam : ", param);
    return await getSearchParamID(Object.keys(param)[0], param[Object.keys(param)[0]].replaceAll('-', ' ')).then(async r => {
        let searchObject = r
        console.log("SEARCH :: Parameters are : ", searchObject)
        if(searchObject[0]){
            let query = "?" + searchKeywords[searchObject[0].category]  + "=" + searchObject[0].id
            return await getDoctors(query.toLowerCase()).then(doc => {
                console.log("SEARCH :: Serch result : ", doc)
                return doc;
            })
        }
    })
}

export const getHospitalsForSearchParam = async (param) => {
    console.log("getDoctorsForSearchParam : ", param);
    return await getSearchParamID(Object.keys(param)[0], param[Object.keys(param)[0]].replaceAll('-', ' ')).then(async r => {
        let searchObject = r
        console.log("Parameters are : ", searchObject[0].id)
        let query = "?" + searchObject[0].category + "=" + searchObject[0].id
        return await getHospitals(query.toLowerCase()).then(hos => {
            console.log("Serch result : ", hos)
            return hos;
        })

    })

}


export const getVisibleDoctors = (data, {brand, color, docspecial, dprices, rate, sortBy}) => {
    //console.log("doc filter content", brand, color, docspecial, value, rate, sortBy);
    return data.products.filter(product => {

        let specialMatch;
        if (product.specialication && docspecial.length !== 0)
            specialMatch = product.specialication.some(tag => docspecial.includes(tag))
        else
            specialMatch = true;


        let catMatch = product.category === "doctor";

        if (!dprices) {
            dprices = {min: 0, max: 15000};
        }

        const startPriceMatch = typeof dprices.min !== 'number' || dprices.min <= product.price;
        const endPriceMatch = typeof dprices.max !== 'number' || product.price <= dprices.max;

        return startPriceMatch && endPriceMatch && catMatch && specialMatch;
    }).sort((product1, product2) => {
        if (sortBy === 'HighToLow') {
            return product2.price < product1.price ? -1 : 1;
        } else if (sortBy === 'LowToHigh') {
            return product2.price > product1.price ? -1 : 1;
        } else if (sortBy === 'Newest') {
            return product2.id < product1.id ? -1 : 1;
        } else if (sortBy === 'AscOrder') {
            return product1.name.localeCompare(product2.name);
        } else if (sortBy === 'DescOrder') {
            return product2.name.localeCompare(product1.name);
        } else {
            return product2.id > product1.id ? -1 : 1;
        }
    });
}

export const getVisibleproducts = (data, {brand, color, value, sortBy}) => {
    return data.products.filter(product => {

        let brandMatch;
        if (product.tags)
            brandMatch = product.tags.some(tag => brand.includes(tag))
        else
            brandMatch = true;

        let colorMatch;
        if (color && product.colors) {
            colorMatch = product.colors.includes(color)
        } else {
            colorMatch = true;
        }

        const startPriceMatch = typeof value.min !== 'number' || value.min <= product.price;
        const endPriceMatch = typeof value.max !== 'number' || product.price <= value.max;

        return brandMatch && colorMatch && startPriceMatch && endPriceMatch;
    }).sort((product1, product2) => {
        if (sortBy === 'HighToLow') {
            return product2.price < product1.price ? -1 : 1;
        } else if (sortBy === 'LowToHigh') {
            return product2.price > product1.price ? -1 : 1;
        } else if (sortBy === 'Newest') {
            return product2.id < product1.id ? -1 : 1;
        } else if (sortBy === 'AscOrder') {
            return product1.name.localeCompare(product2.name);
        } else if (sortBy === 'DescOrder') {
            return product2.name.localeCompare(product1.name);
        } else {
            return product2.id > product1.id ? -1 : 1;
        }
    });
}

export const getCartTotal = cartItems => {
    var total = 0;
    for (var i = 0; i < cartItems.length; i++) {
        total += parseInt(cartItems[i].qty, 10) * parseInt(cartItems[i].Price - (cartItems[i].Price * cartItems[i].Discount / 100), 10);
    }
    return total;
}

// Get Trending Tag wise Collection
export const getTrendingTagCollection = (products, type, tag) => {
    const items = products.filter(product => {
        return product.category === type && product.tags.includes(tag);
    })
    return items.slice(0, 8)
}

export const getTrendingSpecialicationDoctors = (products, type, tag) => {
    const items = products.filter(product => {
        return product.category === type && product.specialication.includes(tag);
    })
    return items.slice(0, 8)
}

// Get Trending Collection
export const getTrendingCollection = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })
    return items.slice(0, 8)
}

// Get Special 5 Collection
export const getSpecialCollection = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })
    return items.slice(0, 5)
}

// Get TOP Collection
export const getTopCollection = products => {
    const items = products.filter(product => {
        return product.rating > 4;
    })
    return items.slice(0, 8)
}

// Get New Products
export const getNewProducts = (products, type) => {
    const items = products.filter(product => {
        return product.new === true && product.category === type;
    })

    return items.slice(0, 8)
}

// Get Related Items
export const getRelatedItems = (products, type) => {
    const items = products.filter(product => {
        return product.category === type;
    })

    return items.slice(0, 4)

}

// Get Best Seller Furniture
export const getBestSellerProducts = (products, type) => {
    const items = products.filter(product => {
        return product.sale === true && product.category === type;
    })

    return items.slice(0, 8)
}

// Get Best Seller
export const getBestSeller = products => {
    const items = products.filter(product => {
        return product.sale === true;
    })

    return items.slice(0, 8)
}

// Get Mens Wear
export const getMensWear = products => {
    const items = products.filter(product => {
        return product.category === 'men';
    })

    return items.slice(0, 8)
}

// Get Womens Wear
export const getWomensWear = products => {
    const items = products.filter(product => {
        return product.category === 'women';
    })

    return items.slice(0, 8)
}

// Get Single Product
export const getSingleItem = (products, id) => {

    const items = products.find((element) => {
        return element.id === id;
    })
    return items;
}

// Get Feature Products
export const getFeatureImages = (products, type) => {

    const items = products.filter(product => {
        return product.type === type;
    })
    return items;
}

export const searchKeywords = {
    condition: 'conditions',
    speciality: 'Specialities',
    SuperSpeciality: 'superspecialities',
    treatment: 'treatments',
    hospital: 'hospital',
    doctor: 'doctor',
}


