import React, { Component } from "react";
import Breadcrumb from "../commonAll/breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { getCartTotal, getUser } from "../../services";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  decrementQty,
  incrementQty,
  removeFromCart,
} from "../../redux/actions";
import axios from "axios";
import appConfig from "../../config";
import { logout } from "../../redux/actions/authActions";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: getUser(),
      bookings: [],
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    let user = getUser();
    if (!user) {
      window.open(`${process.env.PUBLIC_URL}/`, "_self");
    } else {
      this.getBookings(user);
    }
  }

  printDateTime = (dt) => {
    let x = new Date(dt);
    let dtStr = x.toDateString() + " , " + x.toLocaleTimeString();
    return dtStr;
  };

  getBookings = (user) => {
    var bookings = [];
    let filter = { user: user.user.id };
    axios
      .get(appConfig.bookingOrder + "?user=" + user.user.id, {
        headers: {
          Authorization: "Bearer " + user.jwt,
        },
      })
      .then((response) => {
        // Handle success.
        console.log("Well done!");
        console.log("booking data", response.data);
        this.setState({ bookings: response.data });
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error);
      });
  };

  render() {
    const { cartItems, symbol, total, user } = this.props;
    console.log("cart item", cartItems);

    const { bookings } = this.state;
    console.log("DASHBOARD/USER  user : ", user);
    console.log("DASHBOARD/USER  props : ", this.props);
    console.log("DASHBOARD/USER  state : ", this.state);
    // console.log("state is ", this.state);

    if (user.isLogedIn && user.user.user.type === "user") {
      console.log("DASHBOARD/USER  user Object : ", user);
    } else {
      console.error(
        "DASHBOARD/USER  user not allowed on this location : ",
        user.user.user.type
      );
    }

    return (
      <div>
        <Breadcrumb title={"Dashboard"} />

        {/*Dashboard section*/}
        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="account-sidebar">
                  <a className="popup-btn">my account</a>
                </div>
                <div className="dashboard-left">
                  <div className="collection-mobile-back">
                    <span className="filter-back">
                      <i className="fa fa-angle-left" aria-hidden="true" /> back
                    </span>
                  </div>
                  <div className="block-content">
                    <ul>
                      <li className="active">
                        <a href={`${process.env.PUBLIC_URL}/pages/dashboard`}>
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a href={`${process.env.PUBLIC_URL}/pages/bookings`}>
                          My Bookings
                        </a>
                      </li>
                      <li>
                        <a href={`${process.env.PUBLIC_URL}/pages/reports`}>
                          My Reports
                        </a>
                      </li>
                      <li>
                        <a
                          href={`${process.env.PUBLIC_URL}/pages/prescriptions`}
                        >
                          My Prescriptions
                        </a>
                      </li>
                      {/*<li><a href="#">My Account</a></li>*/}
                      {/*<li><a href="#">My Wishlist</a></li>*/}
                      {/*<li><a href="#">Address Book</a></li>*/}
                      {/*<li><a href="#">Newsletter</a></li>*/}
                      {/*<li><a href="#">Change Password</a></li>*/}
                      <li className="last">
                        <a href="#" onClick={this.props.logout}>
                          Log Out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="dashboard-right">
                  <div className="dashboard">
                    <div className="page-title">
                      <h2>My Dashboard</h2>
                    </div>
                    <div className="welcome-msg">
                      <p style={{ textTransform: "capitalize" }}>
                        Hello, {user.user.user.name} !
                      </p>
                      <p>
                        From your My Account Dashboard you have the ability to
                        view a snapshot of your recent account activity and
                        update your account information. Select a link below to
                        view or edit information.
                      </p>
                    </div>
                    <div className="box-account box-info">
                      <div className="box-head">
                        <h2>Account Information</h2>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="box">
                            <div className="box-title">
                              <h3>Contact Information</h3>
                              <a href="#">Edit</a>
                            </div>
                            <div className="box-content">
                              <h6 style={{ textTransform: "capitalize" }}>
                                {user.user.name}
                              </h6>
                              <h6>{user.user.user.email}</h6>
                              <h6>{user.user.user.phone}</h6>
                              <h6>
                                <a href="#">Change Password</a>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box">
                            <div className="box-title">
                              <h3>Address Book</h3>
                              <a href="#">Manage Addresses</a>
                              {/*<a href="#">Edit</a>*/}
                            </div>
                            <div className="box-content">
                              <h6>Default Billing Address</h6>
                              <address>
                                You have not set a default billing address.
                                <br />
                                <a href="#">Edit Address</a>
                              </address>
                              {/*<p>*/}
                              {/*    You are currently not subscribed to any newsletter.*/}
                              {/*</p>*/}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*<div>*/}
                      {/*    <div className="box">*/}
                      {/*        <div className="box-title">*/}
                      {/*            <h3>Address Book</h3>*/}
                      {/*            <a href="#">Manage Addresses</a>*/}
                      {/*        </div>*/}
                      {/*        <div className="row">*/}
                      {/*            <div className="col-sm-6">*/}
                      {/*                <h6>Default Billing Address</h6>*/}
                      {/*                <address>*/}
                      {/*                    You have not set a default billing address.<br/>*/}
                      {/*                    <a href="#">Edit Address</a>*/}
                      {/*                </address>*/}
                      {/*            </div>*/}
                      {/*            <div className="col-sm-6">*/}
                      {/*                <h6>Default Shipping Address</h6>*/}
                      {/*                <address>*/}
                      {/*                    You have not set a default shipping address.<br/>*/}
                      {/*                    <a href="#">Edit Address</a>*/}
                      {/*                </address>*/}
                      {/*            </div>*/}
                      {/*        </div>*/}
                      {/*    </div>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*{bookings.length > 0 ?*/}
        {/*    <section className="cart-section section-b-space">*/}
        {/*        <div className="container">*/}
        {/*            <div className="row">*/}
        {/*                <div className="col-sm-12">*/}
        {/*                    <h3 style={{marginBottom: '45px', textAlign: 'center'}}>My Bookings </h3>*/}
        {/*                    <table className="table cart-table table-responsive-xs"*/}
        {/*                           style={{border: '1px solid #ddd'}}>*/}
        {/*                        <thead>*/}
        {/*                        <tr className="table-head">*/}
        {/*                            <th scope="col" style={{paddingTop: '20px'}}>Doctor</th>*/}
        {/*                            <th scope="col" style={{paddingTop: '20px'}}>Name</th>*/}
        {/*                            <th scope="col" style={{paddingTop: '20px'}}>Type</th>*/}
        {/*                            <th scope="col" style={{paddingTop: '20px'}}>Time</th>*/}
        {/*                            <th scope="col" style={{paddingTop: '20px'}}>Link</th>*/}
        {/*                            <th scope="col"></th>*/}
        {/*                        </tr>*/}
        {/*                        </thead>*/}
        {/*                        {bookings.map((itm, index) => {*/}
        {/*                            let item = itm.Bookings[0];*/}
        {/*                            console.log("item is ", item);*/}
        {/*                            return (*/}
        {/*                                <tbody key={index}>*/}
        {/*                                <tr>*/}
        {/*                                    <td>*/}
        {/*                                        <Link*/}
        {/*                                            to={`${process.env.PUBLIC_URL}/doctor/product/${item.DrID}`}>*/}
        {/*                                            <img src={item.DrImage} alt=""/>*/}
        {/*                                        </Link>*/}
        {/*                                    </td>*/}
        {/*                                    <td>*/}
        {/*                                        <Link*/}
        {/*                                            to={`${process.env.PUBLIC_URL}/doctor/product/${item.DrID}`}>*/}
        {/*                                            {item.DrName}*/}
        {/*                                        </Link>*/}
        {/*                                    </td>*/}
        {/*                                    <td>*/}
        {/*                                        <span>{item.BookingType}</span>*/}
        {/*                                    </td>*/}
        {/*                                    <td>*/}
        {/*                                        <span>{this.printDateTime(item.BookingTime)}</span>*/}

        {/*                                    </td>*/}
        {/*                                    <td>*/}
        {/*                                        <a href={item.BookingURL} target="_blank">*/}
        {/*                                            Start Session*/}
        {/*                                        </a>*/}
        {/*                                    </td>*/}
        {/*                                    <td></td>*/}
        {/*                                </tr>*/}
        {/*                                </tbody>)*/}
        {/*                        })}*/}
        {/*                    </table>*/}
        {/*                    /!*<table className="table cart-table table-responsive-md">*!/*/}
        {/*                    /!*    <tfoot>*!/*/}
        {/*                    /!*    <tr>*!/*/}
        {/*                    /!*        <td>total price :</td>*!/*/}
        {/*                    /!*        <td><h2>{symbol} {total} </h2></td>*!/*/}
        {/*                    /!*    </tr>*!/*/}
        {/*                    /!*    </tfoot>*!/*/}
        {/*                    /!*</table>*!/*/}
        {/*                </div>*/}
        {/*            </div>*/}

        {/*        </div>*/}
        {/*    </section>*/}
        {/*    :*/}
        {/*    <section className="cart-section section-b-space">*/}
        {/*        <div className="container">*/}
        {/*            <div className="row">*/}
        {/*                <div className="col-sm-12">*/}
        {/*                    <div>*/}
        {/*                        <div className="col-sm-12 empty-cart-cls text-center">*/}
        {/*                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`}*/}
        {/*                                 className="img-fluid mb-4" alt=""/>*/}
        {/*                            <h3>*/}
        {/*                                <strong>Your Booking List is Empty</strong>*/}
        {/*                            </h3>*/}
        {/*                            <h4>Chat with us for free. To help you find the most suitable*/}
        {/*                                treatment.</h4>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </section>*/}
        {/*}*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  total: getCartTotal(state.cartList.cart),
  user: state.auth,
});

export default connect(
  mapStateToProps,
  { removeFromCart, incrementQty, decrementQty, logout }
)(Dashboard);

//export default Dashboard
