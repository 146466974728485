import React, { Component } from 'react';
import { Input,Button } from 'antd';

class LiveChat extends Component {
    render() {
        const { symbol, item, addToCartClicked, BuynowClicked, addToWishlistClicked } = this.props

        return (
            <div style={{fontSize:"17px",letterSpacing:"2px",border: "2px solid rgb(238, 238, 238)", padding: "20px",height:"35%" }}>

           
                    <h3 style={{textAlign:"center"}}>Live Chat</h3>
                    <h4>Your Name:</h4>
                    <Input style={{marginBottom:"10px"}} placeholder="Your Name" />
                    <h4>Mobile Number:</h4>
                    <Input style={{marginBottom:"10px"}} placeholder="Mobile Number" />
                    <h4>Your Email:</h4>
                    <Input style={{marginBottom:"10px"}} placeholder="Email" />
                    <h4>Your Symptomps:</h4>
                    <Input.TextArea style={{marginBottom:"10px"}} placeholder="Symptomps" /><br></br>
                   <div  style={{textAlign:"center"}}>
                    <button className="btn btn-solid">Chat Now</button>
                   </div>
                   <hr/>
                   <div  style={{textAlign:"center"}}>
                    <button className="btn btn-solid">Get Opinion</button>
                   </div>
                   <div  style={{textAlign:"center"}}>
                    <button className="btn btn-solid">Tele Consult</button>
                   </div>
                   </div>
          
        )
    }
}

export default LiveChat;