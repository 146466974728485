import React, {Component} from 'react';
import {connect} from 'react-redux'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import {SlideToggle} from 'react-slide-toggle';
import {getHospitals, getHospital} from '../../../redux/actions/hospitalActions'
import { Checkbox} from "antd";
import {  Slider, Switch } from "antd";
import {getBrands, getColors, getMinMaxPrice, getSpeciality, getMinMaxRate, getCity} from '../../../services';
import {filterBrand, filterColor, filterPrice, filtereSpeciality, filterRate, filterHCity} from '../../../redux/actions'

class FilterHospital extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openFilter: false
        }
        //console.log("props",props);
    }

    componentDidMount() {
        let {selectedcity} = this.props
        let fcity = [];
        console.log("fiter recive ", selectedcity);

        if (selectedcity === "" || selectedcity.toUpperCase() === "INDIA") {
            fcity = [];
        } else {
            fcity = [selectedcity.toUpperCase()]
        }

        this.props.filterHCity(fcity);
        //this.updateHospitals();
        let filters = {
            ...this.props.filters,
            hcity: fcity
        }
        // this.props.getHospitals(filters);
    }

    closeFilter = () => {
        document.querySelector(".collection-filter").style = "left: -365px";
    }

    specialityHendle(event, specialities) {
        var index = specialities.indexOf(event.target.value);
        if (event.target.checked)
            specialities.push(event.target.value); // push in array checked value
        else
            specialities.splice(index, 1); // removed in array unchecked value

        this.props.filtereSpeciality(specialities);
        this.updateHospitals();
    }

    hcityHendle(event, hcity) {
        var index = hcity.indexOf(event.target.value);
        if (event.target.checked)
            hcity.push(event.target.value); // push in array checked value
        else
            hcity.splice(index, 1); // removed in array unchecked value

        this.props.filterHCity(hcity);
        this.updateHospitals();
    }

    rateHandle(value) {
        this.props.handleRate(value);

    }

    updateHospitals() {
        this.props.getHospitals(this.props.filters);
    }

    render() {
        //const filteredBrands = this.props.filters.brand;
        const filteredSpecialities = this.props.filters.speciality ? this.props.filters.speciality : [];
        const filterCities = this.props.values.hCheckedCity ? this.props.values.hCheckedCity : [];
        const{hrate,hcity} =this.props.values;
        const cities=hcity;
        console.log("FilterHospital.props.values",this.props.values);
        // console.log(this.props.brands);
        // console.log(this.props.filters);


        return (
            <div className="collection-filter-block">
                {/*brand filter start*/}
                <div className="collection-mobile-back">
                        <span className="filter-back" onClick={(e) => this.closeFilter(e)}>
                            <i className="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                </div>

                <SlideToggle>
                    {({ onToggle, setCollapsibleElement }) => (
                        <div className="collection-collapse-block">
                            <h3 className="collapse-block-title" onClick={onToggle}>Cities</h3>
                            <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                <div className="collection-brand-filter">
                                    {cities.map((city, index) => {
                                        return (
                                            <div className="custom-control custom-checkbox collection-filter-checkbox"
                                                key={index}>
                                                    <Checkbox
                                                    
                                                        value= {city}
                                                        onChange={(e)=>{
                                                            let index = filterCities.indexOf(e.target.value);
                                                            if (e.target.checked)
                                                            filterCities.push(e.target.value); // push in array checked value
                                                            else
                                                            filterCities.splice(index, 1); // removed in array unchecked value
                                                            console.log(filterCities)
                                                            this.props.handleCheckedCity(filterCities);
                                                             console.log(`checked = ${e.target.checked}`);
                                                        }}> {city}</Checkbox>
                                                {/* <input type="checkbox"
                                                    onClick={(e) => this.cityHendle(e, filterCities)}
                                                    value={city}
                                                    defaultChecked={true}
                                                    className="custom-control-input" id={city} />
                                                <label className="custom-control-label"
                                                    htmlFor={city}>{city}</label> */}
                                            </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </SlideToggle>

                {/*<SlideToggle>*/}
                {/*    {({onToggle, setCollapsibleElement}) => (*/}
                {/*        <div className="collection-collapse-block">*/}
                {/*            <h3 className="collapse-block-title" onClick={onToggle}>Speciality</h3>*/}
                {/*            <div className="collection-collapse-block-content" ref={setCollapsibleElement}>*/}
                {/*                <div className="collection-brand-filter">*/}
                {/*                    {this.props.specialities.map((speciality, index) => {*/}
                {/*                        return (*/}
                {/*                            <div className="custom-control custom-checkbox collection-filter-checkbox"*/}
                {/*                                 key={index}>*/}
                {/*                                <input type="checkbox"*/}
                {/*                                       onClick={(e) => this.specialityHendle(e, filteredSpecialities)}*/}
                {/*                                       value={speciality}*/}
                {/*                                       defaultChecked={filteredSpecialities.includes(speciality) ? true : false}*/}
                {/*                                       className="custom-control-input" id={speciality}/>*/}
                {/*                                <label className="custom-control-label"*/}
                {/*                                       htmlFor={speciality}>{speciality}</label>*/}
                {/*                            </div>)*/}
                {/*                    })}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</SlideToggle>*/}




                <SlideToggle>
                    {({onToggle, setCollapsibleElement}) => (
                        <div className="collection-collapse-block open">
                            <h3 className="collapse-block-title" onClick={onToggle}>Rating</h3>
                            <div className="collection-collapse-block-content block-price-content"
                                 ref={setCollapsibleElement}>
                                <div className="collection-brand-filter">
                                    <div className="custom-control custom-checkbox collection-filter-checkbox">
                                    <Slider
                                        range
                                        min={this.props.rate.min}
                                        max={this.props.rate.max}
                                        defaultValue={[this.props.rate.min, this.props.rate.max]}
                                        onAfterChange={(value)=>this.rateHandle(value)}
                                        />
                                        {/* <InputRange
                                            maxValue={this.props.rate.max}
                                            minValue={this.props.rate.min}
                                            value={hrate}
                                            onChange={value => this.rateHandle(value)}/> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </SlideToggle>
                {/*<SlideToggle>*/}
                {/*    {({onToggle, setCollapsibleElement}) => (*/}
                {/*        <div className="collection-collapse-block">*/}
                {/*            <h3 className="collapse-block-title" onClick={onToggle}>brand</h3>*/}
                {/*            <div className="collection-collapse-block-content" ref={setCollapsibleElement}>*/}
                {/*                <div className="collection-brand-filter">*/}
                {/*                    {this.props.brands.map((brand, index) => {*/}
                {/*                        return (*/}
                {/*                            <div className="custom-control custom-checkbox collection-filter-checkbox"*/}
                {/*                                 key={index}>*/}
                {/*                                <input type="checkbox"*/}
                {/*                                       onClick={(e) => this.clickBrandHendle(e, filteredBrands)}*/}
                {/*                                       value={brand}*/}
                {/*                                       defaultChecked={filteredBrands.includes(brand) ? true : false}*/}
                {/*                                       className="custom-control-input" id={brand}/>*/}
                {/*                                <label className="custom-control-label"*/}
                {/*                                       htmlFor={brand}>{brand}</label>*/}
                {/*                            </div>)*/}
                {/*                    })}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</SlideToggle>*/}

                {/*color filter start here*/}
                {/*<SlideToggle>*/}
                {/*    {({onToggle, setCollapsibleElement}) => (*/}
                {/*        <div className="collection-collapse-block">*/}
                {/*            <h3 className="collapse-block-title" onClick={onToggle}>colors</h3>*/}
                {/*            <div className="collection-collapse-block-content" ref={setCollapsibleElement}>*/}
                {/*                <div className="color-selector">*/}
                {/*                    <ul>*/}
                {/*                        {this.props.colors.map((color, index) => {*/}
                {/*                            return (*/}
                {/*                                <li className={color} title={color}*/}
                {/*                                    onClick={(e) => this.colorHandle(e, color)} key={index}></li>)*/}
                {/*                        })}*/}
                {/*                    </ul>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</SlideToggle>*/}
                {/*price filter start here */}

            </div>
        )
    }
}


const mapStateToProps = state => ({
    //brands: getBrands(state.data.products),
    specialities: getSpeciality(state.hospital.hospitals),
    cities: getCity(state.hospital.hospitals),
    //colors: getColors(state.data.products),
    //prices: getMinMaxPrice(state.data.products),
    rate: getMinMaxRate(state.data.products),
    filters: state.filters
})

export default connect(
    mapStateToProps,
    {filtereSpeciality, filterRate, getHospitals, filterHCity}
)(FilterHospital);
