import React, { Component } from 'react';
import { Link as ReactLink } from 'react-router-dom';

function list(element) {
    console.log("nepk", element);
    return (
        <li class="ant-list-item">
            {element.name}
            <ReactLink className="inquire_btn"
                to={element.url}>
                INQUIRE
            </ReactLink>

        </li>
    )
}

class HospitalProcedure extends Component {
    constructor(props) {
        super();
        this.state = {
            item: ''
        };
    }
    componentDidMount() {
        let item = this.props.data;
        this.setState({ item });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            let item = this.props.data;
            this.setState({ item });
        }
    }
    render() {
        const item = this.state.item;
        console.log("hospitalProcedure::item ", item);
        let list=[];
        if (item) {
            item.Data.forEach(value => {
              
                list.push(       
                <li class="ant-list-item">
                    {value.name} 
                        <ReactLink className="inquire_btn"
                            to={value.url}>
                            INQUIRE
                        </ReactLink>
                </li>
                )
            });
        }
        return (
            <div>
                {(item) ?
                    <div>
                        <div class="ant-list ant-list-lg ant-list-split ant-list-bordered">
                            <div class="ant-list-header">
                                <div style={{ color: "red" }}>{item.Name}</div>
                            </div>
                            <div class="ant-spin-nested-loading">
                                <div class="ant-spin-container">
                                    <ul class="ant-list-items">
                                        {list}


                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        )
    }

}

export default HospitalProcedure;