import React, {Component} from 'react';

// import {printDateTime} from

class orderSuccess extends Component {

    constructor(props) {
        super(props)
    }

    printDateTime = (dt) => {
        let x = new Date(dt);
        let dtStr = x.toDateString() + " , " + x.toLocaleTimeString();
        return dtStr;
    }

    render() {

        const {payment, items, symbol, orderTotal} = this.props.location.state;
        var options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        var current = new Date();
        var next5days = new Date(items[0].bTime);
        let CheckDate = current.toLocaleDateString("en-US", options).toString()
        //let deliveryDate = next5days.toLocaleDateString("en-US", options).toString()
        let deliveryDate = next5days.toString()//  .toLocaleDateString("en-US", options).toString()

        console.log("Success page ", items, payment, orderTotal, symbol);

        return (
            (payment) ?
                <div>
                    <section className="section-b-space light-layout">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="success-text">
                                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                                        <h2>thank you</h2>
                                        <p>Payment Is Has Been Received, Appointment Booked, Confirmation Email/SMS
                                            sent.</p>
                                        <p>For Any support please call +91-9899187772</p>
                                        <p>or email help@hosplan.com or click on live chat below</p>
                                        <br/>

                                        <p>Transaction ID: {(payment.paymentID) ? payment.paymentID : payment.id}</p>
                                        <p>Date of Appointment</p>
                                        <p>{this.printDateTime(items[0].bTime)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="product-order">
                                        <h3>Your Booking Details</h3>
                                        {items.map((item, index) => {
                                            return <div className="row product-order-detail" key={index}>
                                                <div className="col-3">
                                                    <img src={item.variants ?
                                                        item.variants[0].images
                                                        : item.Pictures[0].url} alt="" className="img-fluid"/>
                                                </div>
                                                <div className="col-3 order_detail">
                                                    <div>
                                                        <h4>Doctor name</h4>
                                                        <h5>{item.Name}</h5>
                                                    </div>
                                                </div>
                                                <div className="col-3 order_detail">
                                                    <div>
                                                        <h4>Type</h4>
                                                        <h5>{item.bType}</h5>
                                                        {/*<br/>*/}
                                                        {/*<span>{Date(item.bTime).toLocaleString()}</span></h5>*/}
                                                    </div>
                                                </div>
                                                <div className="col-3 order_detail">
                                                    {/*<div>*/}
                                                    {/*    <h4>Time</h4>*/}
                                                    {/*    <h5>{Date(item.bTime).toLocaleString()}</h5>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        })}
                                        <div className="total-sec">
                                            <ul>
                                                <li>subtotal <span>{symbol}{orderTotal}</span></li>
                                                <li>shipping <span>$0</span></li>
                                                <li>tax(GST) <span>$0</span></li>
                                            </ul>
                                        </div>
                                        <div className="final-total">
                                            <h3>total <span>{symbol}{orderTotal}</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row order-success-sec">
                                        <div className="col-sm-6">
                                            <h4>Summary</h4>
                                            <ul className="order-detail">
                                                {(payment.paymentID) ?
                                                    <div>
                                                        <li>payer ID: {payment.payerID}</li>
                                                        <li>payment ID: {payment.paymentID}</li>
                                                        <li>payment Token: {payment.paymentToken}</li>
                                                    </div>
                                                    :
                                                    <li>Order ID: {payment.id}</li>}

                                                <li>Order Date: {CheckDate}</li>
                                                <li>Order Total: {symbol}{orderTotal}</li>
                                            </ul>
                                        </div>
                                        {/*<div className="col-sm-6">*/}
                                        {/*    <h4>shipping address</h4>*/}
                                        {/*    <ul className="order-detail">*/}
                                        {/*        <li>gerg harvell</li>*/}
                                        {/*        <li>568, suite ave.</li>*/}
                                        {/*        <li>Austrlia, 235153</li>*/}
                                        {/*        <li>Contact No. 987456321</li>*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}

                                        {/*<div className="col-sm-12 payment-mode">*/}
                                        {/*    <h4>payment method</h4>*/}
                                        {/*    <p>Pay on Delivery (Cash/Card). Cash on delivery (COD) available. Card/Net*/}
                                        {/*        banking acceptance subject to device availability.</p>*/}
                                        {/*</div>*/}
                                        <div className="col-md-12">
                                            <div className="delivery-sec">
                                                <h3>Date of Appointment</h3>
                                                <h2>{this.printDateTime(items[0].bTime)}</h2>
                                                {/*<h2>{deliveryDate} </h2>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
                :
                <section className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="error-section">
                                    <h1>404</h1>
                                    <h2>page not found</h2>
                                    <a href="index.html" className="btn btn-solid">back to home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        )
    }
}

export default orderSuccess
