import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const headerTab = ({
  handleTab,
  icon,
  name,
  tabName,
  tabSelected,
  searchName,
}) => {
  const handleClick = () => {
    handleTab(name, searchName);
  };
  return (
    <div onClick={handleClick}>
      <li
        className="li"
        style={{
          borderBottom: tabSelected === name ? "5px solid #607da9" : "none",
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          className="font"
          style={{
            color: `${tabSelected === name ? "#607da9" : "#000"}`,
          }}
        />
        <p style={{ color: `${tabSelected === name ? "#607da9" : "#000"}` }}>
          {tabName}
        </p>
      </li>
    </div>
  );
};

export default headerTab;
