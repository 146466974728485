import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import {Rate} from "antd";

class SideImageItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            image: ''
        }
    }

    onClickHandle(img) {
        this.setState({image: img});
    }

    render() {
        const {product, symbol} = this.props;

        let RatingStars = []
        for (var i = 0; i < product.Rating; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }
        return (
            <div className="product-box2">
                <div className="media">
                    <Link to={`${process.env.PUBLIC_URL}/doctor/${product.slug}`}><img
                        src={`${
                            product.variants ?
                                this.state.image ? this.state.image : product.variants[0].images
                                : product.Pictures[0].url
                        }`}
                        className="img-fluid lazyload bg-img"
                        alt=""/></Link>
                    <div className="media-body" style={{paddingTop: '10px'}}>
                        <div>
                            <Link to={`${process.env.PUBLIC_URL}/doctor/${product.slug}`}>
                                <h6>{product.Name}</h6>
                            </Link>

                            <h6 style={{fontSize: 'x-small', marginBottom: '0px'}}>{product.Education}</h6>
                            <h6 style={{fontSize: 'x-small'}}>Experience: {product.Experience}</h6>
                            {/*<i className="fa fa-shopping-cart"></i>*/}
                            {/*<i className="fa video"></i>*/}

                            {/*<i className="fas fa-user"></i>*/}
                            {/*<i className="far fa-user"></i>*/}
                            {/*<i className="fal fa-user"></i>*/}


                            <div style={{marginTop: '30px'}}>
                                {product.OnVideo ? <img src="/assets/images/icons/videocam.svg" alt="React Logo"
                                                        style={{width: '30px', height: '30px', padding: '5px'}}/> :
                                    <img src="/assets/images/icons/videocam_off.svg" alt="React Logo"
                                         style={{width: '30px', height: '30px', padding: '5px'}}/>}
                                {product.OnPhone ? <img src="/assets/images/icons/phone.svg" alt="React Logo"
                                                        style={{width: '30px', height: '30px', padding: '5px'}}/> :
                                    <img src="/assets/images/icons/phone_disabled.svg" alt="React Logo"
                                         style={{width: '30px', height: '30px', padding: '5px'}}/>}
                                {product.OnLocation ? <img src="/assets/images/icons/location_on.svg" alt="React Logo"
                                                           style={{width: '30px', height: '30px', padding: '5px'}}/> :
                                    <img src="/assets/images/icons/location_off.svg" alt="React Logo"
                                         style={{width: '30px', height: '30px', padding: '5px'}}/>}

                            </div>
                            {/*<img src="/assets/images/icons/videocam.svg" alt="React Logo" style={{width:'50px', height:'50px'}}/>*/}
                            {/*<img src="/assets/images/icons/phone.svg" alt="React Logo" style={{width:'50px', height:'50px'}}/>*/}
                            {/*<img src="/assets/images/icons/location_on.svg" alt="React Logo" style={{width:'50px', height:'50px'}}/>*/}

                            {/*<div className="rating">*/}
                            {/*    {RatingStars}*/}
                            {/*</div>*/}

                            <Rate disabled value={product.Rating} style={{ fontSize: 14, color: '#ffa201' }}/>

                            {product.Price ? <h4>{symbol}{product.Price - (product.Price * product.Discount / 100)}
                                <del><span className="money">{symbol}{product.Price}</span></del>
                            </h4> : ""}

                            {/*{product.variants?*/}
                            {/*    <ul className="color-variant">*/}
                            {/*        {product.variants.map((vari, i) => {*/}
                            {/*            return (*/}
                            {/*                <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}/>)*/}
                            {/*        })}*/}
                            {/*    </ul>:''}*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SideImageItem;