// Get Products
export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";
export const FETCH_SUGGESTIONS = "FETCH_SUGGESTIONS";
export const RECEIVE_SUGGESTIONS = "RECEIVE_SUGGESTIONS";

export const RECEIVE_SUGGESTIONS_TREATMENTS = "RECEIVE_SUGGESTIONS_TREATMENTS";
export const RECEIVE_SUGGESTIONS_CONDITIONS = "RECEIVE_SUGGESTIONS_CONDITIONS";
export const RECEIVE_SUGGESTIONS_SPECIALISATIONS =
  "RECEIVE_SUGGESTIONS_SPECIALISATIONS";
export const RECEIVE_SUGGESTIONS_HOSPITALS = "RECEIVE_SUGGESTIONS_HOSPITALS";
export const RECEIVE_SUGGESTIONS_DOCTORS = "RECEIVE_SUGGESTIONS_DOCTORS";

//    treatments: [],
//     conditions: [],
//     specialisations: [],
//     hospitals: [],
//     doctors: [],

// FILTERS Hospital
export const FILTER_SPECIALITY = "FILTER_SPECIALITY";
export const FILTER_HOSPITAL_CITY = "FILTER_HOSPITAL_CITY";
export const FILTER_RATE = "FILTER_RATE";
export const FILTER_ALL_HOSPITAL_CITIES = "FILTER_ALL_HOSPITAL_CITIES";

// FILTERS Doctors
export const FILTER_SEARCH_STRING = "FILTER_SEARCH_STRING";

export const FILTER_DOCSPECIAL = "FILTER_DOCSPECIAL";
export const FILTER_DOCTOR_CITY = "FILTER_DOCTOR_CITY";
export const FILTER_DOCTOR_RATE = "FILTER_DOCTOR_RATE";
export const FILTER_PRICE = "FILTER_PRICE";
export const FILTER_EXPERIENCE = "FILTER_EXPERIENCE";
export const FILTER_GENDER = "FILTER_GENDER";
export const FILTER_AVAILABLE_FOR = "FILTER_AVAILABLE_FOR";
export const FILTER_ALL_CITIES = "FILTER_ALL_CITIES";
export const FILTER_CITY = "FILTER_CITY";
export const FILTER_CLEAN = "FILTER_CLEAN";

// Sort
export const SORT_BY = "SORT_BY";

// Extra
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

// FILTERS Old
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

// User Authentication
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const USER_REGISTER = "USER_REGISTER";
export const GET_USER = "GET_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT = "LOGOUT";

// Hospitals
export const FETCH_HOSPITALS_BEGIN = "FETCH_HOSPITALS_BEGIN";
export const RECEIVE_HOSPITALS = "RECEIVE_HOSPITALS";
export const FETCH_SINGLE_HOSPITALS = "FETCH_SINGLE_HOSPITALS";

// Hospitals
export const FETCH_DOCTORS_BEGIN = "FETCH_DOCTORS_BEGIN";
export const RECEIVE_DOCTORS = "RECEIVE_DOCTORS";
export const FETCH_SINGLE_DOCTOR = "FETCH_SINGLE_DOCTOR";

// Helpers
export const SWITCH_PRESCRIPTION_ON = "SWITCH_PRESCRIPTION_ON";
export const SWITCH_PRESCRIPTION_OFF = "SWITCH_PRESCRIPTION_OFF";

// TREATMENT CONDITION SPECIALISATION HOSPITAL DOCTOR
export const WORKING_MODE_CHANGED = "WORKING_MODE_CHANGED";
export const SEARCH_TREATMENT = "SEARCH_TREATMENT";
export const SEARCH_CONDITION = "SEARCH_CONDITION";
export const SEARCH_SPECIALISATION = "SEARCH_SPECIALISATION";
export const SEARCH_HOSPITAL = "SEARCH_HOSPITAL";
export const SEARCH_DOCTOR = "SEARCH_DOCTOR";

// export const SWITCH_PRESCRIPTION_OFF = 'SWITCH_PRESCRIPTION_OFF'
