import React, {Component} from 'react';
// import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

import { getSpecialCollection} from '../../../services/index'
import {
    addToCart,
    addToWishlist,
    addToCompare,
    incrementQty,
    decrementQty,
    removeFromCart
} from "../../../redux/actions/index";
import ProductItem from './special-product-item';

class Special extends Component {

    render() {

        const {symbol, addToCart, addToWishlist, addToCompare, incrementQty, decrementQty, removeFromCart} = this.props;

        let product = this.props.featured.doctors;
        // console.log(product);

        if (product.length) {

            return (
                <div>
                    {/*Paragraph*/}
                    <section className="section-b-space addtocart_count">
                        <div className="full-box">
                            <div className="container">
                                <div className="title4">
                                    <h2 className="title-inner4" style={{color: '#000'}}>Featured Doctors</h2>
                                    <div className="line"><span></span></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="theme-card center-align">
                                            <div className="offer-slider">
                                                <div className="sec-1">
                                                    <div className="product-box2">
                                                        <div className="media">
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}/doctor/${product[0].slug}`}>
                                                                <img
                                                                    className="img-fluid blur-up lazyload"
                                                                    src={product[0].Pictures[0].url} alt=""/>
                                                            </Link>
                                                            <div className="media-body align-self-center">
                                                                <Link
                                                                    to={`${process.env.PUBLIC_URL}/doctor/${product[0].slug}`}>
                                                                    <h6>{product[0].Name}</h6>
                                                                </Link>

                                                                <h6 style={{
                                                                    fontSize: 'x-small',
                                                                    marginBottom: '0px',
                                                                    lineHeight: '12px'
                                                                }}>{product[0].Education}</h6>
                                                                <h6 style={{
                                                                    fontSize: 'x-small',
                                                                    lineHeight: '12px'
                                                                }}>Exp: {product[0].Experience}</h6>

                                                                <div className="rating"><i className="fa fa-star"></i>
                                                                    <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i></div>
                                                                {/*<Link to={`${process.env.PUBLIC_URL}/doctor/product/${product[0].id}`} >*/}
                                                                {/*    <h6>{product[0].name}</h6>*/}
                                                                {/*</Link>*/}
                                                                {product[0].Price ?
                                                                    <h4>{symbol}{product[0].Price - (product[0].Price * product[0].Discount / 100)}
                                                                        <del><span
                                                                            className="money">{symbol}{product[0].Price}</span>
                                                                        </del>
                                                                    </h4> : ""}
                                                                {/*<h4>{symbol}{product[0].price - (product[0].price * product[0].discount / 100)}*/}
                                                                {/*    <del><span*/}
                                                                {/*        className="money">{symbol}{product[0].price}</span>*/}
                                                                {/*    </del>*/}
                                                                {/*</h4>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-box2">
                                                        <div className="media">
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}/doctor/${product[1].slug}`}>
                                                                <img
                                                                    className="img-fluid blur-up lazyload"
                                                                    src={product[1].Pictures[0].url} alt=""/>
                                                            </Link>
                                                            <div className="media-body align-self-center">
                                                                <Link
                                                                    to={`${process.env.PUBLIC_URL}/doctor/${product[1].slug}`}>
                                                                    <h6>{product[1].Name}</h6>
                                                                </Link>

                                                                <h6 style={{
                                                                    fontSize: 'x-small',
                                                                    marginBottom: '0px',
                                                                    lineHeight: '12px'
                                                                }}>{product[1].Education}</h6>
                                                                <h6 style={{
                                                                    fontSize: 'x-small',
                                                                    lineHeight: '12px'
                                                                }}>Exp: {product[1].Experience}</h6>

                                                                <div className="rating"><i className="fa fa-star"></i>
                                                                    <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i></div>
                                                                {/*<Link to={`${process.env.PUBLIC_URL}/doctor/product/${product[1].id}`} >*/}
                                                                {/*    <h6>{product[1].name}</h6>*/}
                                                                {/*</Link>*/}
                                                                {product[1].Price ?
                                                                    <h4>{symbol}{product[1].Price - (product[1].Price * product[1].Discount / 100)}
                                                                        <del><span
                                                                            className="money">{symbol}{product[1].Price}</span>
                                                                        </del>
                                                                    </h4> : ""}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 center-slider">
                                        <div>
                                            <div className="offer-slider">
                                                <div>
                                                    <ProductItem product={product[2]} symbol={symbol}
                                                                 onAddToCompareClicked={() => addToCompare(product[2])}
                                                                 onAddToWishlistClicked={() => addToWishlist(product[2])}
                                                                 onAddToCartClicked={() => addToCart(product[2], 1)}
                                                                 onIncrementClicked={() => incrementQty(product[2], 1)}
                                                                 onDecrementClicked={() => decrementQty(product[2].id)}
                                                                 onRemoveFromCart={() => removeFromCart(product[2])}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="theme-card center-align">
                                            <div className="offer-slider">
                                                <div className="sec-1">
                                                    <div className="product-box2">
                                                        <div className="media">
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}/doctor/${product[3].slug}`}>
                                                                <img
                                                                    className="img-fluid blur-up lazyload"
                                                                    src={product[3].Pictures[0].url} alt=""/>
                                                            </Link>
                                                            <div className="media-body align-self-center">
                                                                <Link
                                                                    to={`${process.env.PUBLIC_URL}/doctor/${product[3].slug}`}>
                                                                    <h6>{product[3].Name}</h6>
                                                                </Link>

                                                                <h6 style={{
                                                                    fontSize: 'x-small',
                                                                    marginBottom: '0px',
                                                                    lineHeight: '12px'
                                                                }}>{product[3].Education}</h6>
                                                                <h6 style={{
                                                                    fontSize: 'x-small',
                                                                    lineHeight: '12px'
                                                                }}>Exp: {product[3].Experience}</h6>

                                                                <div className="rating"><i className="fa fa-star"></i>
                                                                    <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i></div>
                                                                {/*<Link to={`${process.env.PUBLIC_URL}/doctor/product/${product[3].id}`} >*/}
                                                                {/*    <h6>{product[3].name}</h6>*/}
                                                                {/*</Link>*/}
                                                                {product[3].Price ?
                                                                    <h4>{symbol}{product[3].Price - (product[3].Price * product[3].Discount / 100)}
                                                                        <del><span
                                                                            className="money">{symbol}{product[3].Price}</span>
                                                                        </del>
                                                                    </h4> : ""}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-box2">
                                                        <div className="media">
                                                            <Link
                                                                to={`${process.env.PUBLIC_URL}/doctor/${product[4].slug}`}>
                                                                <img
                                                                    className="img-fluid blur-up lazyload"
                                                                    src={product[4].Pictures[0].url} alt=""/>
                                                            </Link>
                                                            <div className="media-body align-self-center">
                                                                <Link
                                                                    to={`${process.env.PUBLIC_URL}/doctor/${product[4].slug}`}>
                                                                    <h6>{product[4].Name}</h6>
                                                                </Link>

                                                                <h6 style={{
                                                                    fontSize: 'x-small',
                                                                    marginBottom: '0px',
                                                                    lineHeight: '12px'
                                                                }}>{product[4].Education}</h6>
                                                                <h6 style={{
                                                                    fontSize: 'x-small',
                                                                    lineHeight: '12px'
                                                                }}>Exp: {product[4].Experience}</h6>

                                                                <div className="rating"><i className="fa fa-star"></i>
                                                                    <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i> <i
                                                                        className="fa fa-star"></i></div>
                                                                {/*<Link to={`${process.env.PUBLIC_URL}/doctor/product/${product[4].id}`} >*/}
                                                                {/*    <h6>{product[4].name}</h6>*/}
                                                                {/*</Link>*/}
                                                                {product[4].Price ?
                                                                    <h4>{symbol}{product[4].Price - (product[4].Price * product[4].Discount / 100)}
                                                                        <del><span
                                                                            className="money">{symbol}{product[4].Price}</span>
                                                                        </del>
                                                                    </h4> : ""}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )
        } else {
            return (<div>

            </div>)
        }
    }
}

const mapStateToProps = (state, Ownprops) => ({
    product: getSpecialCollection(state.data.products, Ownprops.type),
    symbol: state.data.symbol
})

export default connect(mapStateToProps,
    {
        addToCart,
        addToWishlist,
        addToCompare,
        incrementQty,
        decrementQty,
        removeFromCart
    })(Special);