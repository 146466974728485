import React, { Component } from 'react';
import Breadcrumb from "../commonAll/breadcrumb";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state={
            medicalVisaData:{
                CountryofOrigin:'',
                DestinationCountry:'',
                DestinationHospital:'',
                TravelDate:'',
                NoofAttendents:'',
            }
        }
    }
    componentDidMount(){
        let href = window.location.href;
        let result = href.split("=");
        let data=JSON.parse(decodeURIComponent(result[1]));
        this.setState({
              medicalVisaData: data,
            })
        console.log("visa::main",data);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {

            let href = window.location.href;
            let result = href.split("=");
            let data=JSON.parse(decodeURIComponent(result[1]));
            this.setState({
                  medicalVisaData: data,
                })

        }

    }


    render(){
        const item=this.state.medicalVisaData;
        let list=[];
        if (item) {
            for(let i=1;i<=item.NoofAttendents;++i)
            {
                list.push(
                    <div className="col-md-6" key={i}>
                        <label>Attendent {i} Pasport</label>
                        <input type="file" className="form-control"
                             required=""/>

                    </div>
                );
            }

            // item.Data.forEach(value => {

            //     list.push(
            //     <li class="ant-list-item">
            //         {value.name}
            //             <ReactLink className="inquire_btn"
            //                 to={value.url}>
            //                 INQUIRE
            //             </ReactLink>
            //     </li>
            //     )
            // });
        }

        return(
            <div>
                {/* <img width="100%" height="750px" src="/assets/images/5070.jpg"/> */}

                <Breadcrumb title={'Medical Visa'}/>



                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="theme-card">
                                    <form className="theme-form">

                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label>Country of Origin</label>
                                                <input type="text" className="form-control" value={this.state.medicalVisaData.CountryofOrigin}
                                                       placeholder="Country of Origin" required="" readOnly/>


                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="phone">Destination Country</label>
                                                <input type="text" className="form-control" value={this.state.medicalVisaData.DestinationCountry}
                                                       placeholder="Destination Country" required="" readOnly/>

                                            </div>
                                            <div className="col-md-6">
                                                <label>Destination Hospital</label>
                                                <input type="text" className="form-control" value={this.state.medicalVisaData.DestinationHospital}
                                                       placeholder="Destination Hospital" required="" readOnly/>


                                            </div>
                                            <div className="col-md-6">
                                                <label>Travel Date</label>
                                                <input type="text" className="form-control" value={this.state.medicalVisaData.TravelDate}
                                                       placeholder="Travel Date" required="" readOnly/>

                                            </div>
                                            <div className="col-md-6">
                                                <label>No. of Attendents</label>
                                                <input type="text" className="form-control" value={this.state.medicalVisaData.NoofAttendents}
                                                       placeholder="No. of Attendents" required="" readOnly/>

                                            </div>
                                            <div className="col-md-6">
                                                <label>Patient Name</label>
                                                <input type="text" className="form-control"
                                                       placeholder="Patient Name" required=""/>

                                            </div>
                                            <div className="col-md-6">
                                                <label>Phone</label>
                                                <input type="text" className="form-control"
                                                       placeholder="Phone" required=""/>

                                            </div>
                                            <div className="col-md-6">
                                                <label>Email</label>
                                                <input type="email" className="form-control"
                                                       placeholder="Email" required=""/>

                                            </div>
                                            <div className="col-md-6">
                                                <label>Patient Passport</label>
                                                <input type="file" className="form-control"
                                                        required=""/>

                                            </div>
                                            {list}
                                            <div className="btn btn-solid"
                                                 style={{marginLeft: '10px',marginTop:"20px"}}>Submit</div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </div>
        );
    }
}
export default Main;
