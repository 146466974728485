import React, {Component} from 'react';
// import {Link} from 'react-router-dom';
import {getUser} from "../../../services/index";
import axios from "axios";
import appConfig from "../../../config";
// import {decrementQty, incrementQty, removeFromCart} from "../../../redux/actions";
import {logout} from "../../../redux/actions/authActions";
import {connect} from "react-redux";

class PatientReports extends Component {

    constructor() {
        super();
        this.state = {
            city: {},
            reports: [],
            //doctor: this.props.doctor,
        }
    }

    componentDidMount() {
        let user = getUser();
        if (!user) {
            window.open(`${process.env.PUBLIC_URL}/`, '_self');
        } else {
            this.getBookings(user, this.props.patient);
            //this.getBookings(this.props.user, doctor);
        }
    }

    printDateTime = (dt) => {
        let x = new Date(dt);
        let dtStr = x.toDateString() + " , " + x.toLocaleTimeString();
        return dtStr;
    }

    getBookings = (user, patient) => {

        // console.log("user booking ", user, patient);
        // var bookings = [];
        // let filter = {user: user.user.id};
        axios
            .get(appConfig.testReports + '?user=' + patient.id, {
                headers: {
                    Authorization: 'Bearer ' + user.jwt
                }
            })
            .then(response => {
                // Handle success.
                // console.log('Well done!');
                // console.log('Test data', response.data);
                this.setState({reports: response.data});
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
    }

    render() {

        // const {doctor, patient} = this.props;
        // console.log("PATIENT recived ", doctor, patient);
        const {reports} = this.state;
        //this.getBookings(this.props.user, doctor);

        return (

            <div className="dashboard">
                {reports.length > 0 ?
                    // <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-06">

                                <table className="table cart-table table-responsive-xs"
                                       style={{border: '1px solid #ddd'}}>
                                    <thead>
                                    <tr className="table-head">
                                        <th scope="col" style={{paddingTop: '20px'}}>Report</th>
                                        <th scope="col" style={{paddingTop: '20px'}}>Report Name</th>
                                        {/*<th scope="col" style={{paddingTop: '20px'}}>Type</th>*/}
                                        <th scope="col" style={{paddingTop: '20px'}}>Report Date</th>
                                        <th scope="col" style={{paddingTop: '20px'}}>Link</th>
                                        {/*<th scope="col"></th>*/}
                                    </tr>
                                    </thead>
                                    {reports.map((itm, index) => {
                                        let item = itm;
                                        // console.log("item is ", item);
                                        return (
                                            <tbody key={index}>
                                            <tr>
                                                <td>

                                                    <img src={item.Report[0].url}
                                                         style={{width: '70px'}} alt=""/>

                                                </td>
                                                <td>
                                                    {item.ReportName}
                                                </td>
                                                <td>
                                                    <span>{this.printDateTime(item.Date)}</span><br/>

                                                </td>
                                                <td>
                                                    <a href={item.BookingURL}
                                                       target="_blank">
                                                        View
                                                    </a>

                                                </td>
                                            </tr>
                                            </tbody>)
                                    })}
                                </table>
                            </div>
                        </div>

                    </div>
                    // </section>
                    :

                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div>
                                    <div className="col-sm-12 empty-cart-cls text-center">

                                        <h3>
                                            <strong>Doctor's Booking List is Empty</strong>
                                        </h3>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth,
})

export default connect(
    mapStateToProps,
    {logout}
)(PatientReports)
