import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getDoctors } from "../../../redux/actions/doctorActions";
import { Skeleton } from "antd";

import { getTotal, getCartProducts } from '../../../redux/reducers'
import { addToCart, addToWishlist, addToCompare, filterAddHospitalCities } from '../../../redux/actions'
import {
    getVisibleproducts,
    getVisibleDoctors,
    getDoctorsForSearchParam,
    getHospitalsForSearchParam, searchKeywords
} from '../../../services';
//import ProductListItem from "./_old/product-list-item";
import DoctorListItem from "./doctor-list-item";
import SearchCheck from "../../search/search";
import { getParams, getSearchParamID, getHospitals as searchHospitals } from "../../../api/apicalls";

class SearchListing extends Component {

    constructor(props) {
        super(props)

        this.state = {
            limit: 12,
            hasMoreItems: true,
            searchResult: [],
            filteredResults: []
        };
    }

    componentWillMount() {

        if (this.props.location.search) {
            this.getListingData();
            // eslint-disable-next-line no-restricted-globals
            // getDoctorsForSearchParam(getParams(location.href)).then(result => {
            //     console.log("SEARCH :: Result of listing is : ", result)
            //     if (result && result.length) {
            //         this.setState({
            //             searchResult: [...result]
            //         })
            //     }
            // });
        } else {
            // this.setState({
            //     searchResult: [...this.props.doctors.doctors]
            // })
        }

        // eslint-disable-next-line no-restricted-globals
        // getHospitalsForSearchParam(getParams(location.href)).then(result => {
        //     console.log("Result of listing is : ", result)
        //     this.setState({
        //         searchResult: [...this.state.searchResult, ...result]
        //     })
        // });

    }

    filterData() {
        let result = this.state.searchResult;
        let { filterHos} = this.props;
        console.log("hospital Listing Props", this.props);
 
        let tempResult = [];
        result.map(product => {
         
            //  return product;
            if (
                (filterHos.hCheckedCity.length?filterHos.hCheckedCity.includes(product.City):true)&&
                (product.Rating <= filterHos.hrate[1] && product.Rating >= filterHos.hrate[0])
            ) {
                tempResult.push(product);

            }


        })
        let filteredResults = tempResult;


        console.log("hi", filteredResults)
        this.setState({ filteredResults: filteredResults })
        //    filter(product=>(product.Rating<=filterHos.drate.max && product.Rating>=filterHos.drate.min)).filter(product=>(product.Experience<=filterHos.experience.max && product.Experience>=filterHos.experience.min))
        //    .filter(product=>(product.Price<=filterHos.dprices.max && product.Price>=filterHos.dprices.min))

        // //    map((product)=>{
        // //     if((product.Rating<=filterHos.drate.max && product.Rating>=filterHos.drate.min)&& 
        // //     (product.Experience<=filterHos.experience.max && product.Experience>=filterHos.experience.min)&&
        // //     (product.OnVideo===filterHos.availableFor.videoConference && product.OnPhone===filterHos.availableFor.onCall && product.OnLocation===filterHos.availableFor.inHospital)&&
        // //      (product.Price<=filterHos.dprices.max && product.Price>=filterHos.dprices.min)){
        // //          return product
        // //      }
        // // })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            // this.getSearchParam();
        }
        if (prevProps.searchString !== this.props.searchString) {
            console.log("SEARCH-LISTING :: searchString Updated")
            this.getListingData();
        }
        if (JSON.stringify(prevProps.filterHos) !== JSON.stringify(this.props.filterHos)) {
            this.filterData();
        }
        
      
    }

    getListingData = () => {
        // eslint-disable-next-line no-restricted-globals
        let params = getParams(location.href)
        let prms = [];
        // console.log("SEARCH :: params for url are : ", params);
        if (Object.keys(params).length && Object.keys(params)[0] !== '') {
            Object.keys(params).map((param, idx) => {
                // console.log("SEARCH :: The individual Param is : ", param, ' - ', params[param]);
                prms.push(getSearchParamID(param, params[param].replaceAll('-', ' ')))
            })
            Promise.all(prms).then(resp => {
                // console.log("SEARCH :: ALL PROMISE : ", resp);
                let qer = '';
                resp.map(query => {
                    // console.log("SEARCH :: queary is : ", query[0])
                    qer = qer.length ? qer + "&" + searchKeywords[query[0].category] + '=' + query[0].id : searchKeywords[query[0].category] + '=' + query[0].id
                })
                console.log("SEARCH :: Final Queary is : ", qer)
                searchHospitals('?' + qer).then(r => {
                    this.setState({searchResult: [...r],filteredResults:[...r]});
                    let cities=[];
                    r.forEach(element => {
                        if(cities.indexOf(element.City)===-1)
                        cities.push(element.City);
                    });
                    this.props.handleCity(cities);

                    this.props.filterAddHospitalCities(cities);
                })
            })
        }


    }

    fetchMoreItems = () => {
        // console.log("reached ", this.state.limit, this.props.doctors.doctors.length)
        if (this.state.limit >= this.state.searchResult.length) {
            this.setState({ hasMoreItems: false });
            return;
        }
        // a fake async api call
        setTimeout(() => {
            this.setState({
                limit: this.state.limit + 8
            });
        }, 1000);


    }

    render() {
        console.log("search-listing::Filters", this.props.filters);
        const { products, doctors, addToCart, symbol, addToWishlist, addToCompare, filters } = this.props;
        const { filteredResults, searchResult } = this.state;
        console.log("searchResult:", searchResult);
        window.dlstate = this.state;
        window.srclistingprops = this.props;
        // console.log("DOCTOR/LISTING-PAGE props : ", this.props);
        // console.log("DOCTOR/LISTING-PAGE state : ", this.state);

        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        {(searchResult.length && searchResult.length > 0) ?
                            <InfiniteScroll
                                dataLength={this.state.limit} //This is important field to render the next data
                                next={this.fetchMoreItems}
                                hasMore={this.state.hasMoreItems}
                                // loader={<div className="loading-cls"></div>}
                                endMessage={
                                    <p className="seen-cls seen-it-cls">
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            >
                                <div className="row">
                                    {/*{ doctors.doctors.slice(0, this.state.limit).map((product, index) =>*/}
                                    {/*{searchResult.slice(0, this.state.limit).map((product, index) =>*/}
                                    {filteredResults.map((product, index) => {


                                        // <div className={`${this.props.colSize===3?'col-xl-3 col-md-6 col-grid-box':'col-lg-'+this.props.colSize}`} key={index}>
                                        return (
                                            <div className={`${'col-lg-12'}`} key={index}>

                                                {/*// <div key={index}>*/}
                                                {/*<DoctorListItem product={product} symbol={symbol}*/}
                                                {/*                 onAddToCompareClicked={() => addToCompare(product)}*/}
                                                {/*                 onAddToWishlistClicked={() => addToWishlist(product)}*/}
                                                {/*                 onAddToCartClicked={addToCart} key={index}/>*/}
                                                {/*{}*/}
                                                {/*{product.category === 'hospital'?*/}
                                                {/*    <SearchCheck type="Hospital" data={product}/>*/}
                                                {/*    :<SearchCheck type="Doctor" data={product}/>}*/}

                                                {/*<SearchCheck type="Doctor" data={product}/>*/}
                                                <SearchCheck type="Hospital" data={product} />
                                                {/*<SearchCheck type="Doctor" data={*/}
                                                {/*    {*/}
                                                {/*        Name: product.Name,*/}
                                                {/*        Verified: true,*/}
                                                {/*        Pictures: product.Pictures,*/}
                                                {/*        // Designation:"CTO",*/}
                                                {/*        Experience: product.Experience,*/}
                                                {/*        Department: product.Department,*/}
                                                {/*        Designation: product.Designation,*/}
                                                {/*        Specialization: "Radiotherapy & Tonsil"*/}
                                                {/*    }}/>*/}
                                            </div>)

                                    })
                                    }
                                </div>
                            </InfiniteScroll>
                            :
                            <Skeleton active avatar paragraph={{ rows: 7 }} />
                        }
                        {(searchResult.length && searchResult.length == 0) ?
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                                        className="img-fluid mb-4" />
                                    <h3>Sorry! Couldn't find the result you were looking For!!! </h3>
                                    <p>Please check if you have misspelt something or try searching with other
                                     words.</p>
                                    <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue
                                     </Link>
                                </div>
                            </div> : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    // products: getVisibleDoctors(state.data, state.filters),
    // eslint-disable-next-line no-restricted-globals
    // products: getDoctorsForSearchParam(getParams(location.href)),

    searchString: state.filters.searchString,

    doctors: state.doctors,
    filters: state.filters,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps, { addToCart, addToWishlist, addToCompare, getDoctors, filterAddHospitalCities }
)(withRouter(SearchListing))
