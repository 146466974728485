import React, { Component } from "react";
import "../commonAll/index.scss";
import ImageZoom from "../products/common/product/image-zoom";
import SmallImages from "../products/common/product/small-image";

import Slider from "react-slick";
import { Rate } from "antd";
import { Link } from "react-router-dom";
import { Typography } from "antd";

const { Text } = Typography;

class Hospital extends Component {
  constructor(props) {
    super();
    this.state = {
      nav1: null,
      nav2: null,
      item: {},
      doctors: [],
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
    let hospital = this.props.data;
    // console.log("hospital-card", this.props.data);

    this.setState({ hospital });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      let hospital = this.props.data;
      this.setState({ hospital });
    }
  }

  render() {
    const {
      symbol,
      addToCart,
      addToCartUnsafe,
      addToWishlist,
      auth,
    } = this.props;
    let products = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      fade: true,
    };
    let productsnav = {
      slidesToShow: 3,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
    };
    let data = this.props.data;
    const item = this.state.hospital;
    // console.log("iteeeeem", item);
    return (
      <div>
        {item ? (
          <div className="searchCard">
            <div className="row" style={{ borderBottom: "1px solid #d4d4d4" }}>
              <div className="col-lg-4 ">
                <Link to={`${process.env.PUBLIC_URL}/hospital/${item.Alias}`}>
                  <Slider
                    {...products}
                    asNavFor={this.state.nav2}
                    ref={(slider) => (this.slider1 = slider)}
                    style={{ maxWidth: "200px" }}
                    className="product-slick"
                  >
                    <div>
                      <ImageZoom
                        image={item.Pictures[0].url}
                        className="img-fluid image_zoom_cls-0"
                      />
                    </div>
                    {/*{item.Pictures.map((vari, index) =>*/}
                    {/*    <div key={index}>*/}
                    {/*        <ImageZoom image={vari.url} style={{maxWidth:'250px'}}*/}
                    {/*                   className="img-fluid image_zoom_cls-0"/>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                  </Slider>
                  {/*{item.Pictures.length > 1 ?*/}
                  {/*    <SmallImages item={item} settings={productsnav}*/}
                  {/*                 navOne={this.state.nav1}/> : ""}*/}
                </Link>
              </div>
              <div className="col-lg-8 pad">
                <Link to={`${process.env.PUBLIC_URL}/hospital/${item.Alias}`}>
                  <h3>
                    {" "}
                    {item.Name}
                    {item.Verified ? (
                      <img
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/images/icons/verified.svg`}
                        style={{ width: "20px", paddingBottom: "10px" }}
                        alt={"verified"}
                      />
                    ) : (
                      ""
                    )}
                  </h3>
                </Link>
                <Text>
                  Established In:{" "}
                  <Text strong>
                    {item.EstablishedYear} | Number of Beds: {item.Beds}
                  </Text>
                </Text>
                <br />
                <Text>
                  Times Rank:<Text strong> {item.TOIRank}</Text>
                </Text>
                <br />
                <Text>
                  <Text strong>{item.doctors.length} </Text> Doctors in Panel
                </Text>
                <br />
                <Text>
                  <Text strong>73 </Text>Specalisations,<Text strong>98</Text>{" "}
                  Listed Treatments
                </Text>
                <br />

                <div className="product-description border-product">
                  <div className="rating">
                    <Rate
                      disabled
                      value={item.Rating}
                      style={{ color: "#ffa201" }}
                    />{" "}
                    Rating on Google, FB
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row padd"
              style={{ justifyContent: "space-between" }}
            >
              <div
                style={{
                  width: "133px",
                  fontWeight: "900",
                  borderRight: "1px solid #d4d4d4",
                }}
              >
                Times of India Rank:{" "}
                <span style={{ fontSize: "18px" }}>{item.TOIRank}/10</span>
              </div>
              <button className="btn btn-solid cardBtn">
                Doctors Available
              </button>
              <button className="btn btn-solid cardBtn">Favroute</button>
              <button className="btn btn-solid cardBtn">Get Opinion</button>
              <button className="btn btn-solid cardBtn">Get Estimate</button>

              <button className="btn btn-solid cardBtn">Live Chat</button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Hospital;
