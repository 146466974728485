import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Breadcrumb from "../commonAll/breadcrumb";
import NewProduct from "../commonAll/new-product";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/_old/product-listing";
import StickyBox from "react-sticky-box";
import FilterTreatment from "./common/filter-treatment";
import DoctorListing from "./common/doctor-listing";
import {getParams} from "../../api/apicalls";
import SearchListing from "./common/search-listing";
import {addToWishlistUnsafe} from '../../redux/actions';

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openFilter: false,
            layoutColumns: 3,
            //city: (this.props.params.city)?this.props.params.city:"INDIA",
            city: "INDIA",
            spcl: [],
            topic: '',
            sticky: false,
            rate: [0,5],
            drate: [0, 5],
            dcity: [],
            dCheckedCity: [],
            dprices: [ 0,  15000],
            gender: {male: false, female: false},
            experience: [0, 100],
            availableFor: {videoConference: false, onCall: false, inHospital: false},
            flag: false

        }
        //console.log("props",props);
    }

    // state = {
    //     layoutColumns: 3
    // }
    handleFlag = (value) => {
        this.setState({flag: value})

    }
    handleCity = (value) => {
        this.setState({dcity: value})

    }
    handleCheckedCity = (value) => {

        this.setState({dCheckedCity: value})
        this.setState({flag:!this.state.flag})
    }
    handleSlideChange = (name, value) => {
        this.setState({[name]: value})
    }
    handleAvailableChange = (value) => {
        this.setState({availableFor: value})
    }
    handleGenderChange = (value) => {
        this.setState({gender: value})
    }

    componentDidMount() {
        // eslint-disable-next-line no-restricted-globals
        // let url = location.href;
        // let params = this.getParams(url)
        // let spcl = params.s.split(',');
        // console.log(">>>>>>>> DOCTORS QUERY : ", url);
        // console.log(">>>>>>>> DOCTORS QUERY : ", params);
        // console.log(">>>>>>>> DOCTORS QUERY : ", spcl);
        // this.setState({spcl});
        // this.getSearchParam();


        if (process.browser) {
            window.addEventListener('scroll', this.handleScroll);
        }


    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        // console.log("window.pageYOffset", window.pageYOffset)


        let header = document.querySelector(".sticky");

        if (!header.classList.contains("hidden")) {
            document.querySelector(".collection-filter").style = "margin-top: 50px";

        } else {
            document.querySelector(".collection-filter").style = "margin-top: 0px";
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            // this.getSearchParam();
        }
    }

    getSearchParam = () => {
        console.log("SEARCH :: Get Search Header Parameter : ")
        // eslint-disable-next-line no-restricted-globals
        let url = location.href;
        let params = getParams(url);

        console.log("SEARCH :: Get Search Header Parameter : ", params)
        if (params['Treatment']) {
            this.setState({
                // defaultTreatment: params['Treatment'].replace('-', ' ')
                topic: params['Treatment'].replaceAll('-', ' ')
            })
        } else if (params['Condition']) {
            this.setState({
                topic: params['Condition'].replaceAll('-', ' ')
            })
        } else if (params['Specialisation']) {
            this.setState({
                topic: params['Specialisation'].replaceAll('-', ' ')
            })
        } else if (params['Hospital']) {
            this.setState({
                topic: params['Hospital'].replaceAll('-', ' ')
            })
        } else if (params['Doctor']) {
            this.setState({
                topic: params['Doctor'].replaceAll('-', ' ')
            })
        }
    }


    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns: colums
        })
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }

    render() {
        const {match: {params}} = this.props;
        const {spcl, rate, drate, dprices, gender, experience, availableFor, dcity, dCheckedCity, flag} = this.state;
        window.srcpageProps = this.props;
        console.log(rate, drate, dprices, gender, experience, availableFor)
        console.log("search::dCheckedCity", dCheckedCity);
        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Treatment Excellence</title>
                    <meta name="description" content="Hospitals"/>
                </Helmet>
                {/*SEO Support End */}

                {/*<Breadcrumb title={'Doctors'}/>*/}

                <section className="section-b-space" style={{backgroundColor: '#ECECEC'}}>
                    <div className="collection-wrapper">
                        <div className="container inframe">
                            <div className="row">
                                <div className="col-sm-3 collection-filter">

                                    <StickyBox offsetTop={20} offsetBottom={20}>
                                        <div>
                                            <FilterTreatment handleCheckedCity={this.handleCheckedCity}
                                                          handleSlideChange={this.handleSlideChange}
                                                          handleAvailableChange={this.handleAvailableChange} values={{
                                                rate,
                                                drate,
                                                dprices,
                                                gender,
                                                experience,
                                                availableFor,
                                                dcity,
                                                dCheckedCity
                                            }} handleGenderChange={this.handleGenderChange} selectedcity={params.city}/>
                                            {/*<NewProduct/>*/}
                                            {/*<div className="collection-sidebar-banner">*/}
                                            {/*    <a href="#">*/}
                                            {/*        <img src={`${process.env.PUBLIC_URL}/assets/images/side-banner.png`} className="img-fluid" alt="" />*/}
                                            {/*    </a>*/}
                                            {/*</div>*/}
                                        </div>
                                    </StickyBox>
                                    {/*side-bar banner end here*/}
                                </div>
                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    {this.state.topic ?
                                                        <div className="top-banner-wrapper">
                                                            <a href="#"><img
                                                                src={`${process.env.PUBLIC_URL}/assets/images/mega-menu/2.jpg`}
                                                                className="img-fluid" alt=""/></a>
                                                            <div className="top-banner-content small-section">
                                                                <h4>{this.state.topic}</h4>
                                                                <h5>Lorem Ipsum is simply dummy text of the printing and
                                                                    typesetting industry.</h5>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and
                                                                    typesetting industry. Lorem Ipsum has been the
                                                                    industry's standard dummy text ever since the 1500s,
                                                                    when an unknown printer took a galley of type and
                                                                    scrambled it to make a type specimen book. It has
                                                                    survived not only five centuries, but also the leap
                                                                    into electronic typesetting, remaining essentially
                                                                    unchanged. It was popularised in the 1960s with the
                                                                    release of Letraset sheets containing Lorem Ipsum
                                                                    passages, and more recently with desktop publishing
                                                                    software like Aldus PageMaker including versions of
                                                                    Lorem Ipsum. </p>
                                                            </div>
                                                        </div>
                                                        : null}

                                                    <div className="collection-product-wrapper">
                                                        {/*<div className="product-top-filter">*/}
                                                        {/*    <div className="container-fluid p-0">*/}
                                                        {/*        <div className="row">*/}
                                                        {/*            <div className="col-xl-12">*/}
                                                        {/*                <div className="filter-main-btn">*/}
                                                        {/*                    <span onClick={this.openFilter}*/}
                                                        {/*                          className="filter-btn btn btn-theme"><i*/}
                                                        {/*                        className="fa fa-filter"*/}
                                                        {/*                        aria-hidden="true"></i> Filter</span>*/}
                                                        {/*                </div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="row">*/}
                                                        {/*            <div className="col-12">*/}
                                                        {/*                <FilterBar*/}
                                                        {/*                    onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}/>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}

                                                        {/*Products Listing Component*/}
                                                        <SearchListing handleFlag={this.handleFlag}
                                                                       handleCity={this.handleCity} 
                                                                       filterDoc={{
                                                                           rate,
                                                                           drate,
                                                                           dprices,
                                                                           gender,
                                                                           experience,
                                                                           availableFor,
                                                                           dcity,
                                                                           dCheckedCity,
                                                                           flag
                                                                       }} colSize={this.state.layoutColumns}/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Search;
