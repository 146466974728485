import React, { Component } from 'react';
import { connect } from 'react-redux'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { SlideToggle } from 'react-slide-toggle';


import {
    getBrands,
    getColors,
    getMinMaxPrice,
    getSpeciality,
    getDocspecial,
    getCity,
    getMinMaxRate
} from '../../../services';
import { filterDCity, filterDRate, filterPrice, filterDocspecial, filterExperience, filterGender, filterAvailableFor } from '../../../redux/actions'
import { getDoctors } from "../../../redux/actions/doctorActions";
import { Checkbox, Slider, Switch } from "antd";

class FilterDoctor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openFilter: false,
            spcl: []
        }
        //console.log("props",props);
    }

    closeFilter = () => {
        document.querySelector(".collection-filter").style = "left: -365px";
    }

    componentDidMount() {
        let { selectedcity, specialities } = this.props
        let fcity = [];
        // console.log("fiter recive ", selectedcity, specialities);

        // eslint-disable-next-line no-restricted-globals
        let url = location.href;
        let params = this.getParams(url);
        let spcl = [];
        if (params.s) {
            spcl = params.s.split(',');
            // console.log(">>>>>>>> DOCTORS QUERY : ", url);
            // console.log(">>>>>>>> DOCTORS QUERY : ", params);
            // console.log(">>>>>>>> DOCTORS QUERY : ", spcl);
            this.setState({ spcl });
        }


        if (selectedcity === "" || selectedcity.toUpperCase() === "INDIA") {
            fcity = [];
        } else {
            fcity = [selectedcity.toUpperCase()]
        }

        //   this.props.filterDCity(fcity);
        //this.updateHospitals();
        let filters = {
            ...this.props.filters,
            dcity: fcity,
            docspecial: spcl,
        }
        this.props.getDoctors(filters);


    }

    getParams = (url) => {
        var params = {};
        var parser = document.createElement('a');
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    };

    docspecialityHendle(event, docspecial) {

        var index = docspecial.indexOf(event.target.value);
        if (event.target.checked)
            docspecial.push(event.target.value); // push in array checked value
        else
            docspecial.splice(index, 1); // removed in array unchecked value

        this.props.filterDocspecial(docspecial);
        this.updateDoctors();
    }

    cityHendle(event, cities) {

        var index = cities.indexOf(event.target.value);
        if (event.target.checked)
            cities.push(event.target.value); // push in array checked value
        else
            cities.splice(index, 1); // removed in array unchecked value

        this.props.handleCheckedCity(cities);
       // this.props.filterDCity(cities);
        this.updateDoctors();
    }

    rateHandle(value) {
        this.props.filterDRate({ value });
        this.updateDoctors();
    }

    priceHandle(value,name) {
        this.props.handleSlideChange(name,value)
    }
    experienceHandle(value) {
        this.props.filterExperience({ value });
        this.updateDoctors();
    }
    genderMaleHandle(e) {
        alert(e.target.checked);
    }

    updateDoctors() {
        this.props.getDoctors(this.props.filters);
    }
    handleAvailableChange(value){
        this.props.handleAvailableChange(value)
    }
    handleGenderChange(value){
        this.props.handleGenderChange(value);
    }


    render() {

        const filterDocspecial = this.props.filters.docspecial ? this.props.filters.docspecial : [];
        const filterCities = this.props.values.dCheckedCity ? this.props.values.dCheckedCity : [];
        console.log("filter-doctor::filterCities",filterCities);
        const{rate,drate,dprices,gender,experience,availableFor,dcity} =this.props.values;
        const cities=dcity;
        console.log("filter-doctor::cities",cities);
        window.dfstate = this.state;
        window.dfprops = this.props;
        // console.log("DOCTOR/FILTER-PAGE props : ", this.props);
        // console.log("DOCTOR/FILTER-PAGE state : ", this.state);

        return (
            <div className="collection-filter-block">
                {/*brand filter start*/}
                <div className="collection-mobile-back">
                    <span className="filter-back" onClick={(e) => this.closeFilter(e)}>
                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                </div>
                {/*<SlideToggle>*/}
                {/*    {({onToggle, setCollapsibleElement}) => (*/}
                {/*        <div className="collection-collapse-block">*/}
                {/*            <h3 className="collapse-block-title" onClick={onToggle}>Specialication</h3>*/}
                {/*            <div className="collection-collapse-block-content" ref={setCollapsibleElement}>*/}
                {/*                <div className="collection-brand-filter">*/}
                {/*                    /!*{this.props.docspecial.map((speciality, index) => {*!/*/}
                {/*                    /!*    return (*!/*/}
                {/*                    /!*        <div className="custom-control custom-checkbox collection-filter-checkbox"*!/*/}
                {/*                    /!*             key={index}>*!/*/}
                {/*                    /!*            <input type="checkbox"*!/*/}
                {/*                    /!*                   onClick={(e) => this.docspecialityHendle(e, filterDocspecial)}*!/*/}
                {/*                    /!*                   value={speciality}*!/*/}
                {/*                    /!*                   defaultChecked={filterDocspecial.includes(speciality) ? true : false}*!/*/}
                {/*                    /!*                   className="custom-control-input" id={speciality}/>*!/*/}
                {/*                    /!*            <label className="custom-control-label"*!/*/}
                {/*                    /!*                   htmlFor={speciality}>{speciality}</label>*!/*/}
                {/*                    /!*        </div>)*!/*/}
                {/*                    /!*})}*!/*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</SlideToggle>*/}

                <SlideToggle>
                    {({ onToggle, setCollapsibleElement }) => (
                        <div className="collection-collapse-block">
                            <h3 className="collapse-block-title" onClick={onToggle}>Cities</h3>
                            <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                <div className="collection-brand-filter">
                                    {cities.map((city, index) => {
                                        return (
                                            <div className="custom-control custom-checkbox collection-filter-checkbox"
                                                key={index}>
                                                    <Checkbox
                                                        value= {city}
                                                        onChange={(e)=>{
                                                            let index = filterCities.indexOf(e.target.value);
                                                            if (e.target.checked)
                                                            filterCities.push(e.target.value); // push in array checked value
                                                            else
                                                            filterCities.splice(index, 1); // removed in array unchecked value
                                                            console.log(filterCities)
                                                            this.props.handleCheckedCity(filterCities);
                                                             console.log(`checked = ${e.target.checked}`);
                                                        }}> {city}</Checkbox>
                                                {/* <input type="checkbox"
                                                    onClick={(e) => this.cityHendle(e, filterCities)}
                                                    value={city}
                                                    defaultChecked={true}
                                                    className="custom-control-input" id={city} />
                                                <label className="custom-control-label"
                                                    htmlFor={city}>{city}</label> */}
                                            </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </SlideToggle>

                {/*<SlideToggle>*/}
                {/*    {({onToggle, setCollapsibleElement}) => (*/}
                {/*        <div className="collection-collapse-block">*/}
                {/*            <h3 className="collapse-block-title" onClick={onToggle}>brand</h3>*/}
                {/*            <div className="collection-collapse-block-content" ref={setCollapsibleElement}>*/}
                {/*                <div className="collection-brand-filter">*/}
                {/*                    {this.props.brands.map((brand, index) => {*/}
                {/*                        return (*/}
                {/*                            <div className="custom-control custom-checkbox collection-filter-checkbox"*/}
                {/*                                 key={index}>*/}
                {/*                                <input type="checkbox"*/}
                {/*                                       onClick={(e) => this.clickBrandHendle(e, filteredBrands)}*/}
                {/*                                       value={brand}*/}
                {/*                                       defaultChecked={filteredBrands.includes(brand) ? true : false}*/}
                {/*                                       className="custom-control-input" id={brand}/>*/}
                {/*                                <label className="custom-control-label"*/}
                {/*                                       htmlFor={brand}>{brand}</label>*/}
                {/*                            </div>)*/}
                {/*                    })}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</SlideToggle>*/}

                {/*color filter start here*/}
                {/*<SlideToggle>*/}
                {/*    {({onToggle, setCollapsibleElement}) => (*/}
                {/*        <div className="collection-collapse-block">*/}
                {/*            <h3 className="collapse-block-title" onClick={onToggle}>colors</h3>*/}
                {/*            <div className="collection-collapse-block-content" ref={setCollapsibleElement}>*/}
                {/*                <div className="color-selector">*/}
                {/*                    <ul>*/}
                {/*                        {this.props.colors.map((color, index) => {*/}
                {/*                            return (*/}
                {/*                                <li className={color} title={color}*/}
                {/*                                    onClick={(e) => this.colorHandle(e, color)} key={index}></li>)*/}
                {/*                        })}*/}
                {/*                    </ul>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</SlideToggle>*/}
                {/*price filter start here */}

                <SlideToggle>
                    {({ onToggle, setCollapsibleElement }) => (
                        <div className="collection-collapse-block open">
                            <h3 className="collapse-block-title" onClick={onToggle}>Rating</h3>
                            <div className="collection-collapse-block-content block-price-content"
                                ref={setCollapsibleElement}>
                                <div className="collection-brand-filter">
                                    <div className="custom-control custom-checkbox collection-filter-checkbox">
                                    <Slider
                                        range
                                        min={rate[0]}
                                        max={rate[1]}
                                        defaultValue={[rate[0], rate[1]]}
                                        onAfterChange={(value)=>this.priceHandle(value,"drate")}
                                        />
                                        {/* <InputRange
                                            maxValue={rate.max}
                                            minValue={rate.min}
                                            value={drate}
                                            onChange={value => this.priceHandle(value,"drate")} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </SlideToggle>

                <SlideToggle>
                    {({ onToggle, setCollapsibleElement }) => (
                        <div className="collection-collapse-block open">
                            <h3 className="collapse-block-title" onClick={onToggle}>price</h3>
                            <div className="collection-collapse-block-content block-price-content"
                                ref={setCollapsibleElement}>
                                <div className="collection-brand-filter">
                                    <div className="custom-control custom-checkbox collection-filter-checkbox">
                                    <Slider
                                        range
                                        min={this.props.prices.min}
                                        max={this.props.prices.max}
                                        defaultValue={[this.props.prices.min, this.props.prices.max]}
                                        onAfterChange={(value)=>this.priceHandle(value,'dprices')}
                                        />
                                        {/* <InputRange
                                            maxValue={this.props.prices.max}
                                            minValue={this.props.prices.min}
                                            value={dprices}
                                            onChange={value => this.priceHandle(value,'dprices')} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </SlideToggle>

                <SlideToggle>
                    {({ onToggle, setCollapsibleElement }) => (
                        <div className="collection-collapse-block open">
                            <h3 className="collapse-block-title" onClick={onToggle}>Available For</h3>
                            <div className="collection-collapse-block-content block-price-content"
                                ref={setCollapsibleElement}>
                                <div className="collection-brand-filter" >
                                    <div className="custom-control custom-checkbox collection-filter-checkbox" style={{ marginTop: '0px', padding: '0px' }}>
                                        <br />
                                        <span>Video Conference</span><br />
                                        <Switch
                                            defaultChecked={availableFor.videoConference}
                                            onChange={(checked) => {
                                                this.handleAvailableChange(
                                                    {
                                                        videoConference: checked,
                                                        onCall: availableFor.onCall,
                                                        inHospital: availableFor.inHospital
                                                    }
                                                );
                                               
                                            }}
                                        /><br /><br />
                                        <span>Phone Call</span><br />
                                        <Switch
                                            defaultChecked={availableFor.onCall}
                                            onChange={(checked) => {
                                                this.handleAvailableChange(
                                                     {
                                                        videoConference: availableFor.videoConference,
                                                        onCall: checked,
                                                        inHospital: availableFor.inHospital
                                                    }
                                                )
                                                
                                            }}
                                        /><br /><br />
                                        <span>In Hospital</span><br />
                                        <Switch
                                            defaultChecked={availableFor.inHospital}
                                            onChange={(checked) => {
                                                this.handleAvailableChange(
                                                    {
                                                        videoConference: availableFor.videoConference,
                                                        onCall: availableFor.onCall,
                                                        inHospital: checked
                                                    }
                                                )
                                                
                                            }}
                                        /><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </SlideToggle>

                <SlideToggle>
                    {({ onToggle, setCollapsibleElement }) => (
                        <div className="collection-collapse-block open">
                            <h3 className="collapse-block-title" onClick={onToggle}>Gender</h3>
                            <div className="collection-collapse-block-content block-price-content"
                                ref={setCollapsibleElement}>
                                <div className="collection-brand-filter">
                                    <div className="custom-control custom-checkbox collection-filter-checkbox">
                                        <Checkbox
                                            // indeterminate={this.state.indeterminate}
                                            // onChange={this.onCheckAllChange}
                                            checked={gender.male}
                                            onChange={(e) => {
                                                this.handleGenderChange({ male: e.target.checked, female: gender.female });
                                               
                                            }}
                                        >
                                            Male
                                        </Checkbox><br />
                                        <Checkbox
                                            // indeterminate={this.state.indeterminate}
                                            // onChange={this.onCheckAllChange}
                                            checked={gender.female}
                                            onChange={e => {
                                                this.handleGenderChange({ male: gender.male, female: e.target.checked } )
                                              
                                            }}
                                        >
                                            Female
                                        </Checkbox>
                                        {/*<InputRange*/}
                                        {/*    maxValue={this.props.prices.max}*/}
                                        {/*    minValue={this.props.prices.min}*/}
                                        {/*    value={this.props.filters.dprices}*/}
                                        {/*    onChange={value => this.priceHandle(value)}/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </SlideToggle>

                <SlideToggle>
                    {({ onToggle, setCollapsibleElement }) => (
                        <div className="collection-collapse-block open">
                            <h3 className="collapse-block-title" onClick={onToggle}>Experience</h3>
                            <div className="collection-collapse-block-content block-price-content"
                                ref={setCollapsibleElement}>
                                <div className="collection-brand-filter" >
                                    <div className="custom-control custom-checkbox collection-filter-checkbox" style={{ marginTop: '50px', padding: '7px' }}>
                                    <Slider
                                        range
                                        min={this.props.experience.min}
                                        max={this.props.experience.max}
                                        defaultValue={[this.props.experience.min, this.props.experience.max]}
                                        onAfterChange={(value)=>this.priceHandle(value,"experience")}
                                        />
                                        {/* <InputRange
                                            maxValue={this.props.experience.max}
                                            minValue={this.props.experience.min}
                                            value={experience}
                                            onChange={value => this.priceHandle(value,"experience")} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </SlideToggle>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    //brands: getBrands(state.data.products),
    //specialities: getSpeciality(state.data.products),
    docspecial: getDocspecial(state.doctors.doctors),
    cities: getCity(state.doctors.doctors),
    rate: { min: 0, max: 5 },
    //prices: getMinMaxPrice(state.data.products),
    prices: { min: 0, max: 15000 },
    experience: { min: 0, max: 100 },
    filters: state.filters
})

export default connect(
    mapStateToProps,
    { filterDCity, filterDRate, filterPrice, filterDocspecial, getDoctors, filterExperience, filterGender, filterAvailableFor }
)(FilterDoctor);
