import React, { Component } from 'react';
import Breadcrumb from "../commonAll/breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { getCartTotal, getUser, printDateTime } from "../../services";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { decrementQty, incrementQty, removeFromCart } from "../../redux/actions";
import axios from "axios";
import appConfig from "../../config";
import { logout } from "../../redux/actions/authActions";
import SessionList from "./common/sessionList";

class HospitalBookings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: getUser(),
            bookings: [],
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        let user = getUser();
        if (!user) {
            window.open(`${process.env.PUBLIC_URL}/`, '_self');
        } else {
            this.getBookings(user);
        }
    }

    printDateTime = (dt) => {
        let x = new Date(dt);
        let dtStr = x.toDateString() + " , " + x.toLocaleTimeString();
        return dtStr;
    }

    getBookings = (user) => {
        console.log("userr", user.user.Doctors[0].id);
        var bookings = [];
        let filter = { user: user.user.id };
        user.user.Doctors.forEach(element => {
            axios
                .get(appConfig.bookingOrder + '?doctor=' + element.id, {
                    headers: {
                        Authorization: 'Bearer ' + user.jwt
                    }
                })
                .then(response => {
                    // Handle success.
                    console.log('Well done!');
                    console.log('booking data', response.data);

                    this.setState({ bookings: [...this.state.bookings, response.data] });

                })
                .catch(error => {
                    // Handle error.
                    console.log('An error occurred:', error);
                });
        });


    }

    // getDoctorTable(bookings){
    //     return()
    // }

    render() {
        console.log("bookingsr", this.state.bookings);
        const { cartItems, symbol, total, user } = this.props;
        console.log("cart item", cartItems);

        const { bookings } = this.state;
        console.log("DASHBOARD/USER  user : ", user);
        console.log("DASHBOARD/USER  props : ", this.props);
        console.log("DASHBOARD/USER  state : ", this.state);
        // console.log("state is ", this.state);

        if (user.isLogedIn && user.user.user.type === "hospital") {
            console.log("DASHBOARD/USER  user Object : ", user);
        } else {
            console.error("DASHBOARD/USER  user not allowed on this location : ", user.user.user.type);
        }


        return (

            <div>
                <Breadcrumb title={'Bookings'} />
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    <a className="popup-btn">
                                        my account
                                    </a>
                                </div>
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                        <span className="filter-back">
                                            <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li ><a
                                                href={`${process.env.PUBLIC_URL}/pages/hospitaldashboard`}>Dashboard</a></li>
                                            <li className="active"><a href={`${process.env.PUBLIC_URL}/pages/hospitalbookings`}>My Bookings</a>
                                            </li>
                                            <li ><a href={`${process.env.PUBLIC_URL}/pages/hospitalprofile`}>Edit Profile</a>
                                            </li>
                                            {/*<li><a href="#">My Account</a></li>*/}
                                            {/*<li><a href="#">My Wishlist</a></li>*/}
                                            {/*<li><a href="#">Address Book</a></li>*/}
                                            {/*<li><a href="#">Newsletter</a></li>*/}
                                            {/*<li><a href="#">Change Password</a></li>*/}
                                            <li className="last"><a href="#" onClick={this.props.logout}>Log Out</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="dashboard-right">
                                <div className="dashboard">
                                    {bookings.length > 0 ?
                                        <section></section>
                                        : <section></section>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart),
    user: state.auth,
})

export default connect(
    mapStateToProps,
    { removeFromCart, incrementQty, decrementQty, logout }
)(HospitalBookings)

//export default Dashboard