import React, { Component } from "react";
import Slider from "react-slick";
import "../commonAll/index.scss";
import "../commonAll/atag.scss";
import { connect } from "react-redux";
// import {Button} from 'react-bootstrap';

// import custom Components

import DetailsTopTabs from "./common/details-top-tabs";
import { addToCart, addToCartUnsafe, addToWishlist } from "../../redux/actions";

import LiveChat from "./common/product/liveChat";

import axios from "axios";
import appConfig from "../../config";

import CommentsHosital from "./common/comments/comments-hosital";

import { Collapse, Skeleton } from "antd";
import { Rate } from "antd";
//import { Link } from 'react-router-dom';
import { Anchor } from "antd";
import { Link as ReactLink } from "react-router-dom";
import HospitalProcedure from "./common/product/hospital-procedure";

const { Link } = Anchor;

const { Panel } = Collapse;

class Hospital extends Component {
  baseUrl;

  constructor() {
    super();
    this.state = {
      nav1: null,
      nav2: null,
      item: {},
      doctors: [],
      // sticky: false,
      doctClicked: "#008CBA",
      departClicked: null,
      reviewClicked: null,
      roomClicked: null,
      hospitalProcedure: "",
      top: "",
    };
  }

  listenScrollEvent = (e) => {
    // console.log(window.scrollY)
    if (window.scrollY > 100) {
      this.setState({ top: "0" });
    } else {
      this.setState({ top: "" });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
    document.getElementById("sticky").style.display = "none";
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
    const {
      match: { params },
    } = this.props;
    this.hospitalId = params.id;
    console.log("HOSPITAL/ITEM-PAGE State : ", this.state);
    console.log("HOSPITAL/ITEM-PAGE Props : ", this.props);
    console.log("HOSPITAL/ITEM-PAGE useParams : ", params);
    this.getHospital(params.id);
    // if (process.browser) {
    //     window.addEventListener('scroll', () => {
    //         console.log("window.pageYOffset", window.pageYOffset)

    //         var header = document.getElementById("headerdocSticky");
    //         if (header) {
    //             var sticky = header.offsetTop;

    //             console.log("stickyoffset", sticky);
    //             console.log("window.pageYOffset",window.pageYOffset);
    //             sticky+=95;
    //             if (window.pageYOffset > sticky) {
    //                 this.setState({ sticky: true });

    //             } else {
    //                 this.setState({ sticky: false });
    //             }
    //         }

    //     });
    // }
  }

  getHospital(id) {
    // let param = "/" + id;
    let param = "?Alias=" + id;
    axios.get(appConfig.hospitals + param).then((response) => {
      // Handle success.
      console.log("HOSPITAL/ITEM: Data Received", response.data);
      let hospital = response.data[0];
      this.setState({ hospital }, this.getDoctors(hospital.id));
    });
  }

  getDoctors(id) {
    let param = "?hospital=" + id;
    axios.get(appConfig.doctors + param).then((response) => {
      // Handle success.
      // console.log('HOSPITAL/Doctors: Data Received', response.data);
      let doctors = response.data;
      this.setState({ doctors });
    });
  }

  render() {
    const { auth } = this.props;
    var products = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      fade: true,
    };
    var productsnav = {
      slidesToShow: 3,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
    };
    const settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 586,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const item = this.state.hospital;
    console.log("item", item);
    // const { doctors } = this.state;
    //console.log("HOSPITAL/ITEM-PAGE : ", item);
    // console.log("HOSPITAL/ITEM-PAGE props : ", this.props);

    return (
      <div
        className=" container doctor-body hos-main"
        style={{ backgroundColor: "rgb(236, 236, 236)" }}
      >
        {item ? (
          <div>
            {item ? (
              <div
                className="price-hospital-inside-card hide-me"
                style={{ top: this.state.top }}
              >
                <LiveChat />
              </div>
            ) : null}
            <section
              id="Overview"
              style={{ paddingTop: "0", backgroundColor: "rgb(236, 236, 236)" }}
            >
              <div
                className="doctor-container"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="doctor-inner-container">
                  {/*<div style={{ width: "992px", marginLeft: "auto", marginRight: "auto", marginTop: "10px" }}>*/}
                  <div className=" inframe">
                    <div className="doctor-page-card">
                      <div className="summary row">
                        <div
                          className="col-md-4 "
                          style={{ marginRight: "24px" }}
                        >
                          {item.Pictures.length ? (
                            <img
                              className=" img-fluid"
                              src={item.Pictures[0].url}
                              alt=" hospital"
                            />
                          ) : (
                            <img
                              className="designated-provider-image"
                              src="/assets/images/doctors/Doctor-Male-Placeholder.jpg"
                              alt=" hospital"
                            />
                          )}
                        </div>
                        <div className="col-md-6 my-3 mt-md-0">
                          <div style={{ color: "#444548" }} className="h2">
                            {item.Name}
                          </div>
                          <div className="Patient-satisfaction-container">
                            <svg
                              width="60"
                              height="60"
                              version="1.1"
                              className="coin coin-low"
                              data-qa-target="patient-rating-coin"
                            >
                              <g>
                                <circle cx="30" cy="30" r="27" />
                                <text x="31" y="35">
                                  45%
                                </text>
                              </g>
                            </svg>

                            <p style={{ lineHeight: "1.5" }}>
                              <strong style={{ fontSize: "16px" }}>
                                Patients that would definitely&nbsp;recommend
                              </strong>
                              <br />
                              <span>25% lower than the national average</span>
                            </p>
                          </div>
                          <hr />
                          <div className="doctor-about-container">
                            <p
                              style={{ color: "#444548" }}
                              className="about-doctor"
                            >
                              {item.Description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <hr
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />

            <div className="container inframe">
              <Anchor style={{ marginLeft: "0px" }}>
                <Link
                  href={`/hospital/${this.hospitalId}#Overview`}
                  title="Overview"
                />
                <Link
                  href={`/hospital/${this.hospitalId}#Doctors`}
                  title="Doctors"
                />
                <Link
                  href={`/hospital/${this.hospitalId}#Procedures`}
                  title="Procedures"
                />
                <Link
                  href={`/hospital/${this.hospitalId}#Facilities`}
                  title="Facilities"
                />
                <Link
                  href={`/hospital/${this.hospitalId}#Reviews`}
                  title="Reviews"
                />
                <Link
                  href={`/hospital/${this.hospitalId}#Gallery`}
                  title="Hotels"
                />
              </Anchor>

              {/*<div style={{width: "960px", marginLeft: "auto", marginRight: "auto"}}>*/}

              <section id="Doctors" style={{ margin: "0" }}>
                <section className="silky">
                  <h3>Doctors</h3>

                  {this.state.doctors.length > 3 ? (
                    <section>
                      <Slider {...settings}>
                        {this.state.doctors
                          ? this.state.doctors.map((vari, index) => (
                              <div
                                key={index}
                                style={{ height: "100px", width: "300px" }}
                              >
                                <div>
                                  {/* < a href={'/doctor/' + vari.slug} style={{ */}
                                  <ReactLink
                                    to={`${process.env.PUBLIC_URL}/doctor/${
                                      vari.slug
                                    }`}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      border: "1px solid #eed7d7",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {vari.Pictures && vari.Pictures.length ? (
                                      <img
                                        src={vari.Pictures[0].url}
                                        style={{ width: "100px", flex: "1" }}
                                        alt="Treatment Excellence"
                                      />
                                    ) : null}

                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: "3",
                                        marginLeft: "21px",
                                      }}
                                    >
                                      {/*<ReactLink*/}
                                      {/*    to={`${process.env.PUBLIC_URL}/doctor/${*/}
                                      {/*        vari.slug*/}
                                      {/*    }`}*/}
                                      {/*    style={{color: "vilot"}}*/}
                                      {/*>*/}
                                      {/*    {vari.Name}*/}
                                      {/*</ReactLink>*/}
                                      <Rate
                                        disabled
                                        value={vari.Rating}
                                        style={{
                                          fontSize: 17,
                                          color: "#ffa201",
                                        }}
                                      />
                                    </div>
                                  </ReactLink>
                                </div>
                              </div>
                            ))
                          : null}
                      </Slider>
                    </section>
                  ) : (
                    <section
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: "115px",
                      }}
                    >
                      {this.state.doctors.map((vari, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flex: "1",
                            flexDirection: "column",
                            height: "100%",
                            width: "380px",
                          }}
                        >
                          <ReactLink
                            to={`${process.env.PUBLIC_URL}/doctor/${vari.slug}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #eed7d7",
                              marginRight: "20px",
                            }}
                          >
                            <img
                              src={vari.Pictures[0].url}
                              style={{ width: "100px" }}
                              alt="Treatment Excellence"
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: "3",
                                marginLeft: "21px",
                              }}
                            >
                              <ReactLink
                                to={`${process.env.PUBLIC_URL}/doctor/${
                                  vari.slug
                                }`}
                                style={{ color: "vilot" }}
                              >
                                {vari.Name}
                              </ReactLink>
                              <Rate
                                disabled
                                value={vari.Rating}
                                style={{ fontSize: 17, color: "#ffa201" }}
                              />
                            </div>
                          </ReactLink>
                        </div>
                      ))}
                    </section>
                  )}
                </section>
              </section>
              <section id="Procedures">
                <h3>Procedures</h3>
                <p style={{ lineHeight: "1.5" }}>
                  {item.Name} performs different procedures. Click on a
                  procedure name to see more details and to view doctors for
                  that specialty.
                </p>
                <ul>
                  <li
                    className="btn"
                    style={{
                      border: "1px solid #b49494",
                      margin: "0 20px 20px 0",
                    }}
                  >
                    <ReactLink
                      style={{ color: "#666" }}
                      onClick={() => {
                        this.setState({
                          hospitalProcedure: {
                            Name: "Cardiology",
                            Data: [
                              {
                                name: "dertlogist",
                                url: "http://goodsdsds.com",
                              },
                              { name: "tolostid", url: "http://ssssr.com" },
                            ],
                          },
                        });
                      }}
                      to={`${process.env.PUBLIC_URL}/hospital/${
                        this.hospitalId
                      }#hospital-procedure`}
                    >
                      Cardiology
                    </ReactLink>
                  </li>

                  <li
                    className="btn"
                    style={{
                      border: "1px solid #b49494",
                      margin: "0 20px 20px 0",
                    }}
                  >
                    <ReactLink
                      style={{ color: "#666" }}
                      onClick={() => {
                        this.setState({
                          hospitalProcedure: {
                            Name: "Neurology",
                            Data: [
                              {
                                name: "nuroscience",
                                url: "http://goodsdsds.com",
                              },
                              { name: "htukk", url: "http://ssssr.com" },
                            ],
                          },
                        });
                      }}
                      to={`${process.env.PUBLIC_URL}/hospital/${
                        this.hospitalId
                      }#hospital-procedure`}
                    >
                      Neurology
                    </ReactLink>
                  </li>
                </ul>
                <section id="hospital-procedure">
                  <HospitalProcedure data={this.state.hospitalProcedure} />
                </section>
              </section>
              <section id="Facilities">
                <h3>Our Facilities:</h3>
                <DetailsTopTabs item={item} />
              </section>
              <section id="Reviews">
                <CommentsHosital item={item} auth={auth} type={"hospital"} />
              </section>
              <section id="Gallery" />

              {/*<section style={{height: "800px"}}>*/}
              {/*    <h3>Hotels Nearby</h3>*/}

              {/*    <ins*/}
              {/*        className="bookingaff"*/}
              {/*        data-aid="2101544"*/}
              {/*        data-target_aid="2101544"*/}
              {/*        data-prod="map"*/}
              {/*        data-width="100%"*/}
              {/*        data-height="590"*/}
              {/*        data-lang="ualng"*/}
              {/*        data-dest_id="0"*/}
              {/*        data-dest_type="landmark"*/}
              {/*        data-latitude="28.5406104"*/}
              {/*        data-longitude="77.2830214"*/}
              {/*        data-landmark_name="Apollo Hospital"*/}
              {/*        data-mwhsb="0"*/}
              {/*        data-address="Indraprastha Apollo Hospital, Mathura Road, Sarita Vihar, New Delhi, Delhi, India"*/}
              {/*    >*/}
              {/*        <a href="//www.booking.com?aid=2101544">Booking.com</a>*/}
              {/*    </ins>*/}
              {/*    {(function (d, sc, u) {*/}
              {/*        var s = d.createElement(sc),*/}
              {/*            p = d.getElementsByTagName(sc)[0];*/}
              {/*        s.type = "text/javascript";*/}
              {/*        s.async = true;*/}
              {/*        s.src = u + "?v=" + +new Date();*/}
              {/*        p.parentNode.insertBefore(s, p);*/}
              {/*    })(*/}
              {/*        document,*/}
              {/*        "script",*/}
              {/*        "//aff.bstatic.com/static/affiliate_base/js/flexiproduct.js"*/}
              {/*    )}*/}
              {/*    /!*<script type="text/javascript">*!/*/}
              {/*    /!*    *!/*/}
              {/*    /!*</script>*!/*/}
              {/*</section>*/}
            </div>
          </div>
        ) : (
          <Skeleton active avatar paragraph={{ rows: 7 }} />
        )}
      </div>

      //             <div style={{ backgroundColor: "#f5f5f5" }}>
      //                 {/*<div style={{margin:'0 10% 0 10%',boxShadow: "0px 3px 5px 5px rgba(0,0,0,0.15)"}}>*/}
      //                 <div className='container inPageFrame'>
      //                     <Breadcrumb title={' Hospital / ' + (item ? item.Name : " ")} />
      //                     {/*Section Start*/}
      //                     {(item && this.state.doctors.length) ?
      //                         <section className="section-b-space">
      //                             <div className="collection-wrapper">
      //                                 <div className="container">
      //                                     <div className="row">
      //                                         <div className="col-lg-9 col-sm-12 col-xs-12">
      //                                             <div className="container-fluid">

      //                                                 <div className="row">
      //                                                     <div className="col-lg-6 product-thumbnail">
      //                                                         <h3 style={{ marginBottom: '5px' }}> {item.Name} {item.Verified ?
      //                                                             <img
      //                                                                 src={`${process.env.PUBLIC_URL}/assets/images/icons/verified.svg`}
      //                                                                 style={{ width: '20px', paddingBottom: '10px' }}
      //                                                                 alt={"verified"} /> : ''}</h3>
      //                                                         <Slider {...products} asNavFor={this.state.nav2}
      //                                                             ref={slider => (this.slider1 = slider)}
      //                                                             className="product-slick">
      //                                                             {item.Pictures.map((vari, index) =>
      //                                                                 <div key={index}>
      //                                                                     <ImageZoom image={vari.url}
      //                                                                         className="img-fluid image_zoom_cls-0" />
      //                                                                 </div>
      //                                                             )}
      //                                                         </Slider>
      //                                                         {item.Pictures.length > 1 ?
      //                                                             <SmallImages item={item} settings={productsnav}
      //                                                                 navOne={this.state.nav1} /> : ""}

      //                                                     </div>
      //                                                     <DetailsHospital symbol={symbol} item={item}
      //                                                         navOne={this.state.nav1}
      //                                                         addToCartClicked={addToCart}
      //                                                         doctorLength={this.state.doctors.length}
      //                                                         BuynowClicked={addToCartUnsafe}
      //                                                         addToWishlistClicked={addToWishlist} />

      //                                                 </div>

      //                                             </div>

      //                                             <br />
      //                                             <br />
      //                                             <Divider orientation="left"></Divider>

      //                                             {/* {(this.state.sticky) ? <section>
      //     <div style={{
      //         position: "fixed", top: '62px',

      //         width: "100%", backgroundColor: "white", paddingTop: "10px", borderBottom: "1px solid #eee", zIndex: "50"
      //     }}>
      //         <div style={{ width: "25%", display: "flex", justifyContent: "space-between", paddingBottom: "1rem" }}>
      //             <div style={{ backgroundColor: this.state.doctClicked ,padding:"3px"}} onClick={() => { this.setState({ doctClicked: "#008CBA", departClicked: null, roomClicked: null, reviewClicked: null }) }}>
      //                 <Link to={`#section1`}>Doctors</Link>
      //             </div>
      //             <div style={{ backgroundColor: this.state.departClicked,padding:"3px" }} onClick={() => { this.setState({ departClicked: "#008CBA", doctClicked: null, roomClicked: null, reviewClicked: null }) }}>
      //                 <Link to={`#section2`}>Departments</Link>

      //             </div>
      //             <div style={{ backgroundColor: this.state.roomClicked ,padding:"3px"}} onClick={() => { this.setState({ roomClicked: "#008CBA", departClicked: null, doctClicked: null, reviewClicked: null }) }}>
      //                 <Link to={`#section3`}>Rooms</Link>
      //             </div>
      //             <div style={{ backgroundColor: this.state.reviewClicked,padding:"3px" }} onClick={() => { this.setState({ reviewClicked: "#008CBA", departClicked: null, roomClicked: null, doctClicked: null }) }}>
      //                 <Link to={`#section4`}>Reviews</Link>
      //             </div>
      //         </div>
      //     </div>
      // </section> :
      //     ''
      // }
      // <div id="headerdocSticky" style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #eee", paddingBottom: "1rem", width: "40%" }}>
      //     <div style={{ backgroundColor: this.state.doctClicked,padding:"3px" }} onClick={() => { this.setState({ doctClicked: "#008CBA", departClicked: null, roomClicked: null, reviewClicked: null }) }}>
      //         <Link to={`#section1`}>Doctors</Link>
      //     </div>
      //     <div style={{ backgroundColor: this.state.departClicked,padding:"3px" }} onClick={() => { this.setState({ departClicked: "#008CBA", doctClicked: null, roomClicked: null, reviewClicked: null }) }}>
      //         <Link to={`#section2`}>Departments</Link>

      //     </div>
      //     <div style={{ backgroundColor: this.state.roomClicked,padding:"3px" }} onClick={() => { this.setState({ roomClicked: "#008CBA", departClicked: null, doctClicked: null, reviewClicked: null }) }}>
      //         <Link to={`#section3`}>Rooms</Link>
      //     </div>
      //     <div style={{ backgroundColor: this.state.reviewClicked,padding:"3px" }} onClick={() => { this.setState({ reviewClicked: "#008CBA", departClicked: null, roomClicked: null, doctClicked: null }) }}>
      //         <Link to={`#section4`}>Reviews</Link>
      //     </div>
      // </div> */}

      //                                             <Anchor>
      //                                                 <Link href={`${process.env.PUBLIC_URL}/hospital/${this.hospitalId}#section1`} title="Doctors" />
      //                                                 <Link href={`${process.env.PUBLIC_URL}/hospital/${this.hospitalId}#section2`} title="Departments" />
      //                                                 <Link href={`${process.env.PUBLIC_URL}/hospital/${this.hospitalId}#section3`} title="Rooms" />
      //                                                 <Link href={`${process.env.PUBLIC_URL}/hospital/${this.hospitalId}#section4`} title="Reviews" />

      //                                             </Anchor>

      //                                             <div id="section1"></div>
      //                                             <section className="silky" style={{ paddingTop: "75px" }}>
      //                                                 <h3>Doctors</h3>

      //                                                 <Divider orientation="left"></Divider>
      //                                                 {(this.state.doctors.length > 3) ? <section>
      //                                                     <Slider {...settings}>
      //                                                         {this.state.doctors.map((vari, index) =>

      //                                                             <div key={index} style={{ height: "100px", width: "300px" }}>

      //                                                                 <div>
      //                                                                     {/* < a href={'/doctor/' + vari.slug} style={{ */}
      //                                                                     <ReactLink to={`${process.env.PUBLIC_URL}/doctor/${vari.slug}`}
      //                                                                         style={{
      //                                                                             display: "flex",
      //                                                                             alignItems: "center",
      //                                                                             border: "1px solid #eed7d7",
      //                                                                             marginRight: "20px"
      //                                                                         }}>
      //                                                                         <img src={vari.Pictures[0].url}
      //                                                                             style={{ width: "100px", flex: "1" }}
      //                                                                             alt="Treatment Excellence" />
      //                                                                         <div style={{
      //                                                                             display: "flex",
      //                                                                             flexDirection: "column",
      //                                                                             flex: "3",
      //                                                                             marginLeft: "21px"
      //                                                                         }}>
      //                                                                             {/* <a  */}
      //                                                                             {/*<ReactLink to={`${process.env.PUBLIC_URL}/doctor/${vari.slug}`}*/}
      //                                                                             {/*    style={{ color: "vilot" }}*/}
      //                                                                             {/*>{vari.Name}*/}
      //                                                                             {/*</ReactLink>*/}
      //                                                                             <Rate disabled value={vari.Rating}
      //                                                                                 style={{
      //                                                                                     fontSize: 17,
      //                                                                                     color: '#ffa201'
      //                                                                                 }} />
      //                                                                         </div>
      //                                                                     </ReactLink>

      //                                                                 </div>

      //                                                             </div>
      //                                                         )}

      //                                                     </Slider>
      //                                                 </section> :
      //                                                     <section style={{
      //                                                         display: "flex",
      //                                                         alignItems: "center",
      //                                                         justifyContent: "space-between",
      //                                                         height: "115px"
      //                                                     }}>
      //                                                         {this.state.doctors.map((vari, index) =>

      //                                                             <div key={index} style={{
      //                                                                 display: "flex",
      //                                                                 flex: "1",
      //                                                                 flexDirection: "column",
      //                                                                 height: "100%",
      //                                                                 width: "380px"
      //                                                             }}>

      //                                                                 <ReactLink to={`${process.env.PUBLIC_URL}/doctor/${vari.slug}`}
      //                                                                     style={{
      //                                                                         display: "flex",
      //                                                                         alignItems: "center",
      //                                                                         border: "1px solid #eed7d7",
      //                                                                         marginRight: "20px"
      //                                                                     }}>
      //                                                                     <img src={vari.Pictures[0].url}
      //                                                                         style={{ width: "100px" }}
      //                                                                         alt="Treatment Excellence" />
      //                                                                     <div style={{
      //                                                                         display: "flex",
      //                                                                         flexDirection: "column",
      //                                                                         flex: "3",
      //                                                                         marginLeft: "21px"
      //                                                                     }}>
      //                                                                         <ReactLink to={`${process.env.PUBLIC_URL}/doctor/${vari.slug}`}
      //                                                                             style={{ color: "vilot" }}
      //                                                                         >{vari.Name}
      //                                                                         </ReactLink>
      //                                                                         <Rate disabled value={vari.Rating}
      //                                                                             style={{ fontSize: 17, color: '#ffa201' }} />
      //                                                                     </div>
      //                                                                 </ReactLink>
      //                                                             </div>
      //                                                         )}

      //                                                     </section>
      //                                                 }

      //                                             </section>

      //                                             <div id="section2" style={{ paddingTop: '20px' }}></div>
      //                                             <section style={{ paddingTop: "90px" }}>
      //                                                 <h3>Our Specialisations:</h3>
      //                                                 <br />

      //                                                 <Collapse defaultActiveKey={['1']} accordion>
      //                                                     {item.CardioDescription ?
      //                                                         <Panel header="Cardiology" key="1">
      //                                                             <Interweave content={item.CardioDescription} />
      //                                                         </Panel>
      //                                                         : ""}
      //                                                     {item.OrthoDescription ?
      //                                                         <Panel header="Orthopedics" key="2">
      //                                                             <Interweave content={item.OrthoDescription} />
      //                                                         </Panel>
      //                                                         : ""}
      //                                                     {item.OncoDescription ?
      //                                                         <Panel header="Oncology" key="3">
      //                                                             <Interweave content={item.OncoDescription} />
      //                                                         </Panel>
      //                                                         : ""}
      //                                                     {item.TransplatDescription ?
      //                                                         <Panel header="Transplant" key="4">
      //                                                             <Interweave content={item.TransplatDescription} />
      //                                                         </Panel>
      //                                                         : ""}
      //                                                     {item.NeuroDescription ?
      //                                                         <Panel header="Neurology" key="5">
      //                                                             <Interweave content={item.NeuroDescription} />
      //                                                         </Panel>
      //                                                         : ""}
      //                                                 </Collapse>
      //                                             </section>

      //                                             <div id="section3"></div>
      //                                             <section style={{ paddingTop: "90px" }}>
      //                                                 {item.Rooms ?
      //                                                     <div style={{ marginTop: '25px' }}>
      //                                                         <h3>Our Rooms:</h3>
      //                                                         <br />

      //                                                         {/* <table className="table table-striped mb-0">
      //                         <thead style={{fontWeight: '900'}}>
      //                         <tr>
      //                             <td>Type</td>
      //                             <td>Image</td>
      //                             <td>Description</td>
      //                             <td>Charges</td>
      //                         </tr>
      //                         </thead>
      //                         {item.Rooms.map((room, index) => {
      //                             return (<tbody key={index}>
      //                             <tr>
      //                                 <td>{room.Type}</td>
      //                                 <td>{room.Image ?
      //                                     <img src={room.Image[0].url}
      //                                          className="img-fluid blur-up lazyload bg-img"
      //                                          style={{width: '275px'}}
      //                                          alt=""/>
      //                                     : ""}</td>
      //                                 <td>{room.Description}</td>
      //                                 <td>{room.Charges}</td>
      //                             </tr>
      //                             </tbody>)
      //                         })}
      //                     </table> */}

      //                                                     </div> : ""}
      //                                                 <DetailsTopTabs item={item} />
      //                                             </section>

      //                                             <div id="section4"></div>
      //                                             <section style={{ paddingTop: "90px", width: "75%" }}>
      //                                                 <CommentsHosital item={item} auth={auth} type={'hospital'} />
      //                                             </section>

      //                                             {/* <Tabs defaultActiveKey="1" onChange={callback}>
      //     <TabPane tab="Doctors" key="1">

      //     </TabPane>
      //     <TabPane tab="Department/Labs" key="2">

      //     </TabPane>
      //     <TabPane tab="Rooms" key="3">

      //     </TabPane>
      //     <TabPane tab="Reviews" key="4">

      //     </TabPane>
      // </Tabs> */}
      //                                         </div>
      //                                         <LiveChat />
      //                                     </div>
      //                                 </div>
      //                             </div>
      //                         </section> :
      //                         <section className="section-b-space">
      //                             <div className="collection-wrapper">
      //                                 <div className="container">
      //                                     <div className="row">
      //                                         <Skeleton active avatar paragraph={{ rows: 7 }} />
      //                                         <Skeleton active avatar paragraph={{ rows: 7 }} />
      //                                         <Skeleton active avatar paragraph={{ rows: 7 }} />
      //                                     </div>
      //                                 </div>
      //                             </div>
      //                         </section>
      //                     }
      //                     {/*Section End*/}
      //                 </div>
      //             </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    item: state.data.products.find((el) => el.id == productId),
    symbol: state.data.symbol,
    auth: state.auth,
  };
};

export default connect(
  mapStateToProps,
  { addToCart, addToCartUnsafe, addToWishlist }
)(Hospital);
