import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
// import './CardSectionStyles.css'

const CARD_ELEMENT_OPTIONS = {
    style: {
        width:'400px',
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

function CardSection() {

    return (
        <div>
            {/* user={this.props.user} bookingEntry={this.props.bookingEntry} */}
            {/*<span style={{color: '#000', fontWeight: '600'}}>Card details</span><br/>*/}
            <div className="field-label">Card details</div>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
    );
};

export default CardSection;