import * as types from '../../constants/ActionTypes'

const doctorsReducerDefaultState = {
    isLoading: false,
    doctors: [],
    doctorDetails: {}
};

const doctorReducer = (state = doctorsReducerDefaultState, action) => {
    //console.log('DOCTOR/REDUCER : ', action);
    //console.log(action);
    switch (action.type) {
        case types.FETCH_DOCTORS_BEGIN:
            return {
                isLoading: true,
                doctors: []
            }
        case types.RECEIVE_DOCTORS:
            return {
                isLoading: false,
                doctors: action.doctors,
            };
        case types.FETCH_SINGLE_DOCTOR:
            return {
                isLoading: false,
                doctorDetails: action.doctor
            };
        default:
            return state;
    }
}

export default doctorReducer;